import React from 'react';

import useMartyContext from 'hooks/useMartyContext';

import css from 'styles/components/productdetail/description/SizeChartLi.scss';

interface Props {
  children?: JSX.Element | string | null | (JSX.Element | string | React.ReactElement<any>[] | null)[];
}

export default function SizeChartLi({ children }: Props) {
  const { testId } = useMartyContext();

  return (
    <li className={css.main} data-test-id={testId('sizeChartLi')}>
      {children}
    </li>
  );
}
