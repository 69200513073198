import React from 'react';

import type { SizeChartModalContent } from 'components/common/SizeChartModal';
import type { OpenSizeChartModal } from 'containers/ProductDetail';
import useMartyContext from 'hooks/useMartyContext';

interface Props {
  children: React.ReactElement | string | (React.ReactElement | string)[];
  modalContent: SizeChartModalContent;
  openSizeChartModal: OpenSizeChartModal;
  trackViewSizeChart: () => void;
}

export default function ViewSizeChartModalButton(props: Props) {
  const { children, modalContent, openSizeChartModal, trackViewSizeChart } = props;
  const { testId } = useMartyContext();
  return (
    <button
      data-track-action="Product-Page"
      data-track-label="Tabs"
      data-track-value="Size-Chart"
      data-test-id={testId('descriptionSizeChart')}
      onClick={() => {
        trackViewSizeChart();
        openSizeChartModal(modalContent);
      }}
      type="button"
    >
      {children}
    </button>
  );
}
