import { INFLUENCER_SHOPPABLE_POST_CUSTOMER_VIEW } from 'constants/influencerPages';
import { capitalize, makeAscii } from 'helpers/index';
import type { DimensionId, ProductStockData, ProductStyle, ValueIDToName } from 'types/cloudCatalog';
import type { InfluencerContent, InStockMap, SelectedDimensions, SelectMenuOption } from 'types/influencer';
import { INFLUENCER_CONTENT_TYPE } from 'constants/amethystEnums';
import type { FormattedProductBundle } from 'reducers/detail/productDetail';
import type { AirplaneCache } from 'types/AirplaneCache';
import { makeSizeLegendHeadingFromState } from 'components/productdetail/stylepicker/AirplaneSeatSizing';
import type { ProductWithRelations } from 'types/calypso';
import type { Product } from 'constants/searchTypes';

export const getInStockValues = (
  selectedDimensions: SelectedDimensions,
  dimensionsSet: DimensionId[],
  stockData: ProductStockData[],
  colorId: string
): InStockMap => {
  const inStockMap: InStockMap = {};
  dimensionsSet.forEach((dimension: string) => {
    const stockArray: string[] = stockData.map(stockEntry => {
      if (stockEntry.onHand === '0' || stockEntry.color !== colorId) return '';
      const isStockEntryForSelectedDimensions = Object.keys(selectedDimensions).every(
        selectedDimension => selectedDimension === dimension || selectedDimensions[selectedDimension] === stockEntry[selectedDimension]
      );
      if (!isStockEntryForSelectedDimensions) {
        return '';
      } else {
        return stockEntry[dimension]!;
      }
    });
    inStockMap[dimension] = new Set(stockArray);
  });
  return inStockMap;
};

export const formatDropdownNames = (
  dimensionValues: string[],
  valueIdToName: Record<string, ValueIDToName>,
  inStockValuesForDimension: Set<string> | undefined
): SelectMenuOption[] =>
  dimensionValues.map((dimensionValue: string) => ({
    value: dimensionValue,
    label: valueIdToName[dimensionValue]!.value,
    isInStock: inStockValuesForDimension !== undefined && inStockValuesForDimension?.has(dimensionValue)
  }));

export const generateShoppablePostShareObj = (title: string, postId: string, baseUrl: string) => {
  const SHOPPABLE_POST_URL = `${baseUrl}${INFLUENCER_SHOPPABLE_POST_CUSTOMER_VIEW}${postId}`;
  return {
    link: SHOPPABLE_POST_URL,
    name: title,
    style: '',
    image: ''
  };
};

export const convertContentToShoppablePost = (content: InfluencerContent) => ({
  ...content,
  postType: content.contentType,
  postId: content.contentId
});

export const generateLabelForProduct = (product: FormattedProductBundle, style: ProductStyle) => {
  const { brandName = '', productName = '', productRating = '' } = product;
  const { color = '', price = '' } = style || {};
  const encodedBrandName = makeAscii(brandName);
  const encodedProductName = makeAscii(productName);
  let label = `${encodedBrandName} - ${encodedProductName}.`;
  label = color ? `${label} Color ${color}.` : label;
  label = price ? `${label} Price ${price}.` : label;
  label = parseInt(productRating) > 0 ? `${label} ${productRating} out of 5 stars.` : label;
  return label;
};

// Format: https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/InfluencerContent.proto
export const transformContentForAmethystEvent = (content: InfluencerContent, index: number) => {
  const {
    contentId,
    contentType,
    additionalMetadata: { brandName }
  } = content;
  return {
    contentId,
    contentType: INFLUENCER_CONTENT_TYPE[contentType],
    brandName,
    index
  };
};

/**
 * Helper function to translate ProductWithRelations to Product
 * Needed for mapping related styles in ColorSwatches
 * @param relatedStyles
 */
export const translateToProducts = (relatedStyles: ProductWithRelations[]): Product[] =>
  relatedStyles.map((value: ProductWithRelations) => ({
    ...value,
    imageMap: {},
    isCouture: false,
    isNew: '',
    onHand: 0,
    animationImages: value.animationImages,
    brandName: value.brandName,
    colorId: value.colorId,
    onSale: '',
    originalPrice: value.originalPrice,
    percentOff: value.percentOff,
    price: value.price,
    productId: value.productId,
    productName: value.productName,
    productRating: 1,
    productSeoUrl: value.productUrl,
    productUrl: value.productUrl,
    reviewCount: 0,
    reviewRating: 1,
    styleColor: value.color,
    styleId: value.styleId,
    swatchUrl: value.swatchUrl,
    color: value.color,
    sizing: value.sizing || {}
  }));

export const commonDefaultColorSwatchParams = {
  useMouseEnterEvent: true,
  animationImages: [],
  imageMap: {},
  isCouture: false,
  onHand: 0,
  productRating: 0,
  reviewCount: 0,
  reviewRating: 1,
  sizing: {},
  swatchUrl: '',
  allowSwatchGrouping: true,
  isHydraSwatchGrouping: true
};

export const formatDimensionName = (dimensionName: string, airplaneCache?: AirplaneCache) => {
  if (dimensionName === 'size' && airplaneCache) {
    const {
      all: { genderOptions }
    } = airplaneCache;

    if (genderOptions.length > 1) {
      dimensionName = 'Size:';
    } else {
      const actualDimensionName = makeSizeLegendHeadingFromState(airplaneCache);

      !actualDimensionName.startsWith("Men's") && !actualDimensionName.startsWith("Women's")
        ? (dimensionName = 'Size:')
        : (dimensionName = actualDimensionName);
    }
  } else if (dimensionName === 'width') {
    dimensionName = `${dimensionName} Options:`;
  } else {
    dimensionName = `${dimensionName}:`;
  }
  return capitalize(dimensionName!);
};
