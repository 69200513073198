import type { ThunkAction } from 'redux-thunk';
import type { AnyAction } from 'redux';

import {
  ADD_IMAGE_DIMESIONS,
  ADD_OUTFIT_PRODUCT_DETAILS,
  SET_CURRENTLY_VIEWING_OUTFIT_RECO,
  SET_OUTFIT_RECOS,
  SET_SOURCE_PRODUCT_URL
} from 'constants/reduxActions';
import type { GetOutfitsResponse, ImageDimensionMap, OutfitRecoContent, RecoProductsData } from 'types/outfitRecos';
import type { AppState } from 'types/app';
import { selectMafiaConfig } from 'selectors/environment';
import { getOutfitRecos } from 'apis/mafia';
import { productBundle } from 'apis/cloudcatalog';
import timedFetch from 'middleware/timedFetch';
import { fetchErrorMiddleware } from 'middleware/fetchErrorMiddleware';
import { formatProductData } from 'reducers/detail/productDetail';
import type { ProductBundleOptions } from 'types/cloudCatalog';

export function setOutfitRecos(recos: OutfitRecoContent[]) {
  return {
    type: SET_OUTFIT_RECOS,
    recos
  } as const;
}

export function setCurrentlyViewingOutfitReco(reco: OutfitRecoContent) {
  return {
    type: SET_CURRENTLY_VIEWING_OUTFIT_RECO,
    reco
  } as const;
}

export function setSourceProductUrl(sourceProductUrl: string) {
  return {
    type: SET_SOURCE_PRODUCT_URL,
    sourceProductUrl
  } as const;
}

export function addOutfitProductDetails(recoProductsData: RecoProductsData) {
  return {
    type: ADD_OUTFIT_PRODUCT_DETAILS,
    recoProductsData
  } as const;
}

export function addImageDimensions(imageDimensions: ImageDimensionMap) {
  return {
    type: ADD_IMAGE_DIMESIONS,
    imageDimensions
  } as const;
}

export function fetchComplementaryRecos(styleId: string): ThunkAction<Promise<void>, AppState, void, AnyAction> {
  return (dispatch, getState) => {
    const state = getState();
    const { cookies } = state;
    const mafiaConfig = selectMafiaConfig(state);
    return getOutfitRecos(mafiaConfig, styleId, cookies)
      .then(fetchErrorMiddleware)
      .then((response: GetOutfitsResponse) => {
        const { recommendations } = response;
        if (recommendations) {
          dispatch(setOutfitRecos(recommendations));
        }
      });
  };
}

export function fetchProductStyleDetails(
  fetchOpts: ProductBundleOptions,
  refreshProductStyleDetails?: boolean
): ThunkAction<void, AppState, void, AnyAction> {
  return (dispatch, getState) => {
    const {
      environmentConfig: {
        api: { cloudcatalog }
      },
      outfitRecos: { recoProductsData }
    } = getState();
    const { styleId = '' } = fetchOpts;

    if (!refreshProductStyleDetails && styleId in recoProductsData) {
      return;
    }

    return productBundle(cloudcatalog, fetchOpts, timedFetch('cloudCatalogProduct'))
      .then(fetchErrorMiddleware)
      .then(response => {
        const productData = formatProductData({ detail: response.product[0]! });
        const recoProductsData = {
          [styleId]: { ...productData, styleId }
        } as RecoProductsData;
        dispatch(addOutfitProductDetails(recoProductsData));
        return recoProductsData;
      });
  };
}

export type OutfitRecosAction =
  | ReturnType<typeof setOutfitRecos>
  | ReturnType<typeof setCurrentlyViewingOutfitReco>
  | ReturnType<typeof addOutfitProductDetails>
  | ReturnType<typeof addImageDimensions>
  | ReturnType<typeof setSourceProductUrl>;
