import type { ContextType } from 'react';
import React, { Component } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import ExecutionEnvironment from 'exenv';
import queryString from 'query-string';
import type { Location } from 'history';
import {
  AccordionContent,
  AccordionTrigger,
  Accordion as ZapposUiAccordion,
  AccordionItem as ZapposUiAccordionItem
} from '@mweb/zappos-ui/Accordion';

import { cn } from 'helpers/classnames';
import RecosCantFindYourSize from 'components/productdetail/RecosCantFindYourSize';
import { trackPageEvent } from 'utils/contentSquare/uxaHelper';
import EventName from 'utils/contentSquare/eventNames';
import type { AccordionItemProps } from 'components/common/MelodyAccordion';
import { Accordion, AccordionItem } from 'components/common/MelodyAccordion';
import type { SizeChartModalContent, SizeChartModalProps } from 'components/common/SizeChartModal';
import SizeChartModal from 'components/common/SizeChartModal';
import RecosCompleteTheLook from 'components/productdetail/RecosCompleteTheLook';
import LazyHowItWasWorn from 'components/productdetail/LazyHowItWasWorn';
import { AFTER_PAY_JS } from 'constants/externalJavascriptFiles';
import { PRODUCT_PAGE } from 'constants/amethystPageTypes';
import {
  HYDRA_ALTERNATIVE_RECO,
  HYDRA_BELOW_ADD_TO_CART_SLOT,
  HYDRA_COLOR_LEGEND_PDP,
  HYDRA_CORE_EXPERIENCE_FUNCTIONALITY_IMPROVEMENTS,
  HYDRA_PHOTO_ANGLES,
  HYDRA_RECO_DRAWER
} from 'constants/hydraTests';
import { LazyAsk } from 'containers/LazyAsk';
import { evBadgeCategoryClick, evCantFindYourSizeClick, evProductLowestRecentPrice, evViewSizeChart, pvProduct } from 'events/product';
import { evAddToCart } from 'events/cart';
import { evRecommendationClick } from 'events/recommendations';
import {
  BrandStylesNotifyModal,
  OutOfStockModalWrapper,
  ProductNotifyModal,
  ReportAnErrorModal
} from 'components/productdetail/asyncProductPageModals';
import OutOfStockPopoverWrapper from 'components/productdetail/OutOfStockPopoverWrapper';
import { getHeartCounts, getHearts, heartProduct, toggleHeartingLoginModal, unHeartProduct } from 'actions/hearts';
import { trackEvent, trackLegacyEvent } from 'helpers/analytics';
import { firePixelEvent } from 'actions/pixelServer';
import { getHeartProps } from 'helpers/HeartUtils';
import { saveInfluencerToken } from 'helpers/InfluencerUtils';
import { evLandingPageInfluencer } from 'events/influencer';
import { pageTypeChange } from 'actions/common';
import { addAdToQueue, updateAdData } from 'actions/ads';
import { isAssigned, isInAssignment, triggerAssignment } from 'actions/ab';
import { createAddToCartMicrosoftUetEvent, pushMicrosoftUetEvent } from 'actions/microsoftUetTag';
import { onLookupRewardsTransparencyPointsForItem } from 'store/ducks/rewards/actions';
import { buildProductPageRecoKey, getJanusRecos, getRecoSlotKey, getRecosSlot, RECOS_CANT_FIND_YOUR_SIZE_KEY } from 'helpers/RecoUtils';
import { fetchAccountInfo } from 'actions/account/account';
import {
  fetchBrandPromo,
  fetchLowestPrices,
  fetchProductSearchSimilarity,
  fetchSizingPrediction,
  getPdpStoriesSymphonyComponents,
  hideSelectSizeTooltip,
  highlightSelectSizeTooltip,
  loadProductDetailPage,
  onProductDescriptionCollapsed,
  productAgeGroupChanged,
  productGenderChanged,
  productSingleShoeSideChanged,
  productSizeChanged,
  productSizeRangeChanged,
  productSizeUnitChanged,
  productSwatchChange,
  selectedColorChanged,
  setProductDocMeta,
  showSelectSizeTooltip,
  toggleOosButton,
  toggleProductDescription,
  unhighlightSelectSizeTooltip,
  validateDimensions
} from 'actions/productDetail';
import { fetchProductReviews, fetchProductReviewsWithMedia, hideReviewGalleryModal, showReviewGalleryModal } from 'actions/reviews';
import { handleGetInfluencerStatus, handleGetInfluencerToken } from 'actions/influencer/influencer';
import { toggleBrandNotifyModal, toggleProductNotifyModal, toggleReportAnErrorModal } from 'actions/productdetail/sharing';
import { setLastSelectedSize } from 'actions/lastSelectedSizes';
import { submitNotifyBrandEmail } from 'actions/brand';
import { changeQuantity, showCartModal } from 'actions/cart';
import { fetchProductPageRecos } from 'actions/recos';
import { translateCartError } from 'apis/mafia';
import { PageLoader } from 'components/Loader';
import SiteAwareMetadata from 'components/SiteAwareMetadata';
import SocialCollectionsWidget from 'components/account/Collections/SocialCollectionsWidget';
import ProductBreadcrumbs from 'components/productdetail/ProductBreadcrumbs';
import InfluencerAddToCollectionAndSharing from 'components/productdetail/InfluencerAddToCollectionAndSharing';
import ProductCallout from 'components/productdetail/ProductCallout';
import ProductName from 'components/productdetail/ProductName';
import StylePicker from 'components/productdetail/stylepicker/StylePicker';
import ProductGallery from 'components/productdetail/productGallery/ProductGallery';
import HappyFeatureFeedback from 'components/HappyFeatureFeedback';
import ExpandableProductDescription from 'components/productdetail/description/ExpandableProductDescription';
import RecosDetail1 from 'components/productdetail/RecosDetail1';
import RecosDetail2 from 'components/productdetail/RecosDetail2';
import RecosDetail3 from 'components/productdetail/RecosDetail3';
import ReviewPreview from 'components/productdetail/ReviewPreview';
import { indefiniteArticleSelector, openPopup, stripSpecialCharsDashReplace } from 'helpers/index';
import { usdToNumber } from 'helpers/NumberFormats';
import ProductUtils, { getProductImagesFormatted } from 'helpers/ProductUtils';
import { isDesktop, offset } from 'helpers/ClientUtils';
import { loadScript } from 'helpers/ScriptUtils';
import { buildSeoBrandString, buildSeoProductString, buildSeoProductUrl } from 'helpers/SeoUrlBuilder';
import FitSurvey from 'components/productdetail/FitSurvey';
import Price from 'components/productdetail/Price';
import ShippingAndReturnsBanner from 'components/productdetail/ShippingAndReturnsBanner';
import ReviewPhotoGallery from 'components/productdetail/ReviewPhotoGallery';
import ReviewGalleryWrapper from 'containers/ReviewGalleryWrapper';
import BrandPromo from 'components/productdetail/BrandPromo';
import { POPUP_CLASS_RE } from 'common/regex';
import { shouldRenderMostHelpfulReviews, shouldRenderReviewGallery } from 'helpers/ReviewUtils';
import { getNumberOfAskQuestions } from 'helpers/AskUtils';
import { apstagRemoveToken, apstagUpdateToken, PDP_NARROW_MID, PDP_NARROW_TOP, PDP_WIDE_MID, PDP_WIDE_TOP } from 'helpers/apsAdvertisement';
import { onEvent } from 'helpers/EventHelpers';
import { titaniteView, track } from 'apis/amethyst';
import { stockSelectionCompleted } from 'store/ducks/productDetail/actions';
import GamSlot from 'components/common/GamSlot';
import JanusPixel from 'components/common/JanusPixel';
import ImageLazyLoader from 'components/common/ImageLazyLoader';
import { StructuredVideoObject } from 'components/common/StructuredDataTypes';
import LandingSlot from 'containers/LandingSlot';
import { ProductContextProvider } from 'components/productdetail/ProductContext';
import BuyBoxReviewBlurb from 'components/productdetail/BuyBoxReviewBlurb';
import type { FormattedProductBundle, ProductDetailState, StyleThumbnail } from 'reducers/detail/productDetail';
import type { ProductBrand, ProductSizing, ProductStockData, ProductStyle, SizingValue } from 'types/cloudCatalog';
import type { FeaturedImage, ProductDimensionValidation, SelectedSizing } from 'types/product';
import type { RecosState } from 'reducers/recos';
import type { AppState } from 'types/app';
import { InfluencerStatus } from 'types/influencer';
import SizeCharts from 'components/productdetail/description/SizeCharts';
import { MartyContext } from 'utils/context';
import Gallery from 'components/productdetail/productGallery/Gallery';
import AddToCollection from 'components/productdetail/productGallery/AddToCollection';
import { getProductRelations } from 'actions/products';
import type { Product } from 'constants/searchTypes';
import RecoDrawer from 'components/common/recos/RecoDrawer';
import Badge from 'components/common/Badge';
import PdpSponsoredAds from 'components/productdetail/PdpSponsoredAds';
import { fetchCustomerAuthDetails } from 'actions/authentication';
import { selectSymphonyStory } from 'selectors/product';
import RecosSection from 'components/outfitRecos/RecosSection';
import ProductComparisonTable from 'components/productdetail/ProductComparisonTable';
import { UnleashWiringTest } from 'components/common/UnleashWiringTest';
import ProductSkuNumber from 'components/productdetail/ProductSkuNumber';
import { selectIsFeaturePdpPaperCuts, selectIsPdpAccordionOrder } from 'selectors/features';

import css from 'styles/containers/productDetail.scss';

/**
 * This definitely doesn't feel great, but `SiteAwareMetadata` does some funny business with higher-order-functions,
 * so until that file is typed, we need to cast it.
 */
const SiteMetadata = SiteAwareMetadata as any;

const recosDetail1Ref = React.createRef<HTMLHeadingElement>();

// map of the component prop name to hydra test name for ab tests the component is built for.
const TEST_PROP_TO_NAME = {
  hydraBelowAddToCartSlot: HYDRA_BELOW_ADD_TO_CART_SLOT,
  isHydraAltReco: HYDRA_ALTERNATIVE_RECO,
  isHydraColorLegend: HYDRA_COLOR_LEGEND_PDP,
  isHydraPhotoAngles: HYDRA_PHOTO_ANGLES
} as const;

const HIGHLIGHTS_ACCORDION_HEADING = 'Highlights';
const SIZE_CHART_ACCORDION_HEADING = 'Size Chart';

interface State {
  isLinkCopied: boolean;
  isSpotlightActive: boolean;
  mouseCoordinates: any;
  movementRatioX: number;
  movementRatioY: number;
  sizeChartModalProps: SizeChartModalProps;
  spotlightHiResImageHeight: number;
  spotlightHiResImageSrc: string | null;
  spotlightHiResImageWidth: number;
  spotlightLowResImageSrc: string | null;
  spotlightWrapperHeight: number;
  isVideo: boolean;
}

interface Params {
  productId: string;
  colorId?: string;
  seoName?: string;
}

type Props = PropsFromRedux & RouteComponentProps<Params, {}> & typeof defaultProps;

const defaultProps = {
  enableSlideUpHeader: true,
  trackEvent,
  trackLegacyEvent,
  firePixelEvent
};

type OnRecoClickedHandler = typeof ProductDetail.prototype.onRecoClicked;

function makeWearItWithFragment(similarProductRecos: RecosState, onRecoClicked: OnRecoClickedHandler, isHydraCefiEnabled: boolean) {
  const className = cn(css.aboveProductInfo, css.fullWidth, { ['!mb-0']: isHydraCefiEnabled });
  return (
    <>
      <RecosCompleteTheLook similarProductRecos={similarProductRecos} onRecoClicked={onRecoClicked} className={className} />
    </>
  );
}

export type OpenSizeChartModal = typeof ProductDetail.prototype.openSizeChartModal;

// requires productId from path param with optional colorId from path.
// Trigger assignments here for SSR components
export class ProductDetail extends Component<Props, State> {
  static fetchDataOnServer(store: any, _location: Location, { productId, colorId, seoName }: Params) {
    const { dispatch } = store;
    return dispatch(
      loadProductDetailPage(productId, {
        colorId,
        seoName,
        firePixel: true,
        includeOosSizing: false
      })
    );
  }

  static defaultProps = defaultProps;

  constructor(props: Props) {
    super(props);
    this.onStockChange = this.onStockChange.bind(this);
    this.onProductDescriptionToggle = this.onProductDescriptionToggle.bind(this);
    this.onProductDescriptionCollapsed = this.onProductDescriptionCollapsed.bind(this);
    this.onRecoClicked = this.onRecoClicked.bind(this);
    this.onSwatchStyleChosen = this.onSwatchStyleChosen.bind(this);
    this.onHideSelectSizeTooltip = this.onHideSelectSizeTooltip.bind(this);
    this.unhighlightAndHideSelectSizeTooltip = this.unhighlightAndHideSelectSizeTooltip.bind(this);
    this.getHeartCountsDebounced = debounce(this.getHeartCountsDebounced.bind(this), 350);
    this.getProductRelationsDebounced = debounce(this.getProductRelationsDebounced.bind(this), 350);
    this.checkForHeartsAndProductRelations = this.checkForHeartsAndProductRelations.bind(this);
    this.getRecosToDisplay = this.getRecosToDisplay.bind(this);
    this.isVideoSelected = this.isVideoSelected.bind(this);
  }

  state: State = {
    isSpotlightActive: false,
    isLinkCopied: false,
    sizeChartModalProps: { isOpen: false },
    spotlightLowResImageSrc: '',
    spotlightHiResImageSrc: '',
    spotlightHiResImageWidth: 0,
    spotlightHiResImageHeight: 0,
    spotlightWrapperHeight: 0,
    mouseCoordinates: {},
    movementRatioX: 0,
    movementRatioY: 0,
    isVideo: false
  };

  componentDidMount() {
    titaniteView();
    const {
      addAdToQueue,
      getHearts,
      pageTypeChange,
      product: { detail },
      triggerAssignment,
      fetchAccountInfo,
      fetchCustomerAuthDetails,
      adCustomerId,
      adEmailHash,
      holmes,
      updateAdData,
      location
    } = this.props;

    const {
      marketplace: {
        features: { allowTwoDayShippingPrimePerk, showReviews },
        isInfluencerProgramEnabled,
        pdp: { hasSymphonyStories, showSizingPrediction, showBrandPromo, hasLowestRecentPrice },
        checkout: { allowAfterPay },
        hasApstagAdsToken,
        name
      }
    } = this.context;

    // todo
    /* This "apstagRemoveToken" function is added to remove the cookies that was generated to personalise the apstag ads on 6pm site. Currently the functionality is
        turned off but the cookies are not yet removed. They will automatically get removed after their lifetime, i.e. 14 days from turn off - we
        can remove this function after 9th Feb 2023. We are adding this function to make sure that users are not getting personalised content when
        personalisation is turned off from our side. */
    if (name === '6pm.com') {
      apstagRemoveToken();
    }

    if (hasApstagAdsToken) {
      const customerId = holmes ? holmes.customerId : '';
      apstagUpdateToken(customerId, adCustomerId, adEmailHash, fetchAccountInfo, updateAdData, fetchCustomerAuthDetails);
    }

    // you do not need to call assignTests more than once - just store the
    // result of the first call in a variable.
    //
    // we must call assignTests exactly once in componentDidMount, however, in
    // order to ensure all tests in TEST_PROP_TO_NAME are triggered.
    this.assignTests();

    triggerAssignment(HYDRA_CORE_EXPERIENCE_FUNCTIONALITY_IMPROVEMENTS);

    !isDesktop() && triggerAssignment(HYDRA_RECO_DRAWER);

    if (allowTwoDayShippingPrimePerk) {
      triggerAssignment(HYDRA_BELOW_ADD_TO_CART_SLOT);
    }

    if (allowAfterPay) {
      loadScript({
        src: AFTER_PAY_JS,
        id: 'afterpay-bundle',
        onLoadCallback: null,
        onLoadErrorCallback: null
      });
    }

    pageTypeChange('product');
    firePixelEvent('product');

    const {
      product: { reviewData, sizingPredictionId },
      params: { productId, colorId, seoName },
      fetchBrandPromo,
      fetchProductReviews,
      fetchProductReviewsWithMedia,
      fetchSizingPrediction,
      handleGetInfluencerToken,
      isShowingThirdPartyAds,
      getPdpStoriesSymphonyComponents,
      productSwatchChange,
      setProductDocMeta,
      toggleBrandNotifyModal,
      toggleProductNotifyModal,
      toggleReportAnErrorModal,
      trackEvent,
      fetchLowestPrices,
      selectedColorChanged,
      isProductTypeShoesOrClothing
    } = this.props;
    const { styles, defaultProductType, productId: detailProductId } = detail || {};
    // Tests triggered client side on willMount don't get updated props for the initial didMount
    // Instead we need to manually store the result of the assignment for checks done during didMount
    // See https://github.com/reactjs/react-redux/issues/210 for context.
    const isDesktopSize = isDesktop();

    if (isShowingThirdPartyAds) {
      const slots = [{ name: PDP_NARROW_MID }, { name: PDP_NARROW_TOP }, { name: PDP_WIDE_MID }, { name: PDP_WIDE_TOP }];

      addAdToQueue(slots);
    }

    // Setting event watcher for popup link clicks set by content
    onEvent(
      document.body,
      'click',
      (e: React.MouseEvent) => {
        const { target } = e;
        const element = target as HTMLElement;
        if (element && element.tagName === 'A' && POPUP_CLASS_RE.test(element.className)) {
          openPopup(e as React.MouseEvent<HTMLAnchorElement>);
        }
      },
      undefined,
      this
    );

    onEvent(document.body, 'click', this.onBuyBoxPageContentClick, undefined, this);

    // Updates airplane cache to avoid stale sizing data when changing the product
    // color and navigating back an forth to the same previous pdp page.
    const airplaneConstraintColorId = detail?.sizing.airplaneCache?.constraints.colorId;
    if (airplaneConstraintColorId !== colorId) {
      selectedColorChanged(colorId);
    }

    // if we client routed we need to load everything
    if (!detail || detailProductId !== productId || (styles && styles.length === 0)) {
      this.fetchData(productId);
    } else if (styles) {
      setProductDocMeta(detail, colorId, isProductTypeShoesOrClothing);
      this.getRewardsTransparencyData();
      // product data is loaded, but non-critical pieces may not
      if (window.pageYOffset === 0 && !isDesktop()) {
        this.slideUpHeader();
      }

      if (hasSymphonyStories && productId) {
        getPdpStoriesSymphonyComponents(productId);
      }

      if (showReviews && (!reviewData || reviewData.productId !== productId || reviewData.page !== 1 || reviewData.offset !== 0)) {
        fetchProductReviews(productId, 1, 0, false);
        fetchProductReviewsWithMedia(productId);
      }

      if (hasLowestRecentPrice) {
        fetchLowestPrices(productId);
      }

      // If we direct link to an OOS product, we fall back to the first available style
      // In this instance (if no in stock style matches our colorId from the URL parameter)
      // Make sure we "select" that first style
      if (colorId && !styles.some(style => style.colorId === colorId)) {
        const { colorId: newColorId } = styles[0]!;
        productSwatchChange(newColorId);
        this.useSeoUrl(detail, newColorId);
      }

      if (detailProductId === productId && seoName !== buildSeoProductString(detail, colorId)) {
        this.useSeoUrl(detail, colorId);
      }

      if (showSizingPrediction && colorId && !sizingPredictionId && ProductUtils.isShoeType(defaultProductType)) {
        fetchSizingPrediction(detail, productId, colorId);
      }

      if (isDesktopSize) {
        // desktop-only features
        if (showBrandPromo && detail.brandId) {
          fetchBrandPromo(detail.brandId);
        }
      }

      this.trackPageView(detail);
    }
    if (detailProductId) {
      this.checkForRecos(detailProductId, styles, { colorId });
    }
    trackEvent('TE_PV_PDP', productId);

    if (location.hash) {
      if (location.hash.indexOf('showModal=notifyoos') > -1) {
        toggleProductNotifyModal(true);
      } else if (location.hash.indexOf('showModal=notifybrand') > -1) {
        toggleBrandNotifyModal(true);
      } else if (location.hash.indexOf('showModal=reportanerror') > -1) {
        toggleReportAnErrorModal(true);
      }
    }
    // Get hearting list
    getHearts();

    // Get Influencer Status and token
    if (isInfluencerProgramEnabled) {
      handleGetInfluencerToken();
    }

    const { search } = location;
    const { infToken } = queryString.parse(search) || {};
    if (infToken) {
      saveInfluencerToken(infToken);
      track(() => [evLandingPageInfluencer, { linkId: infToken, pageId: PRODUCT_PAGE, productId, colorId }]);
    }

    this.trackLowestRecentPriceView();
  }

  componentDidUpdate(prevProps: Props) {
    const {
      params,
      trackEvent,
      product: { detail },
      showCartModal,
      params: { colorId }
    } = prevProps;

    const { productId } = detail || {};

    const {
      isCartModalShowing,
      product: { isSimilarStylesLoading, detail: nextDetail, lowestPrices },
      params: { productId: nextProductId, colorId: nextColorId, seoName: nextSeoName },
      fetchSizingPrediction,
      fetchBrandPromo,
      toggleOosButton,
      router
    } = this.props;

    // In some cases, lowestPrices takes longer to fetch, this can cause the related Amethyst event not to trigger.
    const sameLowestPrices =
      lowestPrices?.length &&
      prevProps.product.lowestPrices?.length === lowestPrices?.length &&
      prevProps.product.lowestPrices?.every((price, index) => price === lowestPrices[index]);

    if (!sameLowestPrices) this.trackLowestRecentPriceView();

    const {
      marketplace: {
        pdp: { showSizingPrediction, showBrandPromo }
      }
    } = this.context;

    const { productId: nextDetailProductId, styles: nextStyles, defaultProductType: nextProductType } = nextDetail || {};

    if (nextProductId !== params.productId) {
      titaniteView();
      this.fetchData(nextProductId);
      trackEvent('TE_PV_PDP', nextProductId);
    }

    const newProductLoaded = nextDetail && (productId !== nextDetailProductId || !detail);
    const newColorId = nextDetail && colorId !== nextColorId;
    // update pretty URL if the product data for a new product has loaded
    if (nextDetail && nextDetailProductId === nextProductId) {
      if (newProductLoaded && buildSeoProductString(nextDetail, nextColorId) !== nextSeoName && nextColorId) {
        this.useSeoUrl(nextDetail, nextColorId);
      }

      // if the color changed but the product didn't, fetch new recos.
      if (!newProductLoaded && colorId !== nextColorId) {
        isCartModalShowing && showCartModal(false);
        toggleOosButton(false);
        this.checkForRecos(nextProductId, nextStyles, { colorId: nextColorId });
        this.trackLowestRecentPriceView();
      }
    }

    if (newColorId || newProductLoaded) {
      this.getRewardsTransparencyData(); // if colorId differs from next colorId, make the call again
    }
    // for client routing, this block is for calls we need to make once the product itself has loaded.
    if (newProductLoaded) {
      if (nextDetail) {
        this.trackPageView(nextDetail);
        this.trackLowestRecentPriceView();
      }
      if (window.pageYOffset === 0 && !isDesktop()) {
        this.slideUpHeader();
      }

      if (showSizingPrediction && ProductUtils.isShoeType(nextProductType) && nextColorId) {
        fetchSizingPrediction(nextDetail, nextProductId, nextColorId);
      }

      if (isDesktop()) {
        // desktop-only features
        if (showBrandPromo && nextDetail?.brandId) {
          fetchBrandPromo(nextDetail.brandId);
        }
      }

      this.checkForRecos(nextDetailProductId || nextProductId, nextStyles, {
        colorId: nextColorId
      });
    }

    if (!isSimilarStylesLoading) {
      this.checkForHeartsAndProductRelations(prevProps, this.props);
    }

    // if we click on a color swatch or reco we don't want to scroll back to top ( REPLACE )
    // if we came from a reco click and press back button we do want to suppress scrolling ( POP )
    // if we come from simple productDetail, search then we want to scroll back to top ( PUSH )
    // only do this if products have changed
    if (router?.action === 'PUSH' && productId !== nextProductId) {
      window?.scrollTo(0, 0);
    }
  }

  componentWillUnmount() {
    const { isCartModalShowing, hideReviewGalleryModal, showCartModal, toggleReportAnErrorModal } = this.props;
    const { isSpotlightActive } = this.state;
    isCartModalShowing && showCartModal(false);
    hideReviewGalleryModal();
    toggleReportAnErrorModal(false);

    // we probably don't need to do this, since the component is being unmounted let's cleanup
    if (isSpotlightActive) {
      this.zoomOut();
    }
  }

  static contextType = MartyContext;
  // @ts-ignore https://github01.zappos.net/mweb/marty/issues/19273
  context!: ContextType<typeof MartyContext>;

  // Types for function-bound refs on `this`
  buyBox: undefined | null | HTMLDivElement;
  theater: undefined | null | HTMLDivElement;
  spotlightWrapper: undefined | null | HTMLButtonElement;
  spotlight: undefined | null | HTMLDivElement;

  getRewardsTransparencyData = () => {
    const {
      marketplace: { hasRewardsTransparency }
    } = this.context;
    const { onLookupRewardsTransparencyPointsForItem, isGiftCard } = this.props;
    if (!isGiftCard && hasRewardsTransparency) {
      onLookupRewardsTransparencyPointsForItem();
    }
  };

  getRecosToDisplay(slot: 'slot0' | 'slot1' | 'slot2' | 'slot3' | 'cfys', props: Props) {
    const {
      similarProductRecos: recoStoreData,
      product: { detail },
      params
    } = props;

    const { colorId } = params || {};

    const { productId, styles } = detail || {};

    if (!styles) {
      return;
    }

    const style = ProductUtils.getStyleByColor(styles, colorId);
    const { lastReceivedRecoKey, janus: janusRecos = {} } = recoStoreData;
    if (productId) {
      const recosForProduct = janusRecos[buildProductPageRecoKey(productId, style?.styleId)] || janusRecos;
      return getRecosSlot(recosForProduct, slot, lastReceivedRecoKey);
    }
  }

  checkForHeartsAndProductRelations(props: Props, nextProps: Props) {
    const detail0 = this.getRecosToDisplay('slot0', props);
    const nextDetail0 = this.getRecosToDisplay('slot0', nextProps);
    const nextDetail0Recos = nextDetail0?.recos;

    const detail1 = this.getRecosToDisplay('slot1', props);
    const nextDetail1 = this.getRecosToDisplay('slot1', nextProps);
    const nextDetail1Recos = nextDetail1?.recos;

    const detail2 = this.getRecosToDisplay('slot2', props);
    const nextDetail2 = this.getRecosToDisplay('slot2', nextProps);
    const nextDetail2Recos = nextDetail2?.recos;

    const detail3 = this.getRecosToDisplay('slot3', props);
    const nextDetail3 = this.getRecosToDisplay('slot3', nextProps);
    const nextDetail3Recos = nextDetail3?.recos;

    const detailCfys = this.getRecosToDisplay('cfys', props);
    const nextDetailCfys = this.getRecosToDisplay('cfys', nextProps);
    const nextDetailCysRecos = nextDetailCfys?.recos;

    if (
      detail0?.recos !== nextDetail0Recos ||
      detail1?.recos !== nextDetail1Recos ||
      detail2?.recos !== nextDetail2Recos ||
      detail3?.recos !== nextDetail3Recos ||
      detailCfys?.recos !== nextDetailCysRecos
    ) {
      const allRecos = [
        ...(nextDetail0Recos || []),
        ...(nextDetail1Recos || []),
        ...(nextDetail2Recos || []),
        ...(nextDetail3Recos || []),
        ...(nextDetailCysRecos || [])
      ];
      this.getHeartCountsDebounced(allRecos);

      // get product relations for displaying swatches for recos
      const styleIds = allRecos.reduce((acc, { styleId }) => {
        !acc.includes(styleId) && acc.push(styleId);
        return acc;
      }, []);
      if (styleIds.length) {
        this.getProductRelationsDebounced(styleIds);
      }
    }
  }

  fetchData(productId: string) {
    const {
      loadProductDetailPage,
      fetchProductReviews,
      fetchProductReviewsWithMedia,
      getPdpStoriesSymphonyComponents,
      fetchLowestPrices,
      params: { colorId, seoName }
    } = this.props;
    const {
      marketplace: {
        pdp: { hasSymphonyStories, hasLowestRecentPrice },
        features: { showReviews }
      }
    } = this.context;

    const { isSpotlightActive } = this.state;
    if (isSpotlightActive) {
      this.zoomOut();
    }

    loadProductDetailPage(productId, {
      colorId,
      seoName,
      firePixel: true,
      includeOosSizing: false
    });

    if (hasSymphonyStories) {
      getPdpStoriesSymphonyComponents(productId);
    }

    if (showReviews) {
      fetchProductReviews(productId, 1, 0, false);
      fetchProductReviewsWithMedia(productId);
    }

    if (hasLowestRecentPrice) {
      fetchLowestPrices(productId);
    }
  }

  /**
   * Trigger all the tests defined in TEST_PROP_TO_NAME, and return a map of
   * component prop name to whether the user is in the treatment.
   */
  assignTests = () => {
    const results: Partial<Record<keyof typeof TEST_PROP_TO_NAME, boolean>> = {};
    const { triggerAssignment } = this.props;
    Object.keys(TEST_PROP_TO_NAME).forEach(prop => {
      const propName = prop as keyof typeof TEST_PROP_TO_NAME;
      const result = triggerAssignment(TEST_PROP_TO_NAME[propName]);
      results[propName] = isInAssignment(result);
    });
    return results;
  };

  getHeartCountsDebounced(...args: Parameters<typeof getHeartCounts>) {
    const {
      marketplace: { hasHeartCounts }
    } = this.context;
    if (!hasHeartCounts) return;
    this.props.getHeartCounts(...args);
  }

  getProductRelationsDebounced(styleIds: string[]) {
    this.props.getProductRelations(styleIds);
  }

  slideUpHeader() {
    if (!this.props.enableSlideUpHeader || typeof document === 'undefined') {
      return;
    }
  }

  checkForRecos(productId: string, styles: ProductStyle[] = [], { colorId }: { colorId: string | undefined }, isCFYSslot?: boolean) {
    const { isHydraAltReco, fetchProductPageRecos } = this.props;
    const productStyleId = (ProductUtils.getStyleByColor(styles, colorId) || {}).styleId;

    if (!productStyleId) {
      return;
    }

    fetchProductPageRecos(productId, productStyleId, isHydraAltReco, !!isCFYSslot);
  }

  useSeoUrl(product: FormattedProductBundle, colorId?: string) {
    this.context.router.replacePreserveAppRoot(this.buildSeoUrl(product, colorId));
  }

  buildSeoUrl(product: FormattedProductBundle, colorId?: string) {
    const { search, hash } = this.props.location;
    return `${buildSeoProductUrl(product, colorId)}${search ? search : ''}${hash || ''}`;
  }

  showAndHighlightSelectSizeTooltip() {
    const { showSelectSizeTooltip, highlightSelectSizeTooltip } = this.props;
    showSelectSizeTooltip();
    highlightSelectSizeTooltip();
  }

  unhighlightAndHideSelectSizeTooltip() {
    const { hideSelectSizeTooltip, unhighlightSelectSizeTooltip } = this.props;
    unhighlightSelectSizeTooltip();
    hideSelectSizeTooltip();
  }

  setLastSelectedSizes() {
    const {
      setLastSelectedSize,
      product: { detail, selectedSizing }
    } = this.props;
    const {
      sizing: { hypercubeSizingData = {} }
    } = detail as FormattedProductBundle;

    const gender = ProductUtils.getGender(detail);
    Object.entries(selectedSizing).forEach(([dimensionId, sizeId]) => {
      if (sizeId) {
        const range = hypercubeSizingData[sizeId];
        if (gender && dimensionId && range) {
          setLastSelectedSize(gender, dimensionId, sizeId, range.min, range.max);
        }
      }
    });
  }

  onAddToCart = (e: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>) => {
    const { changeQuantity, product, pushMicrosoftUetEvent, trackLegacyEvent, toggleOosButton, showCartModal } = this.props;
    const { detail, selectedSizing, colorId } = product;
    const { styles, sizing } = detail as FormattedProductBundle;
    const style = ProductUtils.getStyleByColor(styles, colorId);
    const currentColorId = colorId || style.colorId;
    const stock = ProductUtils.getStockBySize(sizing.stockData, currentColorId, selectedSizing);
    const asin = ProductUtils.getSelectedStyleStockBySize(undefined, sizing, selectedSizing, style.stocks)?.asin || undefined;
    e.preventDefault();
    if (!stock) {
      toggleOosButton(true);
    } else {
      const { onHand } = stock as ProductStockData;
      let { stockId: { value: stockId } = { value: undefined } } = e.target as HTMLFormElement,
        sticky;
      if (!stockId) {
        ({
          dataset: { sticky, stockId } = {
            sticky: undefined,
            stockId: undefined
          }
        } = e.currentTarget as HTMLElement);
      }
      const isSticky = Boolean(sticky);
      /* To differentiate sticky vs non-sticky add to cart, the sticky button fires this method via onClick (opposed to the native form submit)
               We need to read the stockID from the button directly, since the event doesn't have access to the hidden stockId input in AddToCart.jsx
            */
      if (stockId && ProductUtils.isSizeSelectionComplete(sizing, selectedSizing)) {
        trackLegacyEvent('CartAddItem', null, `stockId:${stockId}|styleId:${style.styleId}`);
        const amethystEventBundle = {
          ...style,
          addedFrom: PRODUCT_PAGE,
          isSticky
        };
        track(() => [evAddToCart, amethystEventBundle]);
        pushMicrosoftUetEvent(createAddToCartMicrosoftUetEvent(stockId, +usdToNumber(style.price), 'product'));
        this.setLastSelectedSizes();
        changeQuantity(
          {
            items: [
              {
                stockId,
                quantity: 1,
                quantityAddition: true,
                onHand,
                trackAsin: asin
              }
            ]
          },
          { firePixel: true }
        ).then(response => {
          const error = translateCartError(response);
          if (error) {
            alert(error);
          } else {
            showCartModal(true, stockId);
          }
        });
      } else {
        this.handleAddToCartIncomplete(style, sizing, selectedSizing, isSticky);
      }
    }
  };

  // re-usable handler for onAddToCart cases where sizing selection is incomplete
  handleAddToCartIncomplete = (style: ProductStyle, sizingData: ProductSizing, selectedSizing: SelectedSizing, isSticky: boolean) => {
    this.showAndHighlightSelectSizeTooltip();
    this.props.validateDimensions(true);
    const firstInvalidDimensionName = ProductUtils.getMissingDimensionName(selectedSizing, sizingData);
    const missingDimension = firstInvalidDimensionName ? `${firstInvalidDimensionName}_dimension`.toUpperCase() : 'UNKNOWN_PRODUCT_DIMENSION';
    track(() => [
      evAddToCart,
      {
        ...style,
        incompleteAddToCart: true,
        missingDimension,
        addedFrom: PRODUCT_PAGE,
        isSticky
      }
    ]);
    // Do not scroll or show the alert dialog on desktop
    if (!isDesktop()) {
      if (this.buyBox) {
        this.buyBox.scrollIntoView(true);
      }
      // scroll into view is async, so we want the elements to be in view when they see this alert.
      setTimeout(() => {
        alert(`Please select ${indefiniteArticleSelector(firstInvalidDimensionName)} ${firstInvalidDimensionName}.`);
      }, 25);
    }
  };

  getStyleId = () => {
    const {
      product: { detail, colorId }
    } = this.props;
    if (detail) {
      const { styleId } = ProductUtils.getStyleByColor(detail?.styles, colorId);
      return styleId;
    } else {
      return;
    }
  };

  onStockChange(styleId: string, selectedSizing: SelectedSizing, { label }: { label: string | null; name?: string; selectedOption?: SizingValue }) {
    const {
      product: { detail },
      productSizeChanged,
      productSwatchChange,
      validateDimensions
    } = this.props;

    if (detail) {
      const { colorId } = ProductUtils.getStyleMap(detail.styles)[styleId]!;
      const { sizing } = detail;
      const style = ProductUtils.getStyleByColor(detail.styles, colorId);
      const stock = ProductUtils.getStockBySize(sizing.stockData, style.colorId, selectedSizing);

      if (!stock) {
        trackPageEvent(EventName.PDP_OUT_OF_STOCK);
      }

      if (label === 'color') {
        productSwatchChange(colorId);
        this.context.router.replacePreserveAppRoot(this.buildSeoUrl(detail, colorId));
      } else {
        productSizeChanged(selectedSizing);
        validateDimensions();
        if (ProductUtils.isSizeSelectionComplete(sizing, selectedSizing)) {
          this.unhighlightAndHideSelectSizeTooltip();
        }
      }
    }
  }

  onProductDescriptionToggle(e: React.MouseEvent<HTMLButtonElement>) {
    const { dataset } = e.target as HTMLElement;
    const { trackValue } = dataset;
    trackValue && this.props.toggleProductDescription(trackValue);
  }

  onProductDescriptionCollapsed(ref: React.RefObject<HTMLElement>) {
    this.props.onProductDescriptionCollapsed(ref);
  }

  onShowReportError = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { toggleReportAnErrorModal } = this.props;
    event.preventDefault();
    toggleReportAnErrorModal(true);
  };

  onRecoClicked({
    index,
    amethystRecoType,
    recoSource,
    recommendedProduct,
    clickThrough,
    sourcePage
  }: {
    index: number;
    amethystRecoType: string;
    recoSource?: string;
    recommendedProduct?: {} | null | undefined;
    clickThrough: string;
    sourcePage?: string;
  }) {
    track(() => [
      evRecommendationClick,
      {
        index,
        recommendationType: 'PRODUCT_RECOMMENDATION',
        recommendedProduct,
        recommendationSource: recoSource || 'EP13N',
        widgetType: amethystRecoType,
        clickThrough,
        sourcePage
      }
    ]);
  }

  openSizeChartModal = (content: SizeChartModalContent) => {
    this.setState({
      sizeChartModalProps: {
        content,
        isOpen: true,
        onRequestClose: this.closeSizeChartModal
      }
    });
  };

  closeSizeChartModal = () => {
    this.setState({ sizeChartModalProps: { isOpen: false } });
  };

  onOpenProductNotifyMe = (event: React.MouseEvent<HTMLButtonElement>) => {
    const {
      similarProductRecos: { janus },
      product: { detail },
      params: { productId, colorId },
      toggleProductNotifyModal,
      trackLegacyEvent
    } = this.props;

    event.preventDefault();
    trackLegacyEvent('Product-Page', 'OOS', 'Notify-Me');
    toggleProductNotifyModal(true);

    // Makes sure to only retrieve the recos once per product/style/color
    const productStyleId = (ProductUtils.getStyleByColor(detail?.styles || [], colorId) || {}).styleId;
    const recoKey = buildProductPageRecoKey(productId, productStyleId);
    const hasCFYSRecos = janus?.[recoKey]?.hasOwnProperty(getRecoSlotKey(RECOS_CANT_FIND_YOUR_SIZE_KEY));
    if (!hasCFYSRecos) {
      this.checkForRecos(productId, detail?.styles, { colorId }, true);
    }
  };

  onCloseProductNotifyMe = () => {
    this.props.toggleProductNotifyModal(false);
  };

  onOpenBrandNotify = (event: React.MouseEvent<HTMLAnchorElement>) => {
    const { toggleBrandNotifyModal } = this.props;
    event.preventDefault();
    toggleBrandNotifyModal(true);
  };

  onCloseBrandNotify = () => {
    this.props.toggleBrandNotifyModal(false);
  };

  onOutofStockPopoverShown = () => {
    const {
      params: { productId, colorId },
      product: { detail },
      fetchProductSearchSimilarity
    } = this.props;
    if (detail) {
      const { styles } = detail;
      const style = ProductUtils.getStyleByColor(styles, colorId);
      if (style) {
        fetchProductSearchSimilarity(productId, style.styleId);
      }
    }
  };

  showNoSizeSelected = (sizing: ProductSizing, selectedSizing: SelectedSizing) => {
    const { validateDimensions } = this.props;

    this.showAndHighlightSelectSizeTooltip();
    validateDimensions(true);
    const firstInvalidDimensionName = ProductUtils.getMissingDimensionName(selectedSizing, sizing);

    // Do not show the alert dialog on desktop
    if (!isDesktop()) {
      alert(`Please select a ${firstInvalidDimensionName}.`);
    }
  };

  handleThumbnailClick = () => {
    const { isSpotlightActive } = this.state;
    if (isSpotlightActive) {
      this.zoomOut();
    }
    trackLegacyEvent('Product-Page', 'PrImage', 'Thumbnail-Swap-Click');
  };

  handleProductImageClick = (
    lowResImageSrc: string,
    hiResImageSrc: string,
    lowResImageWidth: number,
    lowResImageHeight: number,
    mouseCoordinates: { pageX: number; pageY: number }
  ) => {
    this.setState(
      {
        isSpotlightActive: true,
        spotlightLowResImageSrc: lowResImageSrc,
        spotlightHiResImageSrc: hiResImageSrc,
        spotlightHiResImageWidth: lowResImageWidth * 4,
        spotlightHiResImageHeight: lowResImageHeight * 4,
        mouseCoordinates
      },
      () => {
        this.setHeightsAndOffsets();
      }
    );
  };

  setHeightsAndOffsets = () => {
    const {
      marketplace: {
        pdp: { spotlightProductImagesId }
      }
    } = this.context;
    const productImagesEl = document.querySelector(spotlightProductImagesId);
    const productImagesOffset = offset(productImagesEl);
    /**
     * Test case for spotlight zoom calculation (this fixes an issue with zoom feature not calculation properly)
     */
    const spotlightWrapperHeight = productImagesOffset?.height || 500;
    // Calculation fix (should always relies on the height of the container - which varies in position and size)
    // default min-height for the productImage container

    this.setState({ spotlightWrapperHeight }, () => {
      this.calcMovementRatios();
    });
  };

  handleSpotlightWrapperMouseMove = (event: React.MouseEvent) => {
    this.moveSpotlight(event);
  };

  zoomOut = () => {
    this.setState({
      spotlightLowResImageSrc: null,
      spotlightHiResImageSrc: null,
      spotlightHiResImageWidth: 0,
      spotlightHiResImageHeight: 0,
      spotlightWrapperHeight: 0,
      movementRatioX: 0,
      movementRatioY: 0,
      mouseCoordinates: {},
      isSpotlightActive: false
    });
  };

  isVideoSelected = (productAssetType: boolean) => this.setState({ isVideo: productAssetType });

  calcMovementRatios = () => {
    if (this.spotlightWrapper) {
      const { spotlightWrapperHeight } = this.state;
      const spotlightWrapperWidth = Math.abs(this.spotlightWrapper.offsetWidth);
      const { spotlightHiResImageWidth, spotlightHiResImageHeight, mouseCoordinates } = this.state;

      this.setState(
        {
          movementRatioX: (spotlightHiResImageWidth - spotlightWrapperWidth) / spotlightWrapperWidth,
          movementRatioY: (spotlightHiResImageHeight - spotlightWrapperHeight) / spotlightWrapperHeight
        },
        () => {
          this.moveSpotlight(mouseCoordinates);
        }
      );
    }
  };

  moveSpotlight = (event: React.MouseEvent) => {
    if (this.spotlight && this.spotlightWrapper) {
      const { movementRatioX, movementRatioY } = this.state;
      const { left: offsetLeft = 0, top: offsetTop = 0 } = offset(this.spotlightWrapper);
      const relativeX = event.pageX - offsetLeft;
      const relativeY = event.pageY - offsetTop;

      const spotlightLeft = relativeX * movementRatioX;
      const spotlightTop = relativeY * movementRatioY;

      this.spotlight.style.left = `${-spotlightLeft}px`;
      this.spotlight.style.top = `${-spotlightTop}px`;
    }
  };

  makeSpotlight = () => {
    const {
      isSpotlightActive,
      spotlightWrapperHeight,
      spotlightLowResImageSrc,
      spotlightHiResImageSrc,
      spotlightHiResImageWidth,
      spotlightHiResImageHeight
    } = this.state;
    const { testId } = this.context;

    const style: { height?: number; display?: string } = {};
    if (spotlightWrapperHeight) {
      style.height = spotlightWrapperHeight;
    } else {
      style.display = 'none';
    }

    return (
      <>
        <button
          type="button"
          ref={el => (this.spotlightWrapper = el)}
          style={style}
          className={cn(css.spotlightWrapper, {
            [css.active]: isSpotlightActive
          })}
          onMouseMove={this.handleSpotlightWrapperMouseMove}
          tabIndex={isSpotlightActive ? 0 : -1}
          data-test-id={testId('zoomedImageFrame')}
        >
          <div ref={el => (this.spotlight = el)} className={css.spotlight} data-test-id={testId('spotlightImageContainer')}>
            {spotlightLowResImageSrc && (
              <img
                src={spotlightLowResImageSrc}
                className={css.spotlightLowResImage}
                width={spotlightHiResImageWidth}
                height={spotlightHiResImageHeight}
                alt="presentation"
              />
            )}
            {spotlightHiResImageSrc && (
              <img
                src={spotlightHiResImageSrc}
                className={css.spotlightHiResImage}
                width={spotlightHiResImageWidth}
                height={spotlightHiResImageHeight}
                alt="presentation"
              />
            )}
          </div>
        </button>
        <button
          type="button"
          data-test-id={testId('spotlightClose')}
          className={css.spotClose}
          tabIndex={isSpotlightActive ? 0 : -1}
          aria-label="Zoom out of product image"
        />
      </>
    );
  };

  makeFavoritesButton(style: ProductStyle, sizing: ProductSizing, selectedSizing: SelectedSizing) {
    const {
      params: { productId },
      product: { detail },
      influencer: { isInfluencer, status: influencerStatus }
    } = this.props;
    const firstInvalidDimensionName = ProductUtils.getMissingDimensionName(selectedSizing, sizing);
    const missingDimension = firstInvalidDimensionName && `${firstInvalidDimensionName}_dimension`.toUpperCase();
    const productImages: FeaturedImage[] = getProductImagesFormatted(style?.images, detail?.defaultProductType);
    const {
      marketplace: { isInfluencerProgramEnabled }
    } = this.context;
    return (
      <AddToCollection>
        <SocialCollectionsWidget
          canAddNewCollection={true}
          shouldAddImmediately={true}
          showOnlyHeartCollection={isInfluencerProgramEnabled && isInfluencer && influencerStatus === InfluencerStatus.ACTIVE}
          getStyleId={this.getStyleId}
          productId={productId}
          colorId={style.colorId}
          price={style.price}
          missingDimension={missingDimension}
          sourcePage={PRODUCT_PAGE}
          productImages={productImages}
        />
      </AddToCollection>
    );
  }

  makeInfluencerSharingButtons() {
    const {
      product,
      params: { productId, colorId },
      obfuscatedCustomerId,
      influencer: { status: influencerStatus, influencerToken }
    } = this.props;

    return (
      <InfluencerAddToCollectionAndSharing
        product={product}
        productId={productId}
        colorId={colorId}
        influencerStatus={influencerStatus}
        influencerToken={influencerToken}
        obfuscatedCustomerId={obfuscatedCustomerId}
        getStyleId={this.getStyleId}
      />
    );
  }

  onSwatchStyleChosen(event: React.MouseEvent) {
    event.preventDefault();
    const { currentTarget } = event;
    const { dataset: { styleId } = { styleId: undefined } } = currentTarget as HTMLElement;
    const { isSpotlightActive } = this.state;
    const {
      product: { selectedSizing }
    } = this.props;
    styleId && this.onStockChange(styleId, selectedSizing, { label: 'color' });
    if (isSpotlightActive) {
      this.zoomOut();
    }
  }

  onHideSelectSizeTooltip() {
    const {
      product: { isSelectSizeTooltipHighlighted },
      hideSelectSizeTooltip
    } = this.props;
    if (!isSelectSizeTooltipHighlighted) {
      hideSelectSizeTooltip();
    }
  }

  makeProductNotifyMe = () => {
    const { testId } = this.context;

    const onClickHander = (event: React.MouseEvent<HTMLButtonElement>) => {
      track(() => [evCantFindYourSizeClick, {}]);
      this.onOpenProductNotifyMe(event);
    };

    return (
      <div>
        <button type="button" className={css.notifyMeButton} onClick={onClickHander} data-test-id={testId('notifyMe')}>
          Can't Find Your Size? Notify us.
        </button>
      </div>
    );
  };

  makeHeartsData = () => {
    const {
      marketplace: { hasHearting }
    } = this.context;
    const { isCustomer, heartProduct, toggleHeartingLoginModal, trackEvent, unHeartProduct } = this.props;

    const heartProps = {
      hasHearting,
      isCustomer,
      heartProduct,
      toggleHeartingLoginModal,
      trackEvent,
      unHeartProduct
    };

    const heartsData = getHeartProps(heartProps, {
      heartEventName: 'TE_PDP_HEART',
      unHeartEventName: 'TE_PDP_UNHEART'
    });

    return heartsData;
  };

  makeRecosFindYourSize = () => {
    const styles = this.props.product.detail?.styles || [];
    const style = ProductUtils.getStyleByColor(styles, this.props.params.colorId);
    const styleId = style && style.styleId;
    const heartsData = this.makeHeartsData();
    return (
      <RecosCantFindYourSize
        styleId={styleId}
        params={this.props.params}
        onRecoClicked={reco => {
          this.onRecoClicked({ ...reco, sourcePage: 'CANT_FIND_YOUR_SIZE_MODAL_PAGE' });
          this.onCloseProductNotifyMe();
        }}
        similarProductRecos={this.props.similarProductRecos}
        heartsData={heartsData}
        trackInViewSourcePage="CANT_FIND_YOUR_SIZE_MODAL_PAGE"
      />
    );
  };

  makeStylePicker({
    product,
    style,
    styleThumbnails,
    selectedSizing,
    dimensionValidation,
    sizingPredictionId,
    isOnDemandEligible
  }: {
    product: FormattedProductBundle;
    style: ProductStyle;
    styleThumbnails: StyleThumbnail[];
    selectedSizing: SelectedSizing;
    dimensionValidation: ProductDimensionValidation;
    sizingPredictionId: string | null;
    isOnDemandEligible: boolean | null | undefined;
  }) {
    const {
      location,
      symphonyStory: { slotData },
      product: { detail, isSelectSizeTooltipVisible, isSelectSizeTooltipHighlighted },
      hydraBelowAddToCartSlot,
      isGiftCard,
      showSelectSizeTooltip,
      influencer: { isInfluencer, status: influencerStatus },
      productSizeChanged,
      productAgeGroupChanged,
      productGenderChanged,
      productSingleShoeSideChanged,
      productSizeRangeChanged,
      productSizeUnitChanged,
      validateDimensions,
      isHydraColorLegend,
      isPdpPaperCutsFeatureEnabled
    } = this.props;

    const productIsShoe = ProductUtils.isShoeType(detail?.defaultProductType);
    const isUnsexKidProduct = ProductUtils.getGender(detail) === 'kids';
    const removeGenderFilter = !!productIsShoe && isUnsexKidProduct;

    const {
      marketplace: {
        isInfluencerProgramEnabled,
        features: { showOOSNotifyMe, allowTwoDayShippingPrimePerk },
        pdp: { addToCartAction, hasRecommendedSizing, showRewardsCopy, showSizeChartLink, showSizeGender }
      }
    } = this.context;
    if (style && detail) {
      const { defaultImageUrl, brandName, productName, styles } = detail;

      return (
        <div id="buyBox" className={cn(css.stylePicker, css.noBorder)} ref={c => (this.buyBox = c)}>
          <div className={cn({ [css.newStylePicker]: !isPdpPaperCutsFeatureEnabled }, css.whiteBg)}>
            <StylePicker
              makeProductNotifyMe={this.makeProductNotifyMe}
              styleList={styles}
              product={this.props.product}
              productId={product.productId}
              productType={product.defaultProductType}
              productImage={defaultImageUrl}
              productTitle={`${brandName} ${productName}`}
              sizing={product.sizing}
              genders={product.genders}
              hydraBelowAddToCartSlot={hydraBelowAddToCartSlot}
              selectedSizing={selectedSizing}
              selectedStyle={style}
              thumbnails={styleThumbnails}
              dimensionValidation={dimensionValidation.dimensions}
              onStockChange={this.onStockChange}
              onAddToCart={this.onAddToCart}
              onOpenProductNotifyMe={this.onOpenProductNotifyMe}
              addToCartAction={addToCartAction}
              allowTwoDayShippingPrimePerk={allowTwoDayShippingPrimePerk}
              isGiftCard={isGiftCard}
              isSelectSizeTooltipVisible={isSelectSizeTooltipVisible}
              isSelectSizeTooltipHighlighted={isSelectSizeTooltipHighlighted}
              onShowSelectSizeTooltip={showSelectSizeTooltip}
              onHideSelectSizeTooltip={this.onHideSelectSizeTooltip}
              onUnhighlightSelectSizeTooltip={this.unhighlightAndHideSelectSizeTooltip}
              showOosNotifyMe={showOOSNotifyMe}
              showSizeGender={showSizeGender}
              showSizeChartLink={showSizeChartLink}
              sizingPredictionId={sizingPredictionId}
              isOnDemandEligible={isOnDemandEligible}
              hasRecommendedSizing={hasRecommendedSizing}
              location={location}
              sizeSymphonyContent={slotData?.['buybox-size-1']}
              addToCartSymphonyContent={slotData?.['buybox-cart-1']}
              pageType={PRODUCT_PAGE}
              useDropdowns={false}
              useStickyAddToCart={true}
              productSizeChanged={productSizeChanged}
              productAgeGroupChanged={productAgeGroupChanged}
              productGenderChanged={productGenderChanged}
              productSingleShoeSideChanged={productSingleShoeSideChanged}
              productSizeRangeChanged={productSizeRangeChanged}
              productSizeUnitChanged={productSizeUnitChanged}
              validateDimensions={validateDimensions}
              isHydraColorLegend={isHydraColorLegend}
              removeGenderFilter={removeGenderFilter}
            />
            {showRewardsCopy && (
              <div className={css.rewardsCopy}>
                Join Zappos Rewards & get Free 2-Business Day shipping.
                <Link to="/zappos-rewards/" data-track-action="Product-Page" data-track-label="PrForm" data-track-value="Rewards">
                  Enroll now
                </Link>
              </div>
            )}
          </div>
          {isInfluencerProgramEnabled && isInfluencer && influencerStatus === InfluencerStatus.ACTIVE && this.makeInfluencerSharingButtons()}
        </div>
      );
    } else {
      return;
    }
  }

  onBack = (e: React.MouseEvent) => {
    e.preventDefault();
    this.context.router.goBack();
  };

  makeOutOfStock = () => {
    const {
      product: { detail, similarStyles, selectedSizing },
      brandPage,
      params,
      submitNotifyBrandEmail,
      productNotify,
      isHydraCefiEnabled
    } = this.props;
    const {
      marketplace: {
        pdp: { showOutOfStockPopover },
        features: { showOOSNotifyMe, showUnifiedOOS }
      }
    } = this.context;
    const {
      notifyEmail: { submitted }
    } = brandPage;
    if (detail) {
      const { productId, brandId, brandName, styles, sizing } = detail;
      const productStyles = ProductUtils.getStyleMap(styles);
      const style = ProductUtils.getStyleByColor(styles, params.colorId);
      const stock = ProductUtils.getStockBySize(sizing.stockData, style.colorId, selectedSizing);

      // Different marketplaces show different popups when stock is not available
      if (showOutOfStockPopover) {
        return (
          <OutOfStockPopoverWrapper
            brandId={brandId}
            brandName={brandName}
            recos={similarStyles}
            stock={stock}
            isSubmitted={submitted}
            onBrandNotifySubmit={submitNotifyBrandEmail}
            onShow={this.onOutofStockPopoverShown}
          />
        );
      } else if (showOOSNotifyMe) {
        return (
          <OutOfStockModalWrapper
            detail={detail}
            productId={productId}
            style={style}
            sizing={sizing}
            selectedSizing={selectedSizing}
            productStyles={productStyles}
            onOpenProductNotifyMe={this.onOpenProductNotifyMe}
            isProductNotifyOpen={productNotify.modalShown}
            onCloseProductNotifyMe={this.onCloseProductNotifyMe}
            onStyleChange={this.onSwatchStyleChosen}
            showUnifiedOOS={showUnifiedOOS}
            renderRecos={this.makeRecosFindYourSize}
            isHydraCefiEnabled={isHydraCefiEnabled}
          />
        );
      } else {
        return;
      }
    } else {
      return null;
    }
  };

  makeBrandLogo({ brand }: { brand: ProductBrand }, secureImageBaseUrl: string) {
    const { id, name, headerImageUrl } = brand;
    const { testId } = this.context;
    if (headerImageUrl) {
      const imgProps = {
        src: `${secureImageBaseUrl}${headerImageUrl}`,
        alt: name,
        itemProp: 'logo'
      };
      const placeholder = <div className={css.brandPlaceholder} />;
      return (
        <Link
          className={css.brandNameLink}
          to={buildSeoBrandString(name, id)}
          title={name}
          data-track-action="Product-Page"
          data-track-label="Tabs"
          data-track-value="Brand-Logo"
          data-test-id={testId('brandLogo')}
        >
          <ImageLazyLoader imgProps={imgProps} placeholder={placeholder} />
        </Link>
      );
    } else {
      return (
        <p className={css.brandName}>
          <Link to={buildSeoBrandString(name, id)}>{name}</Link>
        </p>
      );
    }
  }

  handleReviewMediaClick = (reviewId: string, mediaIndex: number) => {
    this.props.showReviewGalleryModal(reviewId, mediaIndex);
  };

  makeAsk() {
    const {
      marketplace: {
        features: { showAsk }
      },
      testId
    } = this.context;
    const { params, product } = this.props;
    if (showAsk) {
      return (
        <div className={cn(css.askContainer, css.fullWidth)} data-test-id={testId('askContainer')}>
          <LazyAsk product={product} params={params} />
        </div>
      );
    }
    return null;
  }

  makeReviewPhotoGallery = () => {
    const {
      context: {
        marketplace: {
          features: { showReviews }
        }
      },
      props: { reviewGallery },
      handleReviewMediaClick
    } = this;

    if (!showReviews || !shouldRenderReviewGallery(reviewGallery)) {
      return null;
    }

    return (
      <ReviewPhotoGallery
        divClass={cn(css.reviewPhotoGallery, css.fullWidth)}
        includeHr={true}
        reviewGallery={reviewGallery}
        limit={10}
        onOpenMediaReview={handleReviewMediaClick}
        showMediaCount={false}
      />
    );
  };

  makeZapposUiAccordion = ({ heading, children }: { heading: string; children: React.ReactElement }) => {
    const { testId } = this.context;

    return (
      <ZapposUiAccordion key={`accordion-${heading}`} type="single" collapsible defaultValue={heading}>
        <ZapposUiAccordionItem value={heading}>
          <div className={css.accordionTitle}>
            <AccordionTrigger data-test-id={testId(`footerAccordionTrigger-${heading?.replace(/\s/g, '-')}`)}>{heading}</AccordionTrigger>
          </div>
          <AccordionContent>{children}</AccordionContent>
        </ZapposUiAccordionItem>
      </ZapposUiAccordion>
    );
  };

  makeHighlightsAccordionSection = () => {
    const { params, product, forKidsProductCallout, rewardsBrandPromos = {}, isHydraCefiEnabled } = this.props;
    const {
      marketplace: {
        pdp: { showProductCallout },
        hasRewardsTransparency
      }
    } = this.context;
    const { detail } = product;

    if (
      detail &&
      ProductUtils.hasHighlightsAccordionSection({
        colorId: params.colorId,
        styles: detail.styles,
        brandId: detail.brandId,
        rewardsBrandPromos,
        showProductCallout,
        hasRewardsTransparency
      })
    ) {
      const { styles, brandId } = detail;

      const style = ProductUtils.getStyleByColor(styles, params.colorId);
      const attributes = style.taxonomyAttributes;

      return isHydraCefiEnabled ? (
        this.makeZapposUiAccordion({
          heading: HIGHLIGHTS_ACCORDION_HEADING,
          children: (
            <ProductCallout
              brandId={brandId}
              rewardsBrandPromos={rewardsBrandPromos}
              attributes={attributes}
              forKidsProductCallout={forKidsProductCallout}
              useTabbableTooltips
              useTooltipOverlay={false}
            />
          )
        })
      ) : (
        <AccordionItem key="Highlights" heading={HIGHLIGHTS_ACCORDION_HEADING} accordionTestId="Highlights">
          <ProductCallout
            brandId={brandId}
            rewardsBrandPromos={rewardsBrandPromos}
            attributes={attributes}
            forKidsProductCallout={forKidsProductCallout}
            useTabbableTooltips
            useTooltipOverlay={false}
          />
        </AccordionItem>
      );
    }
    return null;
  };

  makeSizeChartAccordionSection = () => {
    const {
      product: { detail },
      isHydraCefiEnabled
    } = this.props;

    const {
      marketplace: {
        pdp: { showDescriptionSizeChart }
      }
    } = this.context;

    const trackViewSizeChart = () => {
      track(() => [evViewSizeChart, {}]);
    };

    if (
      detail &&
      ProductUtils.hasSizeChartAccordionSection({
        defaultProductType: detail.defaultProductType,
        description: detail.description,
        showDescriptionSizeChart
      })
    ) {
      return isHydraCefiEnabled ? (
        this.makeZapposUiAccordion({
          heading: SIZE_CHART_ACCORDION_HEADING,
          children: (
            <ul className={css.accordionContent}>
              <SizeCharts
                descriptionItems={detail.description!}
                openSizeChartModal={this.openSizeChartModal}
                productType={detail.defaultProductType}
                trackViewSizeChart={trackViewSizeChart}
              />
            </ul>
          )
        })
      ) : (
        <AccordionItem key="SizeChart" heading={SIZE_CHART_ACCORDION_HEADING} accordionTestId="SizeChart">
          <ul>
            <SizeCharts
              descriptionItems={detail.description!}
              openSizeChartModal={this.openSizeChartModal}
              productType={detail.defaultProductType}
              trackViewSizeChart={trackViewSizeChart}
            />
          </ul>
        </AccordionItem>
      );
    }

    return null;
  };

  makeItemInformationAccordionSection = () => {
    const {
      params,
      product,
      secureImageBaseUrl,
      isProductTypeShoesOrClothing,
      productCardGenderDisplay,
      isHydraCefiEnabled,
      isPdpAccordionOrder,
      isPdpPaperCutsFeatureEnabled
    } = this.props;

    const {
      marketplace: {
        features: { showReviews }
      }
    } = this.context;

    const { detail, isDescriptionExpanded } = product;

    const isLoaded = ProductUtils.isProductDataLoaded(product, params);

    if (!isLoaded) {
      return <PageLoader />;
    }

    if (!detail) {
      return null;
    }

    const { defaultProductType, description, productId } = detail;

    const isProductDescriptionExpanded = !!isDescriptionExpanded;
    const productInfoRef = React.createRef<HTMLElement>();

    return isHydraCefiEnabled && !isPdpAccordionOrder ? (
      this.makeZapposUiAccordion({
        heading: 'Product Information',
        children: (
          <div className={cn(css.fullWidth, css.accordionContent)}>
            <ExpandableProductDescription
              productId={productId}
              defaultProductType={defaultProductType}
              descriptionItems={description}
              allowCollapse={showReviews}
              isExpanded={isProductDescriptionExpanded}
              focusableRef={productInfoRef}
              onCollapse={this.onProductDescriptionCollapsed}
              onToggle={this.onProductDescriptionToggle}
              onReportError={this.onShowReportError}
              brandLogo={this.makeBrandLogo(detail, secureImageBaseUrl)}
              isProductTypeShoesOrClothing={isProductTypeShoesOrClothing}
              productCardGenderDisplay={productCardGenderDisplay}
              isPdpPaperCutsFeatureEnabled={isPdpPaperCutsFeatureEnabled}
            />
          </div>
        )
      })
    ) : (
      <AccordionItem innerRef={productInfoRef} key="ProductInfo" heading="Product Information" accordionTestId="ProductInfo">
        <div className={css.fullWidth}>
          <ExpandableProductDescription
            productId={productId}
            defaultProductType={defaultProductType}
            descriptionItems={description}
            allowCollapse={showReviews}
            isExpanded={isProductDescriptionExpanded}
            focusableRef={productInfoRef}
            onCollapse={this.onProductDescriptionCollapsed}
            onToggle={this.onProductDescriptionToggle}
            onReportError={this.onShowReportError}
            brandLogo={this.makeBrandLogo(detail, secureImageBaseUrl)}
            isProductTypeShoesOrClothing={isProductTypeShoesOrClothing}
            productCardGenderDisplay={productCardGenderDisplay}
            isPdpPaperCutsFeatureEnabled={isPdpPaperCutsFeatureEnabled}
          />
        </div>
      </AccordionItem>
    );
  };

  makeProductAccordion = () => {
    const { isPdpAccordionOrder } = this.props;
    const { testId } = this.context;
    const accordionSections: React.ReactElement<AccordionItemProps>[] = [];
    const highlights = this.makeHighlightsAccordionSection();
    const itemInfo = this.makeItemInformationAccordionSection();
    const sizeChart = this.makeSizeChartAccordionSection();

    if (isPdpAccordionOrder) {
      if (itemInfo) {
        accordionSections.push(itemInfo);
      }

      if (sizeChart) {
        accordionSections.push(sizeChart);
      }

      if (highlights) {
        accordionSections.push(highlights);
      }
    } else {
      if (highlights) {
        accordionSections.push(highlights);
      }

      if (sizeChart) {
        accordionSections.push(sizeChart);
      }

      if (itemInfo) {
        accordionSections.push(itemInfo);
      }
    }

    return (
      <div className={css.accordionContainer} data-test-id={testId('accordionContainer')}>
        {accordionSections.length > 0 && (
          <Accordion defaultOpenAll openMultiple>
            {accordionSections}
          </Accordion>
        )}
      </div>
    );
  };

  onBuyBoxPageContentClick = (e: React.MouseEvent) => {
    const { trackEvent } = this.props;
    const eventTarget = e.target as HTMLElement;
    if (eventTarget.matches('[data-pagecontent-id="pdp-buybox"] a')) {
      e.nativeEvent.stopImmediatePropagation();
      trackEvent('TE_PDP_BUYBOX_CONTENT_CLICK', eventTarget.textContent);
    }
  };

  onSymphonyComponentClick = (e: React.MouseEvent) => {
    // Mostly taken from Landing.jsx container
    const {
      symphonyStory: { productId },
      trackLegacyEvent
    } = this.props;
    const { currentTarget } = e;
    const {
      dataset: { eventlabel, eventvalue, slotindex }
    } = currentTarget as HTMLElement;
    const action = `Detail-${productId}`;
    const label = stripSpecialCharsDashReplace(eventlabel);
    const value = stripSpecialCharsDashReplace(eventvalue);
    const slotIndex = stripSpecialCharsDashReplace(slotindex);

    e.stopPropagation();
    trackLegacyEvent(action, label || null, value);
    trackEvent('TE_PDP_STORIES_CLICK', `${slotIndex}:${label}:${value}`);
  };

  makeSponsoredAdClick = (product: Product, index: number) => {
    const { productId, styleId, colorId } = product;
    return () => {
      track(() => [
        evRecommendationClick,
        {
          index,
          recommendationType: 'PRODUCT_RECOMMENDATION',
          productIdentifiers: {
            productId,
            styleId,
            colorId
          },
          recommendationSource: 'MICROSOFT',
          widgetType: 'MICROSOFT_TOP_BLOCK',
          sourcePage: 'PRODUCT_PAGE'
        }
      ]);
    };
  };

  trackPageView = (detail: FormattedProductBundle) => {
    const {
      marketplace: {
        hasRewardsTransparency,
        pdp: { showProductCallout, showDescriptionSizeChart }
      }
    } = this.context;

    const {
      params: { colorId },
      rewardsBrandPromos = {}
    } = this.props;

    const { brandId, defaultProductType, description, styles } = detail;

    const style = ProductUtils.getStyleByColor(styles, colorId);
    const productViewData = ProductUtils.getProductViewData(style, detail);

    // If has the accordion, add its key to the accordion impression event
    const accordions: { accordionState: boolean; headerName: string }[] = [];
    ProductUtils.hasHighlightsAccordionSection({
      brandId,
      colorId,
      hasRewardsTransparency,
      rewardsBrandPromos,
      showProductCallout,
      styles
    }) && accordions.push({ accordionState: true, headerName: HIGHLIGHTS_ACCORDION_HEADING });
    ProductUtils.hasSizeChartAccordionSection({
      defaultProductType,
      description,
      showDescriptionSizeChart
    }) && accordions.push({ accordionState: true, headerName: SIZE_CHART_ACCORDION_HEADING });

    track(() => [pvProduct, { ...productViewData, accordions }]);
  };

  trackLowestRecentPriceView = () => {
    const {
      params: { colorId },
      product
    } = this.props;

    const {
      marketplace: {
        pdp: { hasLowestRecentPrice }
      }
    } = this.context;

    if (!product.detail) {
      return;
    }

    const { productId, styles } = product.detail;
    const style = ProductUtils.getStyleByColor(styles, colorId);
    const styleId = style && style.styleId;
    const isLowestRecentPrice = ProductUtils.isProductLowestRecentPrice(hasLowestRecentPrice, styleId, style, product.lowestPrices);

    if (isLowestRecentPrice) {
      track(() => [evProductLowestRecentPrice, { productId, styleId }]);
    }
  };

  render() {
    const {
      params,
      product,
      reportAnError,
      reviewGallery,
      productNotify,
      location,
      numberOfReviews,
      numberOfAskQuestions,
      similarProductRecos,
      metaDescription,
      symphonyStory: { stories, loadingSymphonyStoryComponents },
      obfuscatedCustomerId,
      influencer,
      isHydraRecoDrawer,
      isHydraPhotoAngles,
      isProductTypeShoesOrClothing,
      productCardGenderDisplay,
      isHydraCefiEnabled,
      isPdpPaperCutsFeatureEnabled
    } = this.props;

    const {
      marketplace: {
        search: { hasSponsoredAds },
        recos: { showCustomersWhoViewedThisItemAlsoViewed, showSimilarItemsYouMayLike },
        pdp: { percentOffText, showPercentOffBanner, showFitSurvey, hasLowestRecentPrice },
        features: { showReviews, showMelodyShippingAndReturnsBanner },
        name
      },
      testId
    } = this.context;

    const { sizeChartModalProps, isVideo } = this.state;

    const { brandPromo, detail, styleThumbnails, selectedSizing, validation, sizingPredictionId, isOnDemandEligible } = product;

    const { hash = '' } = location;

    const isLoaded = ProductUtils.isProductDataLoaded(product, params);

    if (!isLoaded) {
      return <PageLoader />;
    }

    if (!detail) {
      return null;
    }

    const {
      defaultProductType,
      productId,
      styles,
      sizing,
      videos,
      sizeFit,
      widthFit,
      archFit,
      reviewSummary,
      reviewCount,
      productRating,
      youtubeVideoId,
      isWearable,
      isReviewableWithMedia,
      youtubeData: { embedUrl, contentUrl, videoName, thumbnailUrl, uploadDate }
    } = detail;

    const isZappos = name === 'zappos.com';
    const showProductSku = isZappos && (!productCardGenderDisplay || !isProductTypeShoesOrClothing);

    const firstInStock = sizing.stockData.find(stock => parseInt(stock.onHand) > 0);

    const style = ProductUtils.getStyleByColor(styles, params.colorId);
    const styleId = style && style.styleId;
    const multiviewImages = ProductUtils.buildAngleThumbnailImages(style, 700, 525);
    const isYouTubeVideo = !!youtubeVideoId;
    const heartsData = this.makeHeartsData();

    const janusPixelQueryParams = {
      item: productId,
      teen: styleId,
      widget: 'RecordViewedItem'
    };
    const { images, badges, finalSale } = style;

    const stock = ProductUtils.getStockBySize(sizing.stockData, style.colorId, selectedSizing);
    const { onHand } = (stock as ProductStockData) || {};
    const showRecoDrawer = isHydraRecoDrawer && getJanusRecos(similarProductRecos);

    const onBadgeCategoryClick = () => {
      track(() => [evBadgeCategoryClick, { badges, styleId }]);
    };

    const isLowestRecentPrice = ProductUtils.isProductLowestRecentPrice(hasLowestRecentPrice, styleId, style, product.lowestPrices);

    const hasMostHelpfulReviews = shouldRenderMostHelpfulReviews(reviewSummary);

    return (
      <ProductContextProvider value={product}>
        <SiteMetadata loading={!isLoaded}>
          {/* data-pdp-style-id is to allow marketing to easily access the style-id */}
          <div data-pdp-style-id={styleId} className={css.wrap}>
            <GamSlot slot={PDP_WIDE_TOP} />
            <GamSlot slot={PDP_NARROW_TOP} />
            <ProductBreadcrumbs product={detail} onBack={this.onBack} />
            <div
              ref={el => (this.theater = el)}
              className={css.theater}
              itemScope
              itemType="http://schema.org/Product"
              data-test-id={testId('productDetail')}
            >
              <div className={css.primaryWrapper}>
                <div className={css.productStage} id="productRecap">
                  <div className={cn(css.stickyProductContainer)}>
                    <div id="stage" className={css.productMain}>
                      {multiviewImages && multiviewImages.length && (
                        <>
                          {badges?.length > 0 && (
                            <Badge
                              id={badges[0]!.bid}
                              category={badges[0]?.zc}
                              url={badges[0]?.url}
                              classNameWrapper={css.productDetailBadgeRelative}
                              onBadgeCategoryClick={onBadgeCategoryClick}
                            />
                          )}
                          {this.makeFavoritesButton(style, sizing, selectedSizing)}
                          <div className={css.productGalleryWrapper}>
                            <ProductGallery
                              style={style}
                              product={detail}
                              productVideos={videos}
                              isYouTubeVideo={isYouTubeVideo}
                              youtubeSrc={embedUrl}
                              isVideoSelected={this.isVideoSelected}
                              imageHasBadge={badges?.length > 0}
                              isHydraPhotoAngles={isHydraPhotoAngles}
                              imageChildren={
                                showRecoDrawer && (
                                  <RecoDrawer buttonClassName={css.recoDrawerButton} isVideo={isVideo} focusRef={recosDetail1Ref} isMobileOnly>
                                    <RecosDetail3
                                      styleId={styleId}
                                      params={params}
                                      onRecoClicked={this.onRecoClicked}
                                      similarProductRecos={similarProductRecos}
                                      heartsData={heartsData}
                                      numberOfGridColumns={2}
                                    />
                                  </RecoDrawer>
                                )
                              }
                            />
                          </div>
                          <div className={css.galleryWrapper}>
                            <Gallery
                              images={images}
                              styleId={styleId}
                              product={detail}
                              isHydraPhotoAngles={isHydraPhotoAngles}
                              imageChildren={
                                showRecoDrawer && <RecoDrawer buttonClassName={css.recoDrawerButton} focusRef={recosDetail1Ref} isMobileOnly />
                              }
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      className={cn(css.productForm, { ['px-2 lg:ml-6 lg:px-0']: isPdpPaperCutsFeatureEnabled })}
                      data-test-id={testId('buyBoxContainer')}
                    >
                      <div className={cn(css.wing, { ['flex flex-col gap-y-6']: isPdpPaperCutsFeatureEnabled })}>
                        <div className={cn(isPdpPaperCutsFeatureEnabled ? 'flex flex-col gap-y-6' : css.wingInfo)}>
                          <div>
                            <ProductName
                              showSocialLinks={true}
                              selectedSizing={selectedSizing}
                              product={detail}
                              colorId={style.colorId}
                              obfuscatedCustomerId={obfuscatedCustomerId}
                              influencer={influencer}
                              productCardGenderDisplay={productCardGenderDisplay}
                              isProductTypeShoesOrClothing={isProductTypeShoesOrClothing}
                              isPdpPaperCutsFeatureEnabled={isPdpPaperCutsFeatureEnabled}
                            />
                            {showProductSku && (
                              <div className="md:text-center lg:text-left">
                                <ProductSkuNumber productId={productId} />
                              </div>
                            )}
                          </div>
                          <div className={cn(isPdpPaperCutsFeatureEnabled ? 'flex flex-col gap-y-2' : 'mt-5')}>
                            <Price
                              productStyle={style}
                              percentOffText={percentOffText}
                              showPercentOffBanner={showPercentOffBanner}
                              defaultProductType={defaultProductType}
                              isAvailable={+onHand > 0}
                              isLowestRecentPrice={isLowestRecentPrice}
                            />
                            {showReviews && parseInt(reviewCount) > 0 && (
                              <div className={cn(css.reviewSummaryContainer, { ['mt-6']: !isPdpPaperCutsFeatureEnabled })}>
                                <BuyBoxReviewBlurb hasMostHelpfulReviews={hasMostHelpfulReviews} numReviews={reviewCount} rating={productRating} />
                              </div>
                            )}
                          </div>
                        </div>
                        {styleThumbnails &&
                          this.makeStylePicker({
                            product: detail,
                            style,
                            styleThumbnails,
                            selectedSizing,
                            dimensionValidation: validation,
                            sizingPredictionId,
                            isOnDemandEligible
                          })}
                        {this.makeProductAccordion()}
                        <div className={css.gamSlotContainer}>
                          <GamSlot slot={PDP_WIDE_MID} />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* who viewed also viewed */}
                  <div data-test-id={testId('sideRecoContainer')}>
                    <RecosDetail1
                      styleId={styleId}
                      numberOfAskQuestions={numberOfAskQuestions}
                      numberOfReviews={numberOfReviews}
                      params={params}
                      onRecoClicked={this.onRecoClicked}
                      similarProductRecos={similarProductRecos}
                      heartsData={heartsData}
                      titleRef={recosDetail1Ref}
                    />
                  </div>

                  {/* styling ideas */}
                  {!isDesktop() && <RecosSection currentStyle={style} shouldShowRecosCardV3={false} />}

                  {/* how they compare */}
                  {product.detail && ProductUtils.isComparableProduct(product.detail) && (
                    <ProductComparisonTable styleId={styleId} productId={productId} />
                  )}

                  <meta itemProp="category" content={defaultProductType} />

                  {/* wear it with */}
                  {makeWearItWithFragment(similarProductRecos, this.onRecoClicked, isHydraCefiEnabled)}

                  {/* discover more items */}
                  {hasSponsoredAds && firstInStock?.id && (
                    <PdpSponsoredAds
                      containerClassName={cn(css.sponsoredAdsContainer, isHydraCefiEnabled && '!p-0')}
                      keywords={[defaultProductType]}
                      stockId={firstInStock.id}
                      styleId={styleId}
                    />
                  )}

                  {/* history */}
                  {!!stories?.length && !loadingSymphonyStoryComponents && (
                    <div className={cn(css.symphonyStoryContainer, css.fullWidth)}>
                      {stories.map((slotData, slotIndex) => (
                        <LandingSlot
                          key={slotData.slotName}
                          slotName={slotData.slotName}
                          slotIndex={slotIndex}
                          data={slotData}
                          onComponentClick={this.onSymphonyComponentClick}
                          shouldLazyLoad={true}
                        />
                      ))}
                    </div>
                  )}

                  {/* recommended for you */}
                  <div className={cn(css.descriptionWrapper, css.fullWidth)}>
                    <GamSlot slot={PDP_NARROW_MID} />
                    <RecosDetail2
                      styleId={styleId}
                      params={params}
                      onRecoClicked={this.onRecoClicked}
                      similarProductRecos={similarProductRecos}
                      heartsData={heartsData}
                    />
                  </div>

                  {this.makeAsk()}
                  <HappyFeatureFeedback
                    additionalFeedbackMessage="Please tell us more about your experience"
                    className={cn(css.feedback, css.fullWidth)}
                    completionMessage="Thank you for your feedback!"
                    feedbackQuestion="Was this page helpful?"
                    feedbackType="PRODUCT_PAGE_EXPERIENCE_FEEDBACK"
                    pageType={PRODUCT_PAGE}
                    source="pdp"
                  />
                  {showReviews && isReviewableWithMedia && isWearable && (
                    <LazyHowItWasWorn pageType={PRODUCT_PAGE} productId={productId} colorId={params.colorId} productName={detail.productName} />
                  )}
                  {showFitSurvey && ProductUtils.isShoeType(defaultProductType) && (
                    <FitSurvey sizeFit={sizeFit} widthFit={widthFit} archFit={archFit} reviewSummary={reviewSummary} />
                  )}
                  {showReviews ? <ReviewPreview params={params} onReviewMediaClick={this.handleReviewMediaClick} /> : null}
                  {showReviews && shouldRenderReviewGallery(reviewGallery) ? (
                    <ReviewGalleryWrapper params={params} returnUrl={buildSeoProductUrl(detail)} />
                  ) : null}
                  {this.makeReviewPhotoGallery()}
                  <div className={css.outOfStock}>{this.makeOutOfStock()}</div>
                </div>
              </div>
              {showCustomersWhoViewedThisItemAlsoViewed && (
                <RecosDetail3
                  styleId={styleId}
                  params={params}
                  onRecoClicked={this.onRecoClicked}
                  similarProductRecos={similarProductRecos}
                  heartsData={heartsData}
                />
              )}
              {isYouTubeVideo && (
                <StructuredVideoObject
                  name={videoName}
                  embedUrl={embedUrl}
                  contentUrl={contentUrl}
                  description={metaDescription}
                  thumbnailUrl={thumbnailUrl}
                  uploadDate={uploadDate}
                />
              )}
            </div>
            {showSimilarItemsYouMayLike && (
              <RecosDetail3
                styleId={styleId}
                params={params}
                onRecoClicked={this.onRecoClicked}
                similarProductRecos={similarProductRecos}
                heartsData={heartsData}
              />
            )}
            {brandPromo && brandPromo.hasOwnProperty('type') ? <BrandPromo data={brandPromo} /> : null}
            {showMelodyShippingAndReturnsBanner && !finalSale && <ShippingAndReturnsBanner />}
          </div>
          {productNotify.modalShown && (
            <ProductNotifyModal
              isOpen={productNotify.modalShown}
              onClose={this.onCloseProductNotifyMe}
              product={detail}
              productId={productId}
              colorId={params.colorId}
              selectedSizing={selectedSizing}
              renderRecos={() => this.makeRecosFindYourSize()}
            />
          )}

          {productNotify.brandModalShown && (
            <BrandStylesNotifyModal
              isOpen={productNotify.brandModalShown}
              onClose={this.onCloseBrandNotify}
              brandId={detail.brandId}
              brandName={detail.brandName}
            />
          )}
          {reportAnError.modalShown && (
            <ReportAnErrorModal productId={productId} colorId={style && style.colorId} isOpen={reportAnError.modalShown} hash={hash} />
          )}
          <SizeChartModal {...sizeChartModalProps} />
          <JanusPixel location={location} queryParams={janusPixelQueryParams} />
          <UnleashWiringTest />
        </SiteMetadata>
      </ProductContextProvider>
    );
  }
}

export function mapStateToProps(state: AppState, ownProps: any) {
  const isCustomer = !!(ExecutionEnvironment.canUseDOM && state.cookies['x-main']);
  const isPdpAccordionOrder = selectIsPdpAccordionOrder(state);
  const isPdpPaperCutsFeatureEnabled = selectIsFeaturePdpPaperCuts(state);

  const {
    cart: { isModalShowing: isCartModalShowing },
    cookies,
    client,
    reviews: { reviewGallery },
    sharing: { productNotify, reportAnError, linkShare },
    ask,
    brandPage,
    environmentConfig,
    holmes,
    killswitch: { isShowingThirdPartyAds, forKidsProductCallout },
    meta,
    pageLoad: { loaded: pageLoaded },
    pageView,
    product: productState,
    products,
    recos: janusRecos,
    router,
    sharedRewards: { transparencyPointsForItem },
    url,
    influencer,
    influencerContent: { contents: brandContents },
    ads: { adCustomerId, adEmailHash }
  } = state;

  const { inParams, match: { params = {} } = {} } = ownProps || {};

  const recos: RecosState = janusRecos;
  const product: ProductDetailState = productState;
  const metaDescription = meta.documentMeta?.meta?.name?.description || ''; // to use as the SEO description for video as Broadway used to
  const customerFirstName = (holmes && holmes.firstName) || '';
  const { imageServer } = environmentConfig;
  const testAssignments = {} as Record<keyof typeof TEST_PROP_TO_NAME, boolean>;
  Object.keys(TEST_PROP_TO_NAME).map(prop => {
    const propName = prop as keyof typeof TEST_PROP_TO_NAME;
    testAssignments[propName] = isAssigned(TEST_PROP_TO_NAME[propName], 1, state);
  });

  const isProductTypeShoesOrClothing = ProductUtils.isProductTypeShoesOrClothing(product?.detail?.defaultProductType);
  const productCardGenderDisplay = ProductUtils.getProductGender(product?.detail?.genders);

  const { brandPromos: rewardsBrandPromos } = transparencyPointsForItem || {};
  const obfuscatedCustomerId = cookies['x-main'];
  return {
    params: inParams ?? params,
    isProductTypeShoesOrClothing,
    productCardGenderDisplay,
    brandPage,
    isCustomer,
    obfuscatedCustomerId,
    customerFirstName,
    isCartModalShowing,
    isShowingThirdPartyAds,
    forKidsProductCallout,
    ...testAssignments,
    metaDescription,
    numberOfAskQuestions: getNumberOfAskQuestions(ask),
    numberOfReviews: ProductUtils.getNumberOfReviews(product),
    sessionId: cookies['session-id'] || '',
    similarProductRecos: recos,
    pageLoaded,
    pageView,
    product,
    products,
    productNotify,
    reportAnError,
    reviewGallery,
    rewardsBrandPromos,
    secureImageBaseUrl: imageServer.url,
    symphonyStory: selectSymphonyStory(state),
    url,
    isGiftCard: ProductUtils.isGiftCard(product?.detail?.defaultProductType),
    influencer,
    linkShare,
    client,
    isHydraRecoDrawer: isAssigned(HYDRA_RECO_DRAWER, 1, state),
    brandContents,
    adCustomerId,
    adEmailHash,
    holmes,
    router,
    isHydraCefiEnabled: isAssigned(HYDRA_CORE_EXPERIENCE_FUNCTIONALITY_IMPROVEMENTS, 1, state),
    isPdpAccordionOrder,
    isPdpPaperCutsFeatureEnabled
  };
}

export const mapDispatchToProps = {
  addAdToQueue,
  changeQuantity,
  loadProductDetailPage,
  fetchProductSearchSimilarity,
  fetchProductPageRecos,
  fetchProductReviews,
  fetchProductReviewsWithMedia,
  fetchSizingPrediction,
  fetchBrandPromo,
  getPdpStoriesSymphonyComponents,
  heartProduct,
  hideReviewGalleryModal,
  unHeartProduct,
  pageTypeChange,
  productSizeChanged,
  pushMicrosoftUetEvent,
  toggleProductDescription,
  onProductDescriptionCollapsed,
  submitNotifyBrandEmail,
  showSelectSizeTooltip,
  hideSelectSizeTooltip,
  highlightSelectSizeTooltip,
  unhighlightSelectSizeTooltip,
  validateDimensions,
  setLastSelectedSize,
  setProductDocMeta,
  stockSelectionCompleted,
  toggleBrandNotifyModal,
  toggleHeartingLoginModal,
  toggleOosButton,
  toggleProductNotifyModal,
  toggleReportAnErrorModal,
  showReviewGalleryModal,
  showCartModal,
  triggerAssignment,
  productSwatchChange,
  getHeartCounts,
  getHearts,
  getProductRelations,
  onLookupRewardsTransparencyPointsForItem,
  handleGetInfluencerToken,
  handleGetInfluencerStatus,
  productAgeGroupChanged,
  productGenderChanged,
  productSingleShoeSideChanged,
  productSizeRangeChanged,
  productSizeUnitChanged,
  fetchAccountInfo,
  fetchCustomerAuthDetails,
  updateAdData,
  fetchLowestPrices,
  selectedColorChanged
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ProductDetail);
