// This file was generated with `npm run icons:generate`. Don’t edit directly.
import Icon from 'tailwind/components/Icon/Icon';
import type { SmallIconProps } from 'tailwind/components/Icon/Icon.types';

const UtilityStrokeCloseOutlineSmallIcon = (props: SmallIconProps) => (
  <Icon {...props} viewBox="0 0 16 16">
    <g clipPath="url(#icon__clip0_8836_1446)">
      <path
        d="M5 5L11 11M5 11L11 5M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z"
        stroke="currentcolor"
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="icon__clip0_8836_1446">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);

export default UtilityStrokeCloseOutlineSmallIcon;
