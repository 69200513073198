import type { ReactNode } from 'react';
import React from 'react';

import { useIsComponentMounted } from 'hooks/useIsComponentMounted';

import css from 'styles/components/productdetail/productGallery/addToCollection.scss';

interface AddToCollectionProps {
  children: ReactNode;
}

/**
 * Add to collection heart <3
 * Current limitation of this component is that it can only appear once on a page
 *  it gets confused with which parent to attach to
 * @returns {FunctionComponent}
 */
const AddToCollection = ({ children }: AddToCollectionProps) => {
  // children are expected to be client only components
  const isMounted = useIsComponentMounted();
  return <div className={css.addToCollection}>{isMounted ? children : undefined}</div>;
};

export default AddToCollection;
