import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { parseUrl } from 'query-string';

import { TrackRecosInView } from 'components/productdetail/TrackRecosInView';
import { useWindowEvent } from 'hooks/useEvent';
import Recos from 'components/productdetail/Recos';
import { ProductQuickViewReco } from 'components/productdetail/ProductQuickView';
import { getRecoSlotData, getRecoSlotKey, shouldRecosUpdate } from 'helpers/RecoUtils';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import useMartyContext from 'hooks/useMartyContext';
import type { RecosState } from 'reducers/recos';
import { areRecosFlattened } from 'reducers/recos';
import type { JanusData } from 'types/mafia';
import { RECOMMENDATION_CTA_CLICK } from 'constants/amethystEnums';
import type { AppState } from 'types/app';

interface Props {
  className: string;
  onRecoClicked: ({
    index,
    amethystRecoType,
    recoSource,
    recommendedProduct,
    clickThrough
  }: {
    index: number;
    amethystRecoType: string;
    recoSource?: string;
    recommendedProduct?: {} | null;
    clickThrough: string;
  }) => void;
  similarProductRecos: RecosState;
  isFullMaxWidth?: boolean;
}

export const RecosCompleteTheLook = memo(
  ({ similarProductRecos = {}, className, onRecoClicked, isFullMaxWidth = false }: Props) => {
    const { janus = {}, lastReceivedRecoKey = '' } = similarProductRecos;
    const janusData = janus[lastReceivedRecoKey] || {};
    let janusRecos: JanusData | undefined;
    if (!areRecosFlattened(janusData)) {
      janusRecos = getRecoSlotData(janusData[getRecoSlotKey(0)]!);
    }
    const { testId } = useMartyContext();
    const [productIdFromHash, setProductIdFromHash] = useState();
    const [styleIdFromHash, setStyleIdFromHash] = useState();
    const recoSource = useSelector((state: AppState) => state.recos.source);

    const setIdsFromHash = () => {
      if (window.location.hash.includes('#quickview')) {
        const {
          query: { styleId, productId }
        } = parseUrl(window.location.hash);
        setProductIdFromHash(productId);
        setStyleIdFromHash(styleId);
      } else {
        setProductIdFromHash(undefined);
        setStyleIdFromHash(undefined);
      }
    };

    useEffect(setIdsFromHash, []); // Set state from hash on first load
    useWindowEvent('popstate', setIdsFromHash); // Listen to history changes and set state from hash

    if (!janusRecos) {
      return null;
    }

    const { recos } = janusRecos;

    return (
      <div className={className}>
        <TrackRecosInView products={recos} impression={{ widgetType: 'COMPLETE_THE_LOOK', numberOfRecommendations: janusRecos?.recos.length }}>
          <Recos
            id={'completeTheLook'}
            data-test-id={testId('completeTheLook')}
            title={janusRecos.title}
            recos={recos}
            onRecoClicked={onRecoClicked}
            belowImageRenderer={props => {
              // control which modal is open by state set from product and style in hash changes/intial hash
              const open = props.productId === productIdFromHash && props.styleId === styleIdFromHash;
              const { index, amethystRecoType } = props;
              const cardData = { ...props };
              return (
                <ProductQuickViewReco
                  cardData={cardData}
                  onClick={() => onRecoClicked({ index, amethystRecoType, recoSource, clickThrough: RECOMMENDATION_CTA_CLICK.ADD_ITEM })}
                  open={open}
                />
              );
            }}
            isFullMaxWidth={isFullMaxWidth}
          />
        </TrackRecosInView>
      </div>
    );
  },
  (prevProps, nextProps) => !shouldRecosUpdate(prevProps, nextProps)
);

export default withErrorBoundary('RecosCompleteTheLook', RecosCompleteTheLook);
