import { cn } from 'helpers/classnames';
import useMartyContext from 'hooks/useMartyContext';
import SponsoredAdsProduct from 'components/search/SponsoredAdsProduct';
import { ProductDetailCarousel } from 'components/productdetail/ProductDetailCarousel';
import { getLowStockLabelStatus } from 'helpers/cardUtils';
import { BOTTOM_PLACEMENT, TOP_PLACEMENT } from 'constants/sponsoredAds';

import css from 'styles/components/productdetail/sponsoredAdsCarousel.scss';

const RECOS_TITLE = 'Discover More Items';

const SponsoredAdsCarousel = ({
  CardBottomSlot = null,
  isFullMaxWidth,
  containerClassName,
  makeSponsoredAdClick,
  sponsoredAds = {},
  trackSponsoredAdImpressions,
  preferredResultPlacement = TOP_PLACEMENT
}) => {
  const {
    testId,
    marketplace: {
      search: { msaMelodyImageParams }
    }
  } = useMartyContext();

  const sponsoredAdsResults =
    preferredResultPlacement === BOTTOM_PLACEMENT ? [...(sponsoredAds?.results?.bottom || [])] : [...(sponsoredAds?.results?.top || [])];

  const products =
    sponsoredAdsResults?.map((product, i) => ({
      ...product,
      index: i,
      isSponsored: true
    })) || [];

  if (!sponsoredAdsResults || sponsoredAdsResults.length === 0) {
    return null;
  }

  return (
    <div className={containerClassName}>
      <div
        className={cn(css.carouselContainer, {
          [css.fullMaxWidth]: isFullMaxWidth
        })}
        data-test-id={testId('recoCarousel_SPONSORED_ADS')}
      >
        <ProductDetailCarousel
          title={RECOS_TITLE}
          slides={sponsoredAdsResults.map((_props, i) => {
            const product = products[i];
            const { styleId, colorId } = product;
            product.isLowStock = getLowStockLabelStatus({ ...product });

            const { relatedStyles, ...productInfo } = product;

            return (
              styleId &&
              colorId && (
                <SponsoredAdsProduct
                  key={`${styleId}-${colorId}`}
                  product={product}
                  relatedStyles={relatedStyles ? [productInfo].concat(relatedStyles || []) : []}
                  numSponsoredAds={sponsoredAds.length}
                  isInCarousel={true}
                  trackSponsoredAdImpressions={trackSponsoredAdImpressions}
                  makeSponsoredAdClick={makeSponsoredAdClick}
                  msaImageParams={msaMelodyImageParams}
                  CardBottomSlot={CardBottomSlot}
                  isFullMaxWidth={isFullMaxWidth}
                  includeSwatchRowSpace={true}
                />
              )
            );
          })}
        />
      </div>
    </div>
  );
};

export default SponsoredAdsCarousel;
