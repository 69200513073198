import React from 'react';
import ReactPlayer from 'react-player/lib/players/FilePlayer';

export const AdaptiveBitratePlayer = props => {
  const {
    videoPlayerCss,
    videoSrc = 'https://d2y5sgsy8bbmb8.cloudfront.net/3e9da59b-9198-4b74-b809-7ea630373b54/default.jobtemplate.hls.m3u8',
    autoPlay = true,
    muted = true,
    width = '640px',
    height = '360px',
    light = false,
    videoControls = true,
    videoLoop = false,
    onVideoStart,
    onVideoPlay,
    onVideoPause,
    onVideoSeek,
    onVideoEnded,
    videoId,
    playerRef
  } = props;

  return (
    <ReactPlayer
      id={videoId}
      controls={videoControls}
      loop={videoLoop}
      playing={autoPlay}
      light={light}
      width={width}
      height={height}
      pip={false}
      muted={muted}
      onStart={onVideoStart}
      onPlay={onVideoPlay}
      onPause={onVideoPause}
      onSeek={onVideoSeek}
      onEnded={onVideoEnded}
      url={videoSrc}
      ref={playerRef}
      className={videoPlayerCss}
      config={{ file: { attributes: { controlsList: 'nodownload' } } }}
      onContextMenu={e => e.preventDefault()}
    />
  );
};

export default AdaptiveBitratePlayer;
