import React from 'react';
import { Link } from 'react-router-dom';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import { cn } from 'helpers/classnames';
import HtmlToReact from 'components/common/HtmlToReact';
import ProductUtils from 'helpers/ProductUtils';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import ProductSkuNumber from 'components/productdetail/ProductSkuNumber';
import useMartyContext from 'hooks/useMartyContext';
import type { AppState } from 'types/app';

import css from 'styles/components/productdetail/productDescription.scss';

export interface DescriptionItems {
  bulletPoints: string[];
  sizeCharts: string[];
}

interface OwnProps {
  allowCollapse: boolean;
  brandLogo: JSX.Element;
  descriptionItems?: DescriptionItems;
  defaultProductType: string;
  glossaryLink: string;
  isExpanded: boolean;
  onReportError: (event: React.MouseEvent<HTMLButtonElement>) => void;
  productId: string;
  showGlossary: boolean;
  showReportError: boolean;
  isProductTypeShoesOrClothing?: boolean;
  productCardGenderDisplay?: string;
  isPdpPaperCutsFeatureEnabled?: boolean;
}
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = OwnProps & PropsFromRedux;

export const ProductDescription = ({
  allowCollapse,
  brandLogo,
  descriptionItems,
  defaultProductType,
  gcTerms,
  glossaryLink,
  isExpanded,
  onReportError,
  productId,
  showGlossary,
  showReportError,
  isProductTypeShoesOrClothing,
  productCardGenderDisplay,
  isPdpPaperCutsFeatureEnabled
}: Props) => {
  const {
    testId,
    marketplace: { domain }
  } = useMartyContext();

  let featuredDescription;
  let remainingDescriptionItems: string[] = [];
  const isGiftCard = ProductUtils.isGiftCard(defaultProductType);
  if (descriptionItems && descriptionItems.bulletPoints.length > 0) {
    featuredDescription = descriptionItems.bulletPoints[0];
    remainingDescriptionItems = descriptionItems.bulletPoints.slice(1);
  }

  const is6pm = domain === '6pm';

  return (
    <div className={css.productDescription} itemProp="description" data-test-id={testId('productDescriptionSection')}>
      <div
        className={cn(css.description, {
          [css.more]: !isExpanded && allowCollapse && descriptionItems
        })}
      >
        {!isPdpPaperCutsFeatureEnabled && (
          <div className="mb-6 flex items-center gap-2">
            {!isGiftCard && <div className={css.brandLogo}>{brandLogo}</div>}
            {isProductTypeShoesOrClothing && productCardGenderDisplay && !is6pm && <ProductSkuNumber productId={productId} />}
          </div>
        )}

        {descriptionItems ? (
          <ul>
            {featuredDescription && (
              <li className={css.featuredDescription} data-test-id={testId('featuredDescription')}>
                <HtmlToReact noContainer={true}>{featuredDescription}</HtmlToReact>
              </li>
            )}

            {is6pm && (
              <li className={css.descriptionSku} data-test-id={testId('descriptionSku')}>
                SKU: #<span itemProp="sku">{productId}</span>
              </li>
            )}

            {remainingDescriptionItems.map((item, index) => (
              <li key={`description_${index}`} data-test-id={testId('descriptionItem')}>
                <HtmlToReact noContainer={true}>{item}</HtmlToReact>
              </li>
            ))}
            {isGiftCard && gcTerms && (
              <li>
                <HtmlToReact noContainer={true}>{gcTerms}</HtmlToReact>
              </li>
            )}
            {isPdpPaperCutsFeatureEnabled && !is6pm && (
              <li>
                <ProductSkuNumber productId={productId} />
              </li>
            )}
          </ul>
        ) : null}
        {!isGiftCard && showGlossary && (
          <Link
            to={glossaryLink}
            className={css.glossary}
            data-track-action="Product-Page"
            data-track-label="PrDescription"
            data-track-value="View-Glossary"
            data-test-id={testId('glossaryTermsLink')}
          >
            View Zappos.com Glossary of Terms
          </Link>
        )}
        {showReportError && (
          <div className={css.reportError}>
            Find something wrong in this description?&nbsp;
            <span>
              Help us fix it!&nbsp;
              <button
                type="button"
                className={css.reportErrorButton}
                onClick={onReportError}
                data-track-action="Product-Page"
                data-track-label="PrDescription"
                data-track-value="Report-An-Error"
                data-test-id={testId('reportAnError')}
              >
                Report An Error
              </button>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export const mapStateToProps = (state: AppState) => ({
  gcTerms: state.headerFooter.content.Global.slotData.giftCardTerms?.gcTerms
});

const connector = connect(mapStateToProps);

const ProductDescriptionConnected = connector(ProductDescription);

export default withErrorBoundary('ProductDescription', ProductDescriptionConnected);
