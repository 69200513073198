import type { ReactElement } from 'react';
import React, { useEffect } from 'react';
import type { IntersectionOptions, InViewHookResponse } from 'react-intersection-observer';
import { useInView as defaultUseInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';

import { track } from 'apis/amethyst';
import { evRecommendationImpressionWrapper } from 'events/recommendations';
import type { FormattedJanusReco } from 'types/mafia';
import { getProductWithRelatedStyles } from 'helpers/RecoUtils';
import ProductUtils from 'helpers/ProductUtils';
import type { AppState } from 'types/app';

interface RecommendationImpression {
  widgetType: string;
  numberOfRecommendations: number;
  recommendationType?: string;
  recommendationSource?: string;
}

interface ExpectedChildrenProps {
  intersectionRef: (node?: Element | null | undefined) => void;
}

interface TrackRecosInViewProps {
  impression: RecommendationImpression;
  products: Pick<FormattedJanusReco, 'styleId' | 'productId' | 'link'>[];
  sourcePage?: string;
  children: ReactElement<ExpectedChildrenProps>;
  useInView?: (i?: IntersectionOptions) => InViewHookResponse;
}

// Wrap a Recos component with this to fire the inView tracking event.
export const TrackRecosInView = ({ impression, products, sourcePage, useInView = defaultUseInView, children }: TrackRecosInViewProps) => {
  const [intersectionRef, inView] = useInView({
    threshold: 0.33,
    triggerOnce: true
  });

  const recoProductRelations = useSelector((state: AppState) => state.products.recoProductRelations);

  const formattedProducts = products.map(({ styleId, productId, link }) => {
    const colorId = ProductUtils.extractColorIdFromJanusProductLink({ link });
    const productWithRelatedStyles = getProductWithRelatedStyles(styleId, recoProductRelations);
    let badgeId;

    if (productWithRelatedStyles.badges && productWithRelatedStyles?.badges?.length > 0) {
      badgeId = productWithRelatedStyles?.badges[0]?.bid;
    }

    return {
      productId,
      styleId,
      colorId,
      badgeId
    };
  });

  useEffect(() => {
    if (inView) {
      const defaultImpressionValues = { recommendationType: 'PRODUCT_RECOMMENDATION', recommendationSource: 'EP13N' };
      const recommendationImpression = [
        {
          ...defaultImpressionValues,
          ...impression
        }
      ];
      track(() => [
        evRecommendationImpressionWrapper,
        { products: formattedProducts, recommendationImpression, viewableImpression: true, sourcePage }
      ]);
    }
  }, [inView, impression]);

  return React.cloneElement(children, { intersectionRef });
};
