import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useEffectOnce from 'hooks/useEffectOnce';
import ProductCardV2 from 'components/outfitRecos/ProductCardV2';
import MelodyModal from 'components/common/MelodyModal';
import { SmallLoader } from 'components/Loader';
import type { OutfitRecoContent } from 'types/outfitRecos';
import { fetchProductStyleDetails, setCurrentlyViewingOutfitReco, setSourceProductUrl } from 'actions/outfitRecos';
import { getProductRelations } from 'actions/products';
import type { ProductStyle } from 'types/cloudCatalog';
import { selectOutfitRecos } from 'selectors/outfitRecos';
import type { AppDispatch } from 'entrypoints/bootstrapOnClient';

import css from 'styles/components/outfitRecos/recosQuickShopModal.scss';

type RecosQuickShopModalProps = {
  currentStyle: ProductStyle;
  outfitRecoContent: OutfitRecoContent;
  closeModal: () => void;
};

export const RecosQuickShopModal = ({ closeModal, outfitRecoContent, currentStyle }: RecosQuickShopModalProps) => {
  const { styleId: currentStyleId } = currentStyle;
  const { productList, complementaryGroupId } = outfitRecoContent;
  const { recoProductsData = {} } = useSelector(selectOutfitRecos);
  const dispatch: AppDispatch = useDispatch();

  useEffectOnce(() => {
    const fetchOpts = {
      styleId: currentStyleId,
      entireProduct: true,
      includeSizing: true,
      includeOosSizing: true,
      includeOos: true
    };
    const styleIdList = [currentStyleId];
    dispatch(fetchProductStyleDetails(fetchOpts, true));
    productList.map(product => {
      fetchOpts.styleId = product.styleId;
      styleIdList.push(product.styleId);
      dispatch(fetchProductStyleDetails(fetchOpts, true));
    });
    dispatch(getProductRelations(styleIdList));
  });

  const saveCurrentlyViewingOutfit = () => {
    const productList = outfitRecoContent.productList.map(product => ({
      ...product,
      productUrl: recoProductsData[product.styleId]!.detail?.styles.filter(style => style.styleId === product.styleId)[0]?.productUrl
    }));

    const currentOutfit = { ...outfitRecoContent, productList };

    dispatch(setSourceProductUrl(currentStyle.productUrl));
    dispatch(setCurrentlyViewingOutfitReco(currentOutfit));
  };

  const buildProductCards = () => {
    const areAllStylesFetched = outfitRecoContent.productList.every(product => product.styleId in recoProductsData);

    if (!areAllStylesFetched) {
      return <SmallLoader />;
    }

    return (
      <div>
        {productList.map(product => {
          const { productId, styleId } = product;
          if (styleId in recoProductsData) {
            return (
              <ProductCardV2
                key={styleId}
                styleId={styleId}
                productId={productId}
                productData={recoProductsData[styleId]}
                seedStyleId={currentStyleId}
                onCardClick={saveCurrentlyViewingOutfit}
                productList={productList}
                groupId={complementaryGroupId}
                closeModal={closeModal}
              />
            );
          }
          return null;
        })}
      </div>
    );
  };

  return (
    <MelodyModal
      className={css.modal}
      overlayClassName={css.modalOverlay}
      isOpen={true}
      onRequestClose={closeModal}
      contentLabel="Styling Ideas"
      wrapperTestId="outfitQuickShopModal"
    >
      <h2 className={css.outfitHeading}>Styling Ideas</h2>
      {buildProductCards()}
    </MelodyModal>
  );
};

export default RecosQuickShopModal;
