import React, { useState } from 'react';

import { cn } from 'helpers/classnames';
import BottomDrawer from 'components/common/BottomDrawer';
import { evRecoDrawerButtonClick } from 'events/product';
import { track } from 'apis/amethyst';
import useMartyContext from 'hooks/useMartyContext';
import { isDesktop } from 'helpers/ClientUtils';

import css from 'styles/components/common/recos/recoDrawer.scss';

export const DRAWER_BUTTON_TEST_ID = 'RecoDrawerButton';
export const DRAWER_TEST_ID = 'RecoDrawer';

export interface RecoDrawerProps {
  children?: React.ReactNode;
  buttonClassName?: string;
  isVideo?: boolean;
  /** for desktop only: Instead of showing the drawer we will anchor to the passed in ref upon the drawer button click */
  focusRef?: React.RefObject<HTMLElement>;
  isMobileOnly?: boolean;
}

const RecoDrawer = ({ buttonClassName, children, focusRef, isMobileOnly, isVideo }: RecoDrawerProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { testId } = useMartyContext();

  const handleOpen = () => {
    track(() => [evRecoDrawerButtonClick, {}]);
    // if in desktop only focus the ref if passed instead of opening the drawer
    if (!isMobileOnly && isDesktop() && focusRef) {
      focusRef?.current?.scrollIntoView({ behavior: 'smooth' });
      return;
    }
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      {!isVideo && (
        <button
          data-test-id={testId(DRAWER_BUTTON_TEST_ID)}
          data-drawer-open={isOpen}
          aria-label="Browse similar products"
          className={cn(css.openButton, { [css.hideOutsideOfMobile]: isMobileOnly || !focusRef }, buttonClassName)}
          type="button"
          onClick={handleOpen}
        />
      )}
      <BottomDrawer wrapperTestId={testId(DRAWER_TEST_ID)} className={css.modalContent} onRequestClose={handleClose} isOpen={isOpen}>
        {children}
      </BottomDrawer>
    </>
  );
};

export default RecoDrawer;
