import { PRODUCT_COMPARISON_TABLE_PRODUCT_INTERACTION, PRODUCT_COMPARISON_TABLE_VIEW } from 'constants/amethyst';

// Product Comparison Table view Event Params
type ProductComparisonTableViewParams = {
  sourceProductId: string;
  productIds: string[];
};

type InteractedProduct = {
  productId: string;
  styleId: string;
  colorId: string;
};
type ProductComparisonTableInteractParams = {
  sourceStyleId: string;
  interactedProduct: InteractedProduct;
  interactionType: string;
};

function getProductIdentifiers(productIds: string[]) {
  return productIds.map(productId => ({
    productId: productId
  }));
}

// Events
export const evProductComparisonTableView = ({ sourceProductId, productIds }: ProductComparisonTableViewParams) => ({
  [PRODUCT_COMPARISON_TABLE_VIEW]: {
    sourceProductId,
    products: getProductIdentifiers(productIds)
  }
});

export const evProductInteract = ({ sourceStyleId, interactedProduct, interactionType }: ProductComparisonTableInteractParams) => {
  const { productId, styleId, colorId } = interactedProduct;

  return {
    [PRODUCT_COMPARISON_TABLE_PRODUCT_INTERACTION]: {
      sourceStyleId,
      interactedProduct: {
        productId,
        styleId,
        colorId
      },
      interactionType
    }
  };
};
