import React, { useEffect, useState } from 'react';
import ExecutionEnvironment from 'exenv';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import useMartyContext from 'hooks/useMartyContext';
import Rating from 'components/Rating';
import MartyLink from 'components/common/MartyLink';
import { ColorSwatches } from 'components/search/ColorSwatches';
import ProductPrice from 'components/influencer/shoppablePosts/ProductPrice';
import SizePickerWrapper from 'components/influencer/shoppablePosts/SizePickerWrapper';
import Hearts from 'components/common/Hearts';
import { SmallLoader } from 'components/Loader';
import { changeQuantity } from 'actions/cart';
import { heartProduct, toggleHeartingLoginModal, unHeartProduct } from 'actions/hearts';
import { toggleProductNotifyModal } from 'actions/productdetail/sharing';
import { translateCartError } from 'apis/mafia';
import type { Product } from 'constants/searchTypes';
import type { AppState } from 'types/app';
import type { CartError, CartResponse } from 'types/mafia';
import type { ProductStyle } from 'types/cloudCatalog';
import { ProductCardState } from 'types/influencer';
import type { FormattedProductData } from 'reducers/detail/productDetail';
import { constructMSAImageUrl } from 'helpers/index';
import { getProductWithRelatedStyles } from 'helpers/RecoUtils';
import { commonDefaultColorSwatchParams, translateToProducts } from 'helpers/ShoppablePostUtils';
import { SOLD_LAST_ITEM_IN_THIS_SIZE, YOU_JUST_MISSED_IT } from 'constants/influencerMessages';
import { MSA_IMAGE_DIMENSIONS, OUTFIT_RECO_REF_TAG } from 'constants/outfitRecos';
import { generateLabelForProduct, generateOutfitGroup } from 'helpers/outfitRecoUtils';
import { evOutfitRecoModalViewDetailsClick } from 'events/outfitRecos';
import { track } from 'apis/amethyst';
import type { RecoCardProduct } from 'types/outfitRecos';
import { OUTFIT_RECOS_QUICK_SHOP_MODAL_PAGE } from 'constants/amethystPageTypes';
import { evAddToCart } from 'events/cart';

// eslint-disable-next-line css-modules/no-unused-class
import productCardCSS from 'styles/components/influencer/shoppablePosts/customerViewProductCard.scss';
import css from 'styles/components/outfitRecos/productCardV2.scss';

interface OwnProps {
  styleId: string;
  productId: string;
  productData?: FormattedProductData;
  merchantId?: string;
  seedStyleId?: string;
  onCardClick?: () => void;
  productList: RecoCardProduct[];
  groupId: string;
  closeModal?: () => void;
}

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = OwnProps & PropsFromRedux;

export const ProductCardV2 = (props: Props) => {
  const {
    styleId,
    productId,
    productData,
    merchantId = '',
    seedStyleId,
    onCardClick,
    changeQuantity,
    toggleProductNotifyModal,
    toggleHeartingLoginModal,
    heartProduct,
    unHeartProduct,
    isCustomer,
    products,
    productList,
    groupId,
    closeModal
  } = props;

  const { testId } = useMartyContext();

  const [selectedStockId, setSelectedStockId] = useState<string | undefined>();
  const [currentStyleId, setCurrentStyleId] = useState(styleId);
  const [productCardState, setProductCardState] = useState<ProductCardState>(ProductCardState.SIZE_PICK);

  const isCurrentlyViewing = styleId === seedStyleId;

  const style = productData?.detail?.styles?.filter((styleDetails: ProductStyle) => styleDetails.styleId === currentStyleId)[0]!;
  const { colorId, color, isNew, onSale, originalPrice, price, percentOff, productUrl } = style;

  const formattedStyles: { style: ProductStyle; index: number }[] = [];
  productData?.detail?.styles?.forEach((style: ProductStyle, index: number) => {
    const { styleId } = style;
    formattedStyles[parseInt(styleId)] = { style, index };
  });

  const {
    detail: { brandName, productName, productRating, reviewCount, sizing },
    seoProductUrl
  } = productData || { detail: {} };

  const productWithRelatedStyles = getProductWithRelatedStyles(currentStyleId, products.recoProductRelations);
  const { relatedStyles } = productWithRelatedStyles;
  const relatedSwatchData: Product[] = translateToProducts(relatedStyles || []);

  useEffect(() => {
    setProductCardState(ProductCardState.SIZE_PICK);
  }, [currentStyleId]);

  const onAddToCart = (e: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setProductCardState(ProductCardState.ADD_TO_CART_IN_PROGRESS);
    const amethystEventBundle = { ...style, addedFrom: OUTFIT_RECOS_QUICK_SHOP_MODAL_PAGE };
    track(() => [evAddToCart, amethystEventBundle]);
    if (selectedStockId) {
      changeQuantity({ items: [{ stockId: selectedStockId, quantity: 1, quantityAddition: true, merchantId }] }, { firePixel: true }).then(
        (response: CartResponse | CartError) => {
          const error = translateCartError(response);
          setProductCardState(error ? ProductCardState.ADD_TO_CART_FAIL : ProductCardState.ADD_TO_CART_SUCCESS);
          setTimeout(() => setProductCardState(ProductCardState.SIZE_PICK), 5000);
        }
      );
    } else {
      setProductCardState(ProductCardState.ADD_TO_CART_FAIL);
      setTimeout(() => setProductCardState(ProductCardState.SIZE_PICK), 5000);
    }
  };

  const onHeartClick = ({ styleId }: { styleId: string }, isHearted: boolean) => {
    if (isCustomer) {
      const fn = isHearted ? unHeartProduct : heartProduct;
      // @ts-ignore
      fn({ itemId: styleId, productId, colorId });
    } else {
      toggleHeartingLoginModal(true, styleId);
    }
  };

  const resetProductNotifyState = () => {
    toggleProductNotifyModal(false);
  };

  const miniPageLoader = <SmallLoader />;

  const productDetailsMarkup = (
    <div className={css.productDetails}>
      <div className={productCardCSS.brandName} data-test-id={testId('brandName')}>
        {brandName}
      </div>
      <div className={productCardCSS.productName} data-test-id={testId('productName')}>
        {productName}
      </div>
      <div className={productCardCSS.productColor} data-test-id={testId('productColor')}>
        Color: {color}
      </div>
      <div className={productCardCSS.priceInfoContainer} data-test-id={testId('productPriceInfo')}>
        {style && <ProductPrice productStyle={style} />}
      </div>
      <div className={productCardCSS.productRating}>
        <Rating countClass={productCardCSS.reviewCount} rating={productRating!} reviewCount={reviewCount} hasDisplayReviewCount={true} />
      </div>
    </div>
  );

  const addToCartFailedMarkup = (
    <div className={css.atcFailedContainer} aria-live="polite">
      <div className={css.atcFailedHeaderContainer}>
        <span className={productCardCSS.atcFailedIcon} aria-hidden={true} />
        <span className={productCardCSS.atcFailedHeader}>{YOU_JUST_MISSED_IT}</span>
      </div>
      <span className={productCardCSS.atcFailedMessage}>{SOLD_LAST_ITEM_IN_THIS_SIZE}</span>
    </div>
  );

  const addToCartSuccessMarkup = (
    <div className={css.addToCartSuccess} aria-live="polite">
      <span className={productCardCSS.check} /> Added To Bag
    </div>
  );

  const sizeSelectorMarkup = (
    <SizePickerWrapper
      setSelectedStockId={setSelectedStockId}
      onAddToCart={onAddToCart}
      sizing={sizing!}
      colorId={colorId}
      selectedStyle={style}
      productData={productData!}
      productId={productId}
      resetProductNotifyState={resetProductNotifyState}
      useV2={true}
      sourcePage={OUTFIT_RECOS_QUICK_SHOP_MODAL_PAGE}
    />
  );

  const heartsProps = {
    showFavoriteHeart: true,
    onHeartClick,
    isDisplayCount: false,
    styleId: currentStyleId,
    productId
  };

  const defaultColorSwatchParams = {
    ...commonDefaultColorSwatchParams,
    relatedStyles: relatedSwatchData,
    makeSwatchClickHandler: (swatch: Product) => () => setCurrentStyleId(swatch.styleId),
    colorId: parseInt(colorId),
    styleId: currentStyleId,
    brandName: brandName!,
    productId: productId.toString(),
    productName: productName!,
    productSeoUrl: seoProductUrl!,
    styleColor: color,
    productUrl,
    isNew,
    onSale,
    originalPrice,
    percentOff,
    price,
    color,
    onClick: () => closeModal?.()
  };

  const onProductClick = () => {
    const outfitGroup = generateOutfitGroup(groupId, productList);
    track(() => [evOutfitRecoModalViewDetailsClick, { seedStyleId, outfitGroup, clickStyleId: currentStyleId }]);
    onCardClick?.();
  };

  const productLabel = generateLabelForProduct(brandName, productName, color, price, originalPrice);
  const refTagUrl = currentStyleId === styleId ? `${productUrl}?ref=${OUTFIT_RECO_REF_TAG}` : `${productUrl}`;

  return (
    <div className={css.productCard} data-test-id={testId('productCardV2')}>
      <MartyLink className={css.productDetailContainer} to={refTagUrl} onClick={onProductClick} aria-label={productLabel}>
        <div className={css.imageContainer}>
          <img
            src={constructMSAImageUrl(formattedStyles[parseInt(currentStyleId)]?.style.imageId, MSA_IMAGE_DIMENSIONS)}
            className={css.image}
            alt="Product"
          />
          <Hearts {...heartsProps} />
          {isCurrentlyViewing && <span className={css.imageTag}>Currently Viewing</span>}
        </div>
        {productDetailsMarkup}
      </MartyLink>
      <div className={css.productSelects}>
        {!!relatedSwatchData.length && (
          <div className={css.swatchScroller}>
            <span className={css.swatchDescription}>{relatedSwatchData.length} colors available</span>
            <ColorSwatches {...defaultColorSwatchParams} />
          </div>
        )}
        {
          {
            [ProductCardState.SIZE_PICK]: sizeSelectorMarkup,
            [ProductCardState.ADD_TO_CART_FAIL]: addToCartFailedMarkup,
            [ProductCardState.ADD_TO_CART_SUCCESS]: addToCartSuccessMarkup,
            [ProductCardState.ADD_TO_CART_IN_PROGRESS]: miniPageLoader
          }[productCardState]
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  const isCustomer = !!(ExecutionEnvironment.canUseDOM && state.cookies['x-main']);
  const { hearts: heartsStyleIdsList = {}, products } = state;

  return { isCustomer, products, hearts: heartsStyleIdsList.heartsStyleIds };
};

const mapDispatchToProps = {
  changeQuantity,
  toggleProductNotifyModal,
  toggleHeartingLoginModal,
  heartProduct,
  unHeartProduct
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ProductCardV2);
