import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useMartyContext from 'hooks/useMartyContext';
import OutfitFeedback from 'components/outfitRecos/OutfitFeedback';
import RecosQuickShopModal from 'components/outfitRecos/RecosQuickShopModal';
import type { ProductStyle } from 'types/cloudCatalog';
import RecosCardV2 from 'components/outfitRecos/RecosCardV2';
import RecosCardV3 from 'components/outfitRecos/RecosCardV3';
import { fetchComplementaryRecos } from 'actions/outfitRecos';
import { selectOutfitRecos } from 'selectors/outfitRecos';
import { filterValidRecos, generateOutfitGroup } from 'helpers/outfitRecoUtils';
import { track } from 'apis/amethyst';
import { evOutfitRecoCardView } from 'events/outfitRecos';
import useFetchProductRelation from 'hooks/useFetchProductRelation';
import type { OutfitRecoContent } from 'types/outfitRecos';
import useWindowSize from 'hooks/useWindowSize';
import type { AppDispatch } from 'entrypoints/bootstrapOnClient';
import CarouselHeader from 'components/common/CarouselHeader/CarouselHeader';
import { useInAssignment } from 'hooks/useHydra';
import { HYDRA_CORE_EXPERIENCE_FUNCTIONALITY_IMPROVEMENTS } from 'constants/hydraTests';
import { cn } from 'helpers/classnames';

import css from 'styles/components/outfitRecos/recosSection.scss';

interface RecosSectionProps {
  currentStyle: ProductStyle;
  isModalOpen?: boolean;
  shouldShowRecosCardV3?: boolean;
}

export const RecosSection = ({ currentStyle, isModalOpen = false, shouldShowRecosCardV3 = true }: RecosSectionProps) => {
  const [validOutfitRecos, setValidOutfitRecos] = React.useState<OutfitRecoContent[]>();
  const [isQuickViewModalOpen, setIsQuickViewModalOpen] = useState<boolean>(isModalOpen);
  const [contentIndex, setContentIndex] = useState<number>(0);
  const [styleIds, setStyleIds] = useState<string[]>([]);
  const hydraCefi = useInAssignment(HYDRA_CORE_EXPERIENCE_FUNCTIONALITY_IMPROVEMENTS);

  const [productRelations] = useFetchProductRelation(styleIds);

  const dispatch: AppDispatch = useDispatch();

  const { testId } = useMartyContext();

  const { outfitRecosContent } = useSelector(selectOutfitRecos);
  const { width: windowWidth = 0, height: windowHeight = 0 } = useWindowSize(500);

  const isMobile = windowWidth < 650 || windowHeight < 650;

  useEffect(() => {
    if (isMobile || shouldShowRecosCardV3) {
      dispatch(fetchComplementaryRecos(currentStyle.styleId));
    }
  }, [currentStyle.styleId, isMobile]);

  useEffect(() => {
    const styleIds: string[] = [];
    if (outfitRecosContent.length && currentStyle) {
      const outfitGroups = outfitRecosContent.map(content => {
        const { productList, complementaryGroupId } = content;
        const contentStyleIds = productList.map(product => product.styleId);
        styleIds.push(...contentStyleIds);
        return generateOutfitGroup(complementaryGroupId, productList);
      });
      track(() => [evOutfitRecoCardView, { seedStyleId: currentStyle.styleId, outfitGroups }]);
    }
    setStyleIds(styleIds);
  }, [outfitRecosContent[0]?.complementaryGroupId]);

  useEffect(() => {
    setValidOutfitRecos(filterValidRecos(outfitRecosContent, productRelations));
  }, [productRelations && Object.keys(productRelations).length]);

  const closeQuickViewModal = () => {
    setIsQuickViewModalOpen(false);
  };

  const makeQuickViewModal = () => {
    if (!validOutfitRecos) {
      return <></>;
    }

    return <RecosQuickShopModal closeModal={closeQuickViewModal} outfitRecoContent={validOutfitRecos[contentIndex]!} currentStyle={currentStyle} />;
  };

  return (
    <>
      {validOutfitRecos && (
        <div className={cn(css.sectionWrapper, hydraCefi && '!m-0 !text-left')}>
          {isMobile ? (
            <>
              {hydraCefi ? (
                <div className="mb-4">
                  <CarouselHeader title="Styling Ideas" dataTestId="stylingIdeas" />
                </div>
              ) : (
                <h2 className={css.heading} data-test-id={testId('stylingIdeas')}>
                  Styling Ideas
                </h2>
              )}
              <RecosCardV2
                contents={validOutfitRecos}
                contentIndex={contentIndex}
                setContentIndex={setContentIndex}
                currentImageId={currentStyle.imageId}
                setIsQuickViewModalOpen={setIsQuickViewModalOpen}
                productRelations={productRelations}
              />
              <div className={css.outfitFeedbackSection}>
                <OutfitFeedback
                  groupId={validOutfitRecos[contentIndex]?.complementaryGroupId!}
                  products={validOutfitRecos[contentIndex]?.productList!}
                  outfitNumber={contentIndex + 1}
                />
              </div>
            </>
          ) : (
            shouldShowRecosCardV3 && (
              <>
                {!hydraCefi && <h2 className={css.heading}>Styling Ideas</h2>}
                <RecosCardV3
                  title={hydraCefi ? 'Styling Ideas' : undefined}
                  contents={validOutfitRecos}
                  contentIndex={contentIndex}
                  setContentIndex={setContentIndex}
                  setIsQuickViewModalOpen={setIsQuickViewModalOpen}
                  productRelations={productRelations}
                />
              </>
            )
          )}
          {isQuickViewModalOpen && makeQuickViewModal()}
        </div>
      )}
    </>
  );
};

export default RecosSection;
