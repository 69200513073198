import type { ThunkAction } from 'redux-thunk';
import type { AnyAction } from 'redux';

import {
  ADD_PRODUCT_COMPARISON_PRODUCT_RELATIONS,
  RECEIVE_PRODUCT_COMPARISON_TABLE_FAILURE,
  RECEIVE_PRODUCT_COMPARISON_TABLE_SUCCESS,
  REQUEST_PRODUCT_COMPARISON_TABLE,
  RESET_PRODUCT_COMPARISON_TABLE
} from 'constants/reduxActions';
import type { ProductComparisonTableType } from 'types/productComparison';
import { fetchErrorMiddleware, fetchErrorMiddlewareMaybeJson } from 'middleware/fetchErrorMiddleware';
import { fetchProductRelationsByProductIds } from 'apis/calypso';
import type { AppState } from 'types/app';
import { selectMafiaConfig } from 'selectors/environment';
import { getProductComparisonTable } from 'apis/mafia';
import { trackError } from 'helpers/ErrorUtils';
import type { ProductWithRelationsFromCalypso } from 'types/calypso';

export function requestProductComparisonTable() {
  return {
    type: REQUEST_PRODUCT_COMPARISON_TABLE
  } as const;
}

export function receiveProductComparisonTableSuccess(comparisonTableData: ProductComparisonTableType) {
  return {
    type: RECEIVE_PRODUCT_COMPARISON_TABLE_SUCCESS,
    comparisonTableData
  } as const;
}

export function receiveProductComparisonTableFailure() {
  return {
    type: RECEIVE_PRODUCT_COMPARISON_TABLE_FAILURE
  } as const;
}

export function resetProductComparisonTable() {
  return {
    type: RESET_PRODUCT_COMPARISON_TABLE
  } as const;
}

export function addProductComparisonProductRelations(productComparisonProductRelations: ProductWithRelationsFromCalypso[]) {
  return {
    type: ADD_PRODUCT_COMPARISON_PRODUCT_RELATIONS,
    productComparisonProductRelations
  } as const;
}

export function fetchProductComparisonTable(styleId: string): ThunkAction<Promise<void>, AppState, void, AnyAction> {
  return (dispatch, getState) => {
    const state = getState();
    const { cookies } = state;
    const mafiaConfig = selectMafiaConfig(state);

    return getProductComparisonTable(mafiaConfig, styleId, cookies)
      .then(fetchErrorMiddlewareMaybeJson)
      .then((response: ProductComparisonTableType) => {
        if (response) {
          dispatch(receiveProductComparisonTableSuccess(response));
        } else {
          dispatch(receiveProductComparisonTableFailure());
        }
      })
      .catch((e: any) => {
        trackError('NON-FATAL', 'Could not get Product details', e);
        dispatch(receiveProductComparisonTableFailure());
      });
  };
}

export function getProductRelationsForComparableProductIds(productIds: string[]): ThunkAction<Promise<void>, AppState, void, AnyAction> {
  return async (dispatch, getState) => {
    const state = getState();
    const {
      environmentConfig: {
        api: {
          calypso: { url, siteId, subsiteId }
        }
      }
    } = state;

    const getProductRelations = async () => {
      const response: { results: ProductWithRelationsFromCalypso[] } = await fetchErrorMiddleware(
        (await fetchProductRelationsByProductIds({ url, siteId, subsiteId }, productIds)) as Response
      );
      dispatch(addProductComparisonProductRelations(response.results));
    };

    if (productIds && productIds.length > 0) {
      return getProductRelations().catch(e => {
        trackError('NON-FATAL', 'Could not get Product details', e);
      });
    }
  };
}

export type ProductComparisonAction =
  | ReturnType<typeof requestProductComparisonTable>
  | ReturnType<typeof receiveProductComparisonTableSuccess>
  | ReturnType<typeof receiveProductComparisonTableFailure>
  | ReturnType<typeof resetProductComparisonTable>
  | ReturnType<typeof addProductComparisonProductRelations>;
