import React, { useState } from 'react';

import { cn } from 'helpers/classnames';
import type { FeaturedImage } from 'types/product';
import ImageZoom from 'components/common/zoom/ImageZoom';
import CommonImage from 'components/common/Image';
import MelodyModal from 'components/common/MelodyModal';
import useMartyContext from 'hooks/useMartyContext';

import css from 'styles/components/common/zoom/image.scss';
export interface ImageProps {
  image: FeaturedImage;
  showInstructions: boolean;
  prependedAltText?: string;
  /** Show a grey overlay with the image */
  isOverlayShown: boolean;
  tracking?: {};
  /** Callback function to monitor zooming of images */
  onZoom?: (isZoomed: boolean) => void;
  /** Can be set to give the images a minimum height, helps to prevent vertical shifting */
  minHeight?: number;
  /** Any content to be shown with the zoomed image ( thumbnails for example ) */
  additionalZoomContent?: React.ReactNode[] | React.ReactNode;
  /** Override the zoomed image ( selecting thumbnails for example ) */
  zoomedImageOverride?: FeaturedImage | null;
  buttonClass?: string;
}

/**
 * A zoomable lazy image
 */
export const Image = ({
  image,
  showInstructions,
  prependedAltText = '',
  isOverlayShown,
  tracking,
  onZoom,
  minHeight,
  additionalZoomContent,
  zoomedImageOverride,
  buttonClass
}: ImageProps) => {
  const [isZoomed, setIsZoomed] = useState(false);
  const [isInstructionsShown, setIsInstructionsShown] = useState(showInstructions);
  const {
    alt,
    featured: { src, retinaSrc },
    type
  } = image || {};
  const { testId } = useMartyContext();

  const handleImageButtonClick = () => {
    handleOnZoom(true);
  };

  const handleModalClose = () => {
    handleOnZoom(false);
  };

  const handleOnZoom = (isZoomed: boolean) => {
    setIsZoomed(isZoomed);

    if (onZoom) {
      onZoom(isZoomed);
    }
  };

  let style = {};

  if (minHeight) {
    style = {
      '--min-height': (minHeight + 'px') as React.CSSProperties
    };
  }

  return (
    <>
      {isZoomed && (
        <MelodyModal isOpen={true} className={css.zoomModal} buttonTestId="productGalleryZoomClose" onRequestClose={handleModalClose}>
          {additionalZoomContent}
          <ImageZoom image={zoomedImageOverride ?? image} showInstructions={isInstructionsShown} setShowInstructions={setIsInstructionsShown} />
        </MelodyModal>
      )}
      <button
        style={style}
        className={cn(css.button, { [css.isOverlayShown]: isOverlayShown }, buttonClass)}
        type="button"
        aria-label={`${alt} Zoom`}
        onClick={handleImageButtonClick}
        data-media={type}
        data-test-id={testId(`${type}Featured`)}
        {...tracking}
      >
        <CommonImage
          className={css.image}
          alt={(prependedAltText && `${prependedAltText} - `) + alt}
          src={src}
          srcSet={retinaSrc}
          itemProp="image"
        />
      </button>
    </>
  );
};

export default Image;
