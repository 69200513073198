import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { deepEqual } from 'fast-equals';
import sanitize from 'sanitize-html';

import { cn } from 'helpers/classnames';
import { BEST_FOR_YOU_FACETFIELD } from 'constants/appConstants';
import { SAVI_COOKIE, SAVI_SIZE_COOKIE, SEARCH_BEST_FOR_YOU_COOKIE } from 'constants/cookies';
import { HYDRA_BEST_GUESS, HYDRA_SEARCH_PRODUCT_HOVER } from 'constants/hydraTests';
import { evProductInteract, evSortSearchClick } from 'events/search';
import { fetchAccountInfo } from 'actions/account/account';
import marketplace from 'cfg/marketplace.json';
// constants & regex
import { SLASH_SEARCH_FILTERS_RE, ZSO_URL_WITH_FILTERS_RE } from 'common/regex';
// actions
import { addAdToQueue, updateAdData } from 'actions/ads';
import { fetchProductDetail } from 'actions/productDetail';
import { deleteSavedFilters, fetchFromSearch, fetchFromZso, saveFilters } from 'actions/fancyUrls';
import { clearAutoComplete } from 'actions/autoComplete';
import { setOosMessaging, trackSponsoredAdImpressions } from 'actions/products';
import { redirectToAuthenticationFor, redirectWithAppRoot } from 'actions/redirect';
import { removeFromStoredCookies, sessionExpiration, setAndStoreCookie } from 'actions/session';
import { fireExplicitSearchEventFromLocalStorage } from 'helpers/searchInputUtils';
import { getHeartCounts, getHearts, heartProduct, toggleHeartingLoginModal, unHeartProduct } from 'actions/hearts';
import { triggerAssignment } from 'actions/ab';
import {
  clearInlineRecos,
  fetchSearchInlineRecos,
  searchFeedbackClick,
  setUrlUpdated,
  toggleFacetsContainer,
  toggleSavedFilters,
  updateBestForYou,
  updateSort
} from 'actions/search';
import {
  getFacetUrlParams,
  getFacetUrlPath,
  removePersonalizedSize,
  resetFacetGroup,
  setFacetChosen,
  toggleFacetGroupShowMore,
  togglePersonalizedSize,
  toggleSelectedFacet,
  toggleSelectedFacetGroup,
  toggleSizingFacetGroup
} from 'actions/facets';
import { pageTypeChange } from 'actions/common';
import { setHFSearchTerm } from 'actions/headerfooter';
import { fetchSymphonySearchComponents } from 'store/ducks/search/actions';
import { fetchLandingPageInfo } from 'actions/landing/landingPageInfo.js';
// resources
import { track } from 'apis/amethyst';
import { evSearchCrossSiteRecoImpression } from 'events/recommendations';
import { ensureClass, removeCookie, sanitizeForEvent, setCookie } from 'helpers';
import { apstagRemoveToken, apstagUpdateToken, SRP_NARROW_TOP, SRP_WIDE_MID, SRP_WIDE_TOP } from 'helpers/apsAdvertisement';
import { isDesktop } from 'helpers/ClientUtils';
import { onEvent } from 'helpers/EventHelpers';
import { trackEvent, trackLegacyEvent } from 'helpers/analytics';
import {
  buildSearchTermEventPayload,
  createClearAllFiltersQuery,
  formatProductClickEvent,
  formatSavedFilters,
  isEmptyZsoUrl,
  sortStringToObject,
  updateSortParamOfUrl
} from 'helpers/SearchUtils';
// containers/components
import MelodyCategory from 'components/landing/MelodyCategory';
import HtmlToReact from 'components/common/HtmlToReact';
import JanusPixel from 'components/common/JanusPixel';
import GamSlot from 'components/common/GamSlot';
import DesktopSearchHeader from 'components/search/DesktopSearchHeader';
import CompactSingleSelects from 'components/search/CompactSingleSelects';
import Facets from 'components/search/Facets';
import FacetActions from 'components/search/FacetActions';
import FacetMenu from 'components/search/FacetMenu';
import Pagination from 'components/common/Pagination';
import TopBannerAd from 'components/search/TopBannerAd';
import SearchLogic from 'containers/SearchLogic';
import Products from 'components/search/Products';
import SiteAwareMetadata from 'components/SiteAwareMetadata';
import SkipLinks from 'components/common/SkipLinks';
import Tooltip from 'components/common/Tooltip';
import FocusTrap from 'components/common/FocusTrap';
import UtilityStrokeInfoOutlineSmallIcon from 'tailwind/components/Icons/UtilityStrokeInfoOutlineSmallIcon';
import { fetchCustomerAuthDetails } from 'actions/authentication';
import { evSearchClearFilters } from 'events/headerFooter';
import { BannerAdInTest } from 'components/common/BannerAd/BannerAd';

// eslint-disable-next-line css-modules/no-unused-class
import css from 'styles/containers/search.scss';

// marketplace
const {
  hasApstagAdsToken,
  cookieDomain,
  features: { showRatings },
  search: {
    autoCompleteMinValues,
    facetHeader,
    hasFacetListClear,
    hasStickySearch,
    mobileCloseFiltersText,
    mobileFacetHeader,
    mobileRemoveSelectionsText,
    showRatingStars,
    showSeoText,
    sortOptions
  },
  name
} = marketplace;

const SYMPHONY_TOP_HEADER_SLOT_NAME = 'search-header-1';

export class StandardSearch extends Component {
  static fetchDataOnServer(store, location, params, fetchParams = {}) {
    const state = store.getState();
    const { cookies } = state;

    if (!cookies?.[SAVI_COOKIE]) {
      store.dispatch(toggleSavedFilters());
    }

    return SearchLogic.fetchDataOnServer(store, location, params, fetchParams);
  }

  static afterFetchDataOnServer(store) {
    return SearchLogic.afterFetchDataOnServer(store);
  }

  state = {
    hasFilters: false,
    locationPathname: null,
    seoCopyCollapsed: true,
    feedbackSubmitted: false
  };

  componentDidMount() {
    const {
      cookieDomain,
      saveFilters,
      saviSizeCookie,
      isShowingThirdPartyAds,
      isCustomer,
      fetchAccountInfo,
      fetchCustomerAuthDetails,
      products: { trustedRetailers },
      customerId,
      adCustomerId,
      adEmailHash,
      updateAdData,
      triggerAssignment
    } = this.props;

    // todo
    /* This "apstagRemoveToken" function is added to remove the cookies that was generated to personalise the apstag ads on 6pm site. Currently the functionality
        is turned off but the cookies are not yet removed. They will automatically get removed after their lifetime, i.e. 14 days from turn off - we
        can remove this function after 9th Feb 2023. We are adding this function to make sure that users are not getting personalised content when
        personalisation is turned off from our side. */
    if (name === '6pm.com') {
      apstagRemoveToken();
    }

    if (hasApstagAdsToken) {
      apstagUpdateToken(customerId, adCustomerId, adEmailHash, fetchAccountInfo, updateAdData, fetchCustomerAuthDetails);
    }

    triggerAssignment(HYDRA_SEARCH_PRODUCT_HOVER);

    fireExplicitSearchEventFromLocalStorage();

    if (saviSizeCookie && isCustomer) {
      removeCookie(SAVI_SIZE_COOKIE, cookieDomain);
      saveFilters(null, true);
    }

    if (isShowingThirdPartyAds) {
      this.includeApsAds();
    }

    if (trustedRetailers.length) {
      this.sendTrustedRetailersImpression(trustedRetailers);
    }

    // resets facet to account for menu change at m-tabletPortrait
    this.resizeListener = window.matchMedia('(max-width: 768px)');
    this.resizeListener.addListener(this.resetFacet);
  }

  componentDidUpdate(prevProps) {
    // Mobile sticky header
    ensureClass(document.body, 'fixedSearchMenu');

    // If search term changes at all, reset the feedback form for another submission
    const {
      filters: {
        originalTerm,
        savedsizes,
        term,
        selected: { singleSelects }
      },
      location,
      products: { trustedRetailers },
      triggerAssignment
    } = this.props;

    const {
      filters: { originalTerm: prevOriginalTerm, term: prevTerm, selected: prevSelected },
      location: oldLocation,
      products: { oldTrustedRetailers }
    } = prevProps;

    const { singleSelects: prevSingleSelects } = prevSelected;
    const locationsDiffer = !deepEqual(location, oldLocation);

    if (term !== prevTerm && singleSelects !== prevSingleSelects) {
      triggerAssignment(HYDRA_BEST_GUESS);
    }

    if (originalTerm !== prevOriginalTerm) {
      this.setState({ feedbackSubmitted: false });
    }

    if (this.state.locationPathname !== location.pathname) {
      this.setState({
        locationPathname: location.pathname,
        hasFilters: ZSO_URL_WITH_FILTERS_RE.test(location.pathname) || SLASH_SEARCH_FILTERS_RE.test(location.pathname)
      });
    }

    if (locationsDiffer) {
      if (savedsizes && Object.values(savedsizes.filters).length && savedsizes.id && !this.sentSavedSizeImpression) {
        trackEvent('TE_SAVED_FILTERS_VISIBLE');
        this.sentSavedSizeImpression = true;
      } else if (this.sentSavedSizeImpression) {
        this.sentSavedSizeImpression = false;
      }
    }

    if ((trustedRetailers.length && !deepEqual(trustedRetailers), oldTrustedRetailers)) {
      this.sendTrustedRetailersImpression(trustedRetailers);
    }
  }

  componentWillUnmount() {
    this.resizeListener.removeListener(this.resetFacet);
  }

  sentSavedSizeImpression = false;

  includeApsAds = () => {
    const { addAdToQueue } = this.props;
    const slots = [{ name: SRP_WIDE_TOP }, { name: SRP_WIDE_MID }, { name: SRP_NARROW_TOP }];

    addAdToQueue(slots);
  };

  sendTrustedRetailersImpression = trustedRetailers => {
    track(() => [
      evSearchCrossSiteRecoImpression,
      {
        products: trustedRetailers,
        widgetType: 'CROSS_SITE_RECOMMENDATION'
      }
    ]);
  };

  onPagination = page => {
    const { trackEvent } = this.props;
    trackEvent('TE_SEARCH_PAGINATION', `${page}`);
  };

  makePagination = () => {
    const { filters } = this.props;
    return (
      filters.pageCount > 1 && (
        <Pagination
          firstPageIndex={0}
          page={filters.page}
          filters={filters}
          compact={false}
          smallerButtons={true}
          totalPages={filters.pageCount}
          onPagination={this.onPagination}
          useSearchPageStyles={true}
        />
      )
    );
  };

  searchScrollTop = () => {
    if (window) {
      const originalTop = window.scrollY;
      const step = originalTop / 10;
      setTimeout(() => {
        window.scrollTo(0, originalTop - step);
        if (window.scrollY > 0) {
          this.searchScrollTop();
        }
      }, 10);
    }
    document.querySelector('.searchPage article a').focus();
  };

  makeScrollButton = () => {
    const { testId } = this.context;
    return (
      <button
        type="button"
        ref={el => (this.backToTop = el)}
        className={css.backToTop}
        onClick={this.searchScrollTop}
        aria-label="scroll to top"
        data-test-id={testId('scrollToTop')}
      />
    );
  };

  makeSearchFooter = () => (
    <div className={css.searchFooterWrapper} id="searchPagination">
      {this.makePersonalizedSortToggle('bestForYouMobileMessaging', true)}
      {this.makePagination()}
      {this.makeSeoCopyBottomPosition()}
    </div>
  );

  makeScrollhandler = () => {
    const { backToTop } = this;
    if (window && document) {
      onEvent(
        document,
        'scroll',
        () => {
          const scrollTop = window.scrollY;
          if (scrollTop > 200 && !backToTop.classList.contains(css.visible)) {
            backToTop.classList.add(css.visible);
          } else if (scrollTop < 200 && backToTop.classList.contains(css.visible)) {
            backToTop.classList.remove(css.visible);
          }
        },
        null,
        this
      );
    }
  };

  onSortSelected = ({ target }) => {
    const { filters, trackEvent, cookieDomain, trackLegacyEvent, updateSort, updateBestForYou, getFacetUrlPath, getFacetUrlParams } = this.props;
    if (target.value === 'bestForYou-desc') {
      setCookie(SEARCH_BEST_FOR_YOU_COOKIE, '', { domain: cookieDomain });
      updateBestForYou(true);
    } else {
      updateBestForYou(false);
    }

    updateSort(sortStringToObject(target.value));

    const updatedZsoUrl = updateSortParamOfUrl(filters.executedSearchUrl, target.value);
    getFacetUrlPath(updatedZsoUrl);
    getFacetUrlParams(updatedZsoUrl);

    const payload = buildSearchTermEventPayload(filters.term);
    const { label } = (target[target.selectedIndex] || target).dataset;
    const sortByEventInfo = sanitizeForEvent(label);
    trackLegacyEvent('Search-Results-Page', `Sort-By-${sortByEventInfo}`, payload);
    trackEvent('TE_SORTMENU_SELECTSORT', sortByEventInfo);
    track(() => [
      evSortSearchClick,
      {
        sortType: label
      }
    ]);
  };

  runToggleFacetsContainer = opening => {
    const { toggleFacetsContainer } = this.props;
    toggleFacetsContainer(opening);
  };

  onApplyFilters = () => {
    const { trackEvent } = this.props;
    this.resetFacet();
    trackEvent('TE_SEARCH_APPLYFILTERS');
  };

  makeAccessibilityAnchors = hasProductResults => {
    const {
      filters: {
        selected: { singleSelects, multiSelects }
      },
      toggleFacetsContainer
    } = this.props;
    if (hasProductResults()) {
      const links = [
        {
          id: 'searchPage',
          value: 'Skip to search results'
        },
        {
          id: 'searchFilters',
          value: 'Skip to filters',
          callback: () => !isDesktop() && toggleFacetsContainer(true)
        },
        {
          id: 'searchSort',
          value: 'Skip to sort'
        },
        {
          id: 'searchSelectedFilters',
          value: !!Object.keys(singleSelects).length || !!Object.keys(multiSelects).length ? 'Skip to selected filters' : null
        }
      ];
      return <SkipLinks links={links} />;
    }
    return null;
  };

  handlePersonalizedBestForYou = () => {
    const { updateBestForYou, cookieDomain, filters, updateSort, getFacetUrlPath, getFacetUrlParams } = this.props;
    const isNewBestForYouStateActive = !filters.bestForYou || !filters.sort.bestForYou;
    const isBestForYouActive = isNewBestForYouStateActive ? 'bestForYou-desc' : 'relevance-desc';
    const updatedZsoUrl = updateSortParamOfUrl(filters.executedSearchUrl, isBestForYouActive);
    getFacetUrlPath(updatedZsoUrl);
    getFacetUrlParams(updatedZsoUrl);
    updateBestForYou(isNewBestForYouStateActive);
    updateSort(isNewBestForYouStateActive ? { bestForYou: 'desc' } : { relevance: 'desc' });
    setCookie(SEARCH_BEST_FOR_YOU_COOKIE, isNewBestForYouStateActive ? '' : 'active', { domain: cookieDomain });
    trackEvent('TE_PERSONALIZED_SEARCH_BFU_BUTTON', `${isNewBestForYouStateActive}`);
  };

  onToggleFacetsContainer = () => {
    const { isFacetsVisible, trackEvent } = this.props;
    const opening = !isFacetsVisible;
    this.runToggleFacetsContainer(opening);

    if (opening) {
      trackEvent('TE_SEARCH_OPENFILTERS');
    } else {
      trackEvent('TE_SEARCH_CLOSEFILTERS');
    }
  };

  resetFacet = () => {
    // toggles facets when screen hits tablet portrait
    this.runToggleFacetsContainer(false);
  };

  makeSeoCopyBottomPosition() {
    return this.makeSeoCopy(css.copyBottom);
  }

  makeSeoCopy(positionCSS) {
    const { testId } = this.context;
    const {
      filters: { seoData }
    } = this.props;

    const copy = seoData?.copy;
    if (copy) {
      return (
        <div className={cn(css.seoWrapper, positionCSS)} data-test-id={testId('seoWrapper')}>
          <HtmlToReact>{copy}</HtmlToReact>
        </div>
      );
    }
    return '';
  }

  toggleSeoCopy = () => {
    this.setState({ seoCopyCollapsed: !this.state.seoCopyCollapsed });
  };

  makeSeoText = () => {
    const {
      filters: { seoText, termLander, originalTerm },
      showSeoText
    } = this.props;
    const { testId } = this.context;

    if (showSeoText && (seoText || (termLander && originalTerm))) {
      return (
        <div className={css.seoWrapper}>
          {seoText && (
            <div className={css.seoText}>
              <p
                dangerouslySetInnerHTML={{
                  __html: sanitize(seoText)
                }}
              />
            </div>
          )}
          {termLander && originalTerm && (
            <p data-test-id={testId('termLanderMessage')}>
              We adjusted your search results to be more relevant.{' '}
              <Link to={`/search/null/orig/${originalTerm}`}>Not what you were looking for? Click here.</Link>
            </p>
          )}
        </div>
      );
    }
    return '';
  };

  onProductClicked = (productPosition, productId, styleId) => {
    const { filters, trackEvent, trackLegacyEvent } = this.props;
    const searchTerm = buildSearchTermEventPayload(filters.term);
    trackEvent('TE_SEARCH_CLICKPRODUCT', `${productId}:${styleId}:${productPosition}:${filters.page}`);
    // Duplicate event is expected #8854
    trackEvent('TE_SEARCH_CLICKTHROUGHPRODUCT', `${productId}:${styleId}:${productPosition}:${filters.page}`);
    trackLegacyEvent('Search-Results-Click-Through', formatProductClickEvent(filters.page, productPosition), searchTerm);
    trackLegacyEvent('Search-Results-Page', 'Results', productId);
  };

  onProductMediaHovered = (product, mainProductStyleId) => {
    track(() => [
      evProductInteract,
      {
        mainStyleId: mainProductStyleId,
        interactedProduct: product,
        interactionType: 'HOVER'
      }
    ]);
  };

  makeStickyHeader = () => {
    const { location } = this;
    const { filters, removePersonalizedSize, getFacetUrlParams, getFacetUrlPath, clearAutoComplete } = this.props;

    const clearFilters = () => {
      track(() => [evSearchClearFilters, {}]);
      this.clearFacetGroup();
    };

    return (
      <div className={css.stickyHeader}>
        <div className={cn(css.desktopSearchMenu, css.pillsDesktopSearchMenu)}>
          <CompactSingleSelects
            // The key below ensures this component re-renders each time a page is navigated,
            // Forcing pill state in search to reset to the left
            key={location?.pathname}
            filters={filters}
            clearFilters={clearFilters}
            handlePillClick={this.handlePillClick}
            removePersonalizedSize={removePersonalizedSize}
            hasAutoScroll={true}
            getFacetUrlParams={getFacetUrlParams}
            getFacetUrlPath={getFacetUrlPath}
            clearAutoComplete={clearAutoComplete}
          />
        </div>
      </div>
    );
  };

  makeJanusPixel = () => {
    const {
      filters: { originalTerm },
      location
    } = this.props;
    const queryParams = {
      widget: 'RecordSearch',
      txt: originalTerm || 'no-term'
    };
    return <JanusPixel location={location} queryParams={queryParams} />;
  };

  onFacetGroupSelect = event => {
    const { toggleSelectedFacetGroup } = this.props;
    const { selectedFacetGroupName: groupName, selectedFacetSection: section } = event.currentTarget.dataset;
    toggleSelectedFacetGroup(groupName, section);
  };

  makeProducts = ({ isCustomer, inlineBannerData } = {}) => {
    const { onProductClicked, onProductMediaHovered, onSortSelected, props, state, handleSearchFeedbackClick } = this;
    const {
      filters,
      location,
      products,
      showRatingStars,
      fetchProductDetail,
      heartProduct,
      unHeartProduct,
      toggleHeartingLoginModal,
      isFacetsVisible,
      trackSponsoredAdImpressions,
      crossSiteRecos,
      isLoadingSymphony,
      brandContents,
      brandContentSlotData
    } = props;
    const { feedbackSubmitted } = state;

    return (
      <Products
        makeScrollButton={this.makeScrollButton}
        products={products}
        filters={filters}
        onSortSelected={onSortSelected}
        page={filters.page}
        onProductClicked={onProductClicked}
        onProductMediaHovered={onProductMediaHovered}
        showRatings={showRatings}
        showRatingStars={showRatingStars}
        feedbackSubmitted={feedbackSubmitted}
        handleSearchFeedbackClick={handleSearchFeedbackClick}
        inlineBannerData={inlineBannerData}
        isCustomer={isCustomer}
        trackSponsoredAdImpressions={trackSponsoredAdImpressions}
        getProductInfo={fetchProductDetail}
        location={location}
        heartProduct={heartProduct}
        unHeartProduct={unHeartProduct}
        toggleHeartingLoginModal={toggleHeartingLoginModal}
        isFacetsVisible={isFacetsVisible}
        crossSiteRecos={crossSiteRecos}
        isLoadingSymphony={isLoadingSymphony}
        brandContents={brandContents}
        brandContentSlotData={brandContentSlotData}
      />
    );
  };

  clearFacetGroup = () => {
    const { resetFacetGroup, getFacetUrlPath, getFacetUrlParams, facets, filters } = this.props;
    const facetField = hasFacetListClear && facets.chosenFacetGroup ? facets.chosenFacetGroup.facetField : null;
    const clearAllFiltersUrl = createClearAllFiltersQuery(filters, sortOptions);
    getFacetUrlPath(clearAllFiltersUrl);
    getFacetUrlParams(clearAllFiltersUrl);
    resetFacetGroup(facetField);
  };

  handlePillClick = ({ name, value }, index) => {
    this.onFacetSelect(name, value, null, index);
  };

  clearChosenFacet = () => {
    const { setFacetChosen } = this.props;
    setFacetChosen(null);
    this.facets.scrollTop = 0;
  };

  facetDone = () => {
    this.resetFacet();
    this.clearChosenFacet();
  };

  saveFiltersRedirectToLogin = () => {
    const { cookieDomain, location, redirectToAuthenticationFor, filters } = this.props;
    const canSave = formatSavedFilters(filters);
    if (canSave) {
      setCookie(SAVI_SIZE_COOKIE, '1', { domain: cookieDomain });
    }
    redirectToAuthenticationFor(location);
  };

  onSaveSizeClick = () => {
    const { saveFilters, isCustomer } = this.props;
    if (!isCustomer) {
      this.saveFiltersRedirectToLogin();
    } else {
      saveFilters();
      trackEvent('TE_SAVED_FILTERS_SAVE_CLICK');
    }
  };

  onResetSizeClick = facetField => {
    const {
      deleteSavedFilters,
      filters: { savedsizes },
      saveFilters
    } = this.props;
    const savedFiltersWithValues = Object.values(savedsizes.filters).filter(v => v.length);
    const savedFilter = savedsizes.filters[facetField] || [];
    if (savedFilter.length) {
      if (savedFiltersWithValues.length > 1) {
        saveFilters(facetField);
      } else {
        if (savedsizes?.id) {
          deleteSavedFilters(savedsizes.id);
        }
      }
      trackEvent('TE_SAVED_FILTERS_RESET_CLICK');
    }
  };

  onSaveFeatureToggle = () => {
    const {
      cookieDomain,
      isCustomer,
      toggleSavedFilters,
      filters: { applySavedFilters }
    } = this.props;
    if (!isCustomer) {
      this.saveFiltersRedirectToLogin();
    } else {
      if (applySavedFilters) {
        trackEvent('TE_SAVED_FILTERS_TOGGLE_OFF');
      }
      setCookie(SAVI_COOKIE, applySavedFilters ? 'true' : '', {
        domain: cookieDomain
      });
      toggleSavedFilters();
    }
  };

  onFacetSelect = (facetGroup, facetName, selectedFacetGroupIndex, selectedFacetIndex, isPcm, section = null) => {
    const { filters, facets, togglePersonalizedSize } = this.props;
    if (facetGroup === BEST_FOR_YOU_FACETFIELD) {
      togglePersonalizedSize(facets);
      trackEvent('TE_PERSONALIZED_SEARCH_SIZE', `${!filters.personalizedSize?.facets?.[0]?.selected}`);
    } else {
      const { facets, filters, toggleSelectedFacet, trackEvent, trackLegacyEvent, clearAutoComplete } = this.props;

      if (filters.personalizedSize?.facets?.[0]?.selected && filters.personalizedSize?.sizes.indexOf(facetName) > -1) {
        togglePersonalizedSize();
      }

      toggleSelectedFacet(facetGroup, facetName, selectedFacetGroupIndex, selectedFacetIndex, section);
      clearAutoComplete(facetGroup);
      const facetLocation = isPcm ? 'pcm' : 'toDisplay';
      const facet = facets[facetLocation][selectedFacetGroupIndex];

      if (facet) {
        const facetGroupDisplayName = sanitizeForEvent(facet.facetFieldDisplayName);
        const sanitizedName = sanitizeForEvent(facetName);
        const searchTerm = buildSearchTermEventPayload(filters.term);
        trackLegacyEvent('Search-Results-Page', `Facet-Click-${facetGroupDisplayName}-${sanitizedName}`, searchTerm);
        trackLegacyEvent('Search-Results-Page', `FCT${facetGroup.toLowerCase()}`, sanitizedName);
        trackEvent('TE_SEARCH_FILTERS', `${facetGroupDisplayName}:${facetName}${isPcm ? ':pcm:true' : ''}`);
      }
    }
  };

  handleSearchFeedbackClick = feedback => {
    this.setState({ feedbackSubmitted: true });
    this.props.searchFeedbackClick(feedback);
  };

  makePersonalizedMessage = (isCompact = false) => {
    const { testId } = this.context;
    const {
      filters: { sort, bestForYouSortEligible }
    } = this.props;
    const optedText = !sort.bestForYou ? 'not ' : '';
    const toggleText = `Results are ${optedText}sorted based on your Preferences. `;

    if (bestForYouSortEligible) {
      return (
        <>
          <Tooltip
            tooltipId="personalizedSearch"
            wrapperClassName={css.tooltipWrapper}
            tooltipClassName={css.tooltip}
            direction="left"
            content={
              "Your own special blend is here! We're combining your feedback, recent purchases, and shopping behavior to sort your best results to the top."
            }
          >
            <UtilityStrokeInfoOutlineSmallIcon size={16} />
          </Tooltip>
          <span>
            {!isCompact && toggleText}
            <button
              type="button"
              onClick={this.handlePersonalizedBestForYou}
              data-test-id={testId('bestForYouButton')}
              aria-label={`Turn ${sort.bestForYou ? 'off' : 'on'} best for you sort.`}
            >
              Turn {sort.bestForYou ? 'off' : 'on'}
            </button>
          </span>
        </>
      );
    }
  };

  makePersonalizedSortToggle = (testIdName, isFooter = false, isCompact = false) => {
    const { testId } = this.context;
    return (
      <div data-test-id={testId(testIdName)} className={cn(css.hpsMessage, { [css.footer]: isFooter })}>
        {this.makePersonalizedMessage(isCompact)}
      </div>
    );
  };

  toggleShowMore = (facetField, index, section) => {
    const { toggleFacetGroupShowMore } = this.props;

    if (facetField && index >= 0 && section) {
      toggleFacetGroupShowMore(facetField, index, section);
    }
  };

  onModalOverlayClick = e => {
    const { isFacetsVisible } = this.props;
    if (isFacetsVisible && e.target.id === 'searchFilterModalOverlay') {
      this.facetDone();
    }
  };

  shouldShowCtaOnSearch = ({ executedSearchUrl }) => {
    if (!executedSearchUrl) {
      return false;
    }
    // must be empty zso which means no facets used.
    return isEmptyZsoUrl(executedSearchUrl);
  };

  render() {
    const {
      autoComplete,
      facets,
      filters,
      isFacetsVisible,
      location,
      onApplyFilters,
      params,
      products,
      topBannerData,
      inlineBannerData,
      isCustomer,
      toggleSizingFacetGroup,
      fetchSearchInlineRecos,
      redirectWithAppRoot,
      toggleFacetsContainer,
      clearInlineRecos,
      setOosMessaging,
      getHearts,
      pageTypeChange,
      getHeartCounts,
      setHFSearchTerm,
      setAndStoreCookie,
      sessionExpiration,
      fetchFromZso,
      fetchFromSearch,
      fetchSymphonySearchComponents,
      setUrlUpdated,
      triggerAssignment,
      isVip,
      landingPage,
      fetchLandingPageInfo,
      filter,
      isResponseFilter,
      getFacetUrlPath,
      getFacetUrlParams
    } = this.props;
    const { testId } = this.context;
    const isLoading = !!products.isLoading;

    return (
      <SiteAwareMetadata loading={isLoading || !products.executedSearchUrl}>
        <SearchLogic
          makeScrollhandler={this.makeScrollhandler}
          location={location}
          params={params}
          fetchSearchInlineRecos={fetchSearchInlineRecos}
          products={products}
          facets={facets}
          filters={filters}
          isFacetsVisible={isFacetsVisible}
          redirectWithAppRoot={redirectWithAppRoot}
          toggleFacetsContainer={toggleFacetsContainer}
          clearInlineRecos={clearInlineRecos}
          setOosMessaging={setOosMessaging}
          getHearts={getHearts}
          pageTypeChange={pageTypeChange}
          getHeartCounts={getHeartCounts}
          setHFSearchTerm={setHFSearchTerm}
          setAndStoreCookie={setAndStoreCookie}
          sessionExpiration={sessionExpiration}
          fetchFromZso={fetchFromZso}
          fetchFromSearch={fetchFromSearch}
          fetchSymphonySearchComponents={fetchSymphonySearchComponents}
          setUrlUpdated={setUrlUpdated}
          triggerAssignment={triggerAssignment}
          fetchLandingPageInfo={fetchLandingPageInfo}
          landingPage={landingPage}
          isVip={isVip}
        >
          {({ hasProductResults, shouldShowNoResults }) => (
            <div ref={e => (this.stickyPlaceholder = e)} className={cn(css.wrap, 'searchWrapper')} data-test-id={testId('searchPageWrapper')}>
              {!shouldShowNoResults() && (
                <div data-test-id={testId('searchResults')} className={css.searchResults}>
                  {this.makeAccessibilityAnchors(hasProductResults)}
                  <GamSlot slot={SRP_WIDE_TOP} className={css.displayAd} />
                  <GamSlot slot={SRP_NARROW_TOP} />
                  <DesktopSearchHeader
                    searchFilter={filter}
                    isResponseFilter={isResponseFilter}
                    searchTerm={filters.term}
                    trustedRetailers={products.trustedRetailers}
                    totalProductCount={products.totalProductCount}
                    onSortSelected={this.onSortSelected}
                    filters={filters}
                    makePersonalizedSortToggle={this.makePersonalizedSortToggle}
                    isFacetsVisible={isFacetsVisible}
                    onToggleFacetsContainer={this.onToggleFacetsContainer}
                  />
                  {this.shouldShowCtaOnSearch(filters) && topBannerData && (
                    <MelodyCategory
                      isSearchHeaderPlacement={true}
                      slotDetails={topBannerData}
                      slotIndex={0}
                      slotName={SYMPHONY_TOP_HEADER_SLOT_NAME}
                    />
                  )}
                  {topBannerData && !this.state.hasFilters && (
                    <TopBannerAd
                      slotDetails={topBannerData}
                      searchTerm={filters.term}
                      selectedFilters={filters.selected}
                      isFacetsVisible={isFacetsVisible}
                    />
                  )}
                  {this.makeStickyHeader()}
                  {this.makeSeoText()}
                  {this.makeProducts({ isCustomer, inlineBannerData })}
                  <div // eslint-disable-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
                    id="searchFilterModalOverlay"
                    className={cn({ [css.modalOverlay]: isFacetsVisible, [css.stickySearchFilters]: hasStickySearch })}
                    onClick={this.onModalOverlayClick}
                  >
                    <aside id="searchFilters" className={'facetWrapper facets'} ref={c => (this.facets = c)}>
                      <FocusTrap active={isFacetsVisible} shouldFocusFirstElement>
                        {focusRef => (
                          <div ref={focusRef} data-test-id={testId('facets')}>
                            <FacetMenu
                              clearChosenFacet={this.clearChosenFacet}
                              totalProductCount={products.totalProductCount}
                              facets={facets}
                              hasMobileLayeredFacets={true}
                              mobileFacetHeader={mobileFacetHeader}
                              facetDone={this.facetDone}
                            />
                            <Facets
                              autoComplete={autoComplete}
                              autoCompleteMinValues={autoCompleteMinValues}
                              onApplyFilters={onApplyFilters}
                              isFacetsVisible={isFacetsVisible}
                              filters={filters}
                              facets={facets}
                              products={products}
                              getFacetUrlParams={getFacetUrlParams}
                              getFacetUrlPath={getFacetUrlPath}
                              onFacetSelect={this.onFacetSelect}
                              onFacetGroupSelect={this.onFacetGroupSelect}
                              clearFacetGroup={this.clearFacetGroup}
                              runToggleFacetsContainer={this.runToggleFacetsContainer}
                              facetDone={this.facetDone}
                              facetHeader={facetHeader}
                              hasMultiSelectMessaging={true}
                              sortOptions={sortOptions}
                              onSortSelected={this.onSortSelected}
                              hasAutoComplete={true}
                              isCustomer={isCustomer}
                              onSaveSizeClick={this.onSaveSizeClick}
                              onResetSizeClick={this.onResetSizeClick}
                              saveFilters={saveFilters}
                              onSaveFeatureToggle={this.onSaveFeatureToggle}
                              toggleShowMore={this.toggleShowMore}
                              makePersonalizedSortToggle={this.makePersonalizedSortToggle}
                              toggleSizingFacetGroup={toggleSizingFacetGroup}
                            />
                            <FacetActions
                              clearFacetGroup={this.clearFacetGroup}
                              isFacetsVisible={isFacetsVisible}
                              onApplyFilters={onApplyFilters}
                              selectedFacet={facets.chosenFacetGroup}
                              facetDone={this.facetDone}
                              mobileCloseFiltersText={mobileCloseFiltersText}
                              mobileRemoveSelectionsText={mobileRemoveSelectionsText}
                            />
                          </div>
                        )}
                      </FocusTrap>
                      <GamSlot slot={SRP_WIDE_MID} className={css.adContainerOverride} />
                    </aside>
                  </div>
                  {this.makeJanusPixel()}
                  {this.makeSearchFooter({ hasProductResults })}
                  <div className="searchPage">
                    <BannerAdInTest className="mb-8 mt-2" />
                  </div>
                </div>
              )}
            </div>
          )}
        </SearchLogic>
      </SiteAwareMetadata>
    );
  }
}

StandardSearch.defaultProps = {
  trackEvent,
  trackLegacyEvent
};

StandardSearch.contextTypes = {
  testId: PropTypes.func.isRequired,
  marketplace: PropTypes.object.isRequired
};

StandardSearch.propTypes = {
  products: PropTypes.object.isRequired,
  filters: PropTypes.object,
  facets: PropTypes.object
};

function mapStateToProps(state) {
  const {
    autoComplete,
    cookies,
    facets,
    filters,
    isFacetsVisible,
    search: {
      symphony: { slotData },
      isLoadingSymphony
    },
    headerFooter: { filter, isResponseFilter },
    products,
    url: { userAgent },
    killswitch: { isShowingThirdPartyAds },
    landingPage,
    rewards,
    ads: { adCustomerId, adEmailHash },
    holmes: { customerId },
    influencerContent: { contents: brandContents, brandInfluencerContentSlotData: brandContentSlotData }
  } = state;

  const isCustomer = state.cookies['x-main'];

  return {
    autoComplete,
    cookieDomain,
    facets,
    filters,
    isFacetsVisible,
    isShowingThirdPartyAds,
    isCustomer,
    brandContents,
    brandContentSlotData,
    landingPage,
    products,
    saviSizeCookie: cookies[SAVI_SIZE_COOKIE] || null,
    showRatingStars,
    showSeoText,
    topBannerData: slotData?.[SYMPHONY_TOP_HEADER_SLOT_NAME],
    inlineBannerData: slotData?.['search-results-1'],
    crossSiteRecos: slotData?.['cross-site-search-results-1'],
    isLoadingSymphony: isLoadingSymphony,
    isVip: rewards?.rewardsInfo?.isVipOrConsented,
    userAgent,
    adCustomerId,
    adEmailHash,
    customerId,
    filter,
    isResponseFilter
  };
}

export const mapDispatchToProps = {
  addAdToQueue,
  clearAutoComplete,
  deleteSavedFilters,
  fetchProductDetail,
  heartProduct,
  redirectToAuthenticationFor,
  removeFromStoredCookies,
  removePersonalizedSize,
  resetFacetGroup,
  toggleHeartingLoginModal,
  setFacetChosen,
  toggleFacetGroupShowMore,
  toggleFacetsContainer,
  togglePersonalizedSize,
  toggleSelectedFacet,
  toggleSelectedFacetGroup,
  trackSponsoredAdImpressions,
  toggleSizingFacetGroup,
  triggerAssignment,
  updateBestForYou,
  unHeartProduct,
  updateSort,
  getFacetUrlParams,
  getFacetUrlPath,
  searchFeedbackClick,
  saveFilters,
  toggleSavedFilters,
  fetchSearchInlineRecos,
  redirectWithAppRoot,
  clearInlineRecos,
  setOosMessaging,
  getHearts,
  pageTypeChange,
  getHeartCounts,
  setHFSearchTerm,
  setAndStoreCookie,
  sessionExpiration,
  fetchFromZso,
  fetchFromSearch,
  fetchSymphonySearchComponents,
  setUrlUpdated,
  fetchLandingPageInfo,
  fetchAccountInfo,
  updateAdData,
  fetchCustomerAuthDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(StandardSearch);
