import React from 'react';

import css from 'styles/containers/layout/grid.scss';

export interface GridProps {
  children: React.ReactNode;
  columns: 'auto-fit' | number;
}

const Grid = ({ children, columns }: GridProps) => (
  <div className={css.container} style={{ '--grid-columns': columns } as React.CSSProperties}>
    {children}
  </div>
);

export default Grid;
