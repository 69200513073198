import React from 'react';
import { Link } from 'react-router-dom';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import { cn } from 'helpers/classnames';
import useMartyContext from 'hooks/useMartyContext';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import type { AppState } from 'types/app';

import css from 'styles/components/productdetail/shippingAndReturnsBanner.scss';

type OwnProps = {
  isFullMaxWidth?: boolean;
};

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = OwnProps & PropsFromRedux;
export const ShippingAndReturnsBanner = ({ shippingPromoBanner = {}, isFullMaxWidth = false }: Props) => {
  const { heading, cta, link, copy } = shippingPromoBanner;

  const { testId } = useMartyContext();

  return (
    <div className={cn(css.container, { [css.fullMaxWidth]: isFullMaxWidth })} data-test-id={testId('shipReturnSection')}>
      <h2 className={css.heading}>{heading}</h2>
      <div>
        <div className={css.text}>
          <p className={css.info} data-test-id={testId('shipReturnsText')}>
            {copy}
          </p>
          <Link to={link || ''} className={css.link} data-test-id={testId('shipReturnPolicyLink')}>
            {cta}
          </Link>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state: AppState) {
  return {
    shippingPromoBanner: state.product.symphonyStory.slotData?.shippingPromoBanner
  };
}

const connector = connect(mapStateToProps);

const ConnectedShippingAndReturnsBanner = connector(ShippingAndReturnsBanner);

export default withErrorBoundary('ShippingAndReturnsBanner', ConnectedShippingAndReturnsBanner);
