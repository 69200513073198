import timedFetch from 'middleware/timedFetch';
import { generateIncludes } from 'apis/calypso';
import { addClientHeaders } from 'apis/mafia';

// server side fn to invoke our diamond service.
// we chose to dynamically import this so it doesnt appear on the client (which it shouldn't).
export async function useDiamondOnServer() {
  const { diamond } = await import('services/zfcDiamond/index');
  return diamond;
}

// client request to the marty server /diamondDetails endpoint.
export async function fetchDiamondDetails({ uri, proxy = true }, request = {}, appState, fetcher = timedFetch('diamondFetch')) {
  const mafiaHeaders = addClientHeaders(request); // need?
  const headers = { ...mafiaHeaders };

  const includes = generateIncludes(appState);
  const reqUrl = `/marty/diamondDetails?proxy=${proxy}&includes=${includes}&uri=${uri}`;

  return fetcher(reqUrl, {
    headers,
    credentials: 'include'
  });
}
