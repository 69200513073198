import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';

import { cn } from 'helpers/classnames';
import { isDesktop } from 'helpers/ClientUtils';
import { useWindowEvent } from 'hooks/useEvent';
import MelodyModal from 'components/common/MelodyModal';

import css from 'styles/components/common/bottomDrawer.scss';

// This is only visible on mobile

export const BottomDrawer = props => {
  const { children, className, onRequestClose } = props;
  const modalProps = {
    ...props,
    className: cn(css.modalContent, className),
    closeTimeoutMS: 300 // for animations, see scss file & melody for timing
  };

  // Close out the drawer on desktop
  const onResize = useCallback(
    debounce(() => {
      if (isDesktop()) {
        onRequestClose();
      }
    }, 100),
    []
  );

  useWindowEvent('resize', onResize);

  return <MelodyModal {...modalProps}>{children}</MelodyModal>;
};

BottomDrawer.contextTypes = {
  testId: PropTypes.func
};

export default BottomDrawer;
