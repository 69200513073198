import React, { Fragment, useEffect, useState } from 'react';

import { cn } from 'helpers/classnames';
import type { ProductImage } from 'types/cloudCatalog';
import type { GalleryProductBundle } from 'reducers/detail/productDetail';
import {
  filterProductPhotos,
  getPDPTrackingPropsFormatted,
  getProductImagesFormatted,
  getProductVideoFormatted,
  SIX_SHOT_PHOTO_PRODUCTS
} from 'helpers/ProductUtils';
import useMartyContext from 'hooks/useMartyContext';
import Grid from 'containers/layout/Grid';
import Image from 'components/common/zoom/Image';
import MelodyVideoPlayer from 'components/common/melodyVideo/MelodyVideoPlayer';
import ThumbnailCarousel from 'components/productdetail/productGallery/ThumbnailCarousel';
import type { FeaturedImage, PDPFeaturedVideo } from 'types/product';
import { track } from 'apis/amethyst';
import { evProductImageImpression } from 'events/product';

import css from 'styles/components/productdetail/productGallery/gallery.scss';

export interface GalleryProps {
  images: ProductImage[];
  product: GalleryProductBundle;
  styleId: string;
  imageChildren?: React.ReactNode;
  isHydraPhotoAngles: boolean;
}

const Gallery = (props: GalleryProps) => {
  const { images, product, styleId, imageChildren, isHydraPhotoAngles } = props;
  const { testId } = useMartyContext();
  const {
    productName,
    brandName,
    productId,
    defaultProductType,
    videos,
    youtubeData: { embedUrl, videoId }
  } = product || {};
  const isYouTubeVideo = !!videoId;
  const prependedAltText = `${brandName} ${productName}`;

  const productImages: FeaturedImage[] = getProductImagesFormatted(images, defaultProductType) || [];

  const filteredProductImages: FeaturedImage[] = isHydraPhotoAngles
    ? filterProductPhotos(productImages, { productId, styleId }, SIX_SHOT_PHOTO_PRODUCTS)
    : productImages;

  const { length } = filteredProductImages;
  const productVideo: PDPFeaturedVideo | undefined = getProductVideoFormatted(videos, productId, length, isYouTubeVideo, embedUrl);
  const mainImage = length > 0 && filteredProductImages[0];
  const { imageId, type } = mainImage || {};
  const [thumbnailImage, setThumbnailImage]: any = useState(null);

  const handleImageZoom = (isZoomed: boolean) => {
    if (!isZoomed) {
      setThumbnailImage(null);
    }
  };

  const onThumbnailSelected = (thumbnailIndex: number) => {
    const asset = filteredProductImages[thumbnailIndex];
    if (asset) {
      setThumbnailImage(asset);
    }
  };

  const createThumbnailCarousel = (index: number) => (
    <ThumbnailCarousel
      defaultSelectedIndex={index}
      onThumbnailSelected={onThumbnailSelected}
      prependedAltText={prependedAltText}
      assets={filteredProductImages}
    />
  );

  useEffect(() => {
    track(() => [
      evProductImageImpression,
      {
        productId,
        styleId,
        imageAngles: filteredProductImages.map(image => image.angleType)
      }
    ]);
  }, [productId, styleId]);

  return (
    <>
      {mainImage &&
        length === 1 && ( // show just the main image if theres only 1
          <div className={css.imageWrapper} data-test-id={testId('mainProductImage')}>
            <Image
              minHeight={600}
              key={imageId}
              image={mainImage}
              zoomedImageOverride={thumbnailImage}
              showInstructions={false}
              prependedAltText={prependedAltText}
              isOverlayShown={true}
              tracking={getPDPTrackingPropsFormatted('Zoom-In', `${type}-Click`)}
              onZoom={handleImageZoom}
              additionalZoomContent={createThumbnailCarousel(0)}
            />
            {imageChildren}
          </div>
        )}
      {length > 1 && (
        <Grid columns={2}>
          {filteredProductImages.map((asset, index) => {
            const { imageId, type } = asset;

            const isFirstImage = index === 0;

            const showImageChildren = !!imageChildren;
            const imageChildrenInsertionIndex = 0;

            return (
              <Fragment key={imageId + index}>
                <div
                  className={cn(css.imageWrapper, { [css.twoColumnImage]: isFirstImage })}
                  data-test-id={isFirstImage ? testId('mainProductImage') : undefined}
                >
                  <Image
                    minHeight={550}
                    image={asset}
                    zoomedImageOverride={thumbnailImage}
                    showInstructions={false}
                    prependedAltText={prependedAltText}
                    isOverlayShown={true}
                    tracking={getPDPTrackingPropsFormatted('Zoom-In', `${type}-Click`)}
                    onZoom={handleImageZoom}
                    additionalZoomContent={createThumbnailCarousel(index)}
                  />
                  {showImageChildren && imageChildrenInsertionIndex === index && imageChildren}{' '}
                  {/* If we are on the 2nd image show the reco drawer / image children */}
                </div>
              </Fragment>
            );
          })}
        </Grid>
      )}
      {productVideo && <MelodyVideoPlayer {...productVideo} isAutoplaying={false} isFullWidth={true} trackInView />}
    </>
  );
};

export default Gallery;
