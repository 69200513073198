import React, { Component } from 'react';
import { parseUrl } from 'query-string';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import { TrackRecosInView } from 'components/productdetail/TrackRecosInView';
import type { AppState } from 'types/app';
import Recos from 'components/productdetail/Recos';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import { getRecoSlotData, getRecoSlotKey, shouldRecosUpdate } from 'helpers/RecoUtils';
import type { RecosState } from 'reducers/recos';
import { areRecosFlattened } from 'reducers/recos';
import type { JanusData } from 'types/mafia';
import { onEvent } from 'helpers/EventHelpers';
import ProductUtils from 'helpers/ProductUtils';

interface Params {
  productId: string;
  colorId?: string;
  seoName?: string;
}
interface OwnProps {
  onRecoClicked: (...args: any) => void;
  params: Params;
  similarProductRecos: RecosState;
  styleId: string;
  heartsData: any; // TODO ts type this when `hearts` are typed
  numberOfGridColumns?: number;
}
interface State {
  productIdFromHash: string;
  styleIdFromHash: string;
}

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = OwnProps & PropsFromRedux;

export class RecosDetail2 extends Component<Props> {
  state: State = {
    productIdFromHash: '',
    styleIdFromHash: ''
  };

  componentDidMount() {
    this.setIdsFromHash();
    onEvent(window, 'popstate', this.setIdsFromHash, undefined, this);
  }

  // We don't want this component to re-render when it's either loading, or similarProductRecos haven't changed
  shouldComponentUpdate(nextProps: Props) {
    return shouldRecosUpdate(this.props, nextProps);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.setIdsFromHash);
  }

  setIdsFromHash = () => {
    const { urlHash } = this.props;
    if (urlHash.includes('#quickview')) {
      const {
        query: { styleId, productId }
      } = parseUrl(urlHash);
      this.setState({
        productIdFromHash: productId,
        styleIdFromHash: styleId
      });
    } else {
      this.setState({
        productIdFromHash: '',
        styleIdFromHash: ''
      });
    }
  };

  render() {
    const { heartsData, numberOfGridColumns, onRecoClicked, similarProductRecos = {} } = this.props;

    // If we have recommendations, render the component.
    const { janus = {}, lastReceivedRecoKey = '' } = similarProductRecos;
    const janusData = janus[lastReceivedRecoKey] || {};
    let janusRecos: JanusData | undefined;
    if (!areRecosFlattened(janusData)) {
      janusRecos = getRecoSlotData(janusData[getRecoSlotKey(2)]);
    }
    if (janusRecos) {
      const { title, recos } = janusRecos;

      return (
        <TrackRecosInView
          products={recos}
          impression={{ widgetType: ProductUtils.translateRecoTitleToAmethystWidget(title), numberOfRecommendations: janusRecos?.recos.length }}
        >
          <Recos
            id="alsoLike"
            recoType="associatedProducts"
            title={title}
            recos={recos}
            onRecoClicked={onRecoClicked}
            heartsData={heartsData}
            numberOfGridColumns={numberOfGridColumns}
          />
        </TrackRecosInView>
      );
    }

    return null;
  }
}

const mapStateToProps = (state: AppState) => {
  const {
    router: {
      location: { hash = '' }
    }
  } = state;

  return { urlHash: hash };
};
const connector = connect(mapStateToProps, {});
export default withErrorBoundary('RecosDetail2', connector(RecosDetail2));
