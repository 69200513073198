import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { cn } from 'helpers/classnames';
import { isExternalReferrer } from 'helpers/index';
import marketplace from 'cfg/marketplace.json';
import Grid from 'containers/layout/Grid';
import ProductCard from 'components/common/ProductCard';
import ProductUtils from 'helpers/ProductUtils';
import useMartyContext from 'hooks/useMartyContext';
import type { FormattedJanusReco } from 'types/mafia';
import ColorSwatchWrapper from 'components/search/ColorSwatches';
import { ProductDetailCarousel } from 'components/productdetail/ProductDetailCarousel';
import { getProductWithRelatedStyles } from 'helpers/RecoUtils';
import { track } from 'apis/amethyst';
import { evProductInteract } from 'events/search';
import type { Product } from 'constants/searchTypes';
import type { ProductWithRelations } from 'types/calypso';
import { RECOMMENDATION_CTA_CLICK } from 'constants/amethystEnums';
import { getBadgeId, getRowHasBadge } from 'helpers/BadgeUtils';
import { setHFSearchTerm } from 'actions/headerfooter';
import type { AppState } from 'types/app';
import type { HeartProps } from 'types/hearts';

import css from 'styles/components/productdetail/recos.scss';

const {
  recos: { showRecosOnTopForExternalReferrer },
  search: { msaImageParams, enableColorSwatches }
} = marketplace;

interface Props {
  additionalMelodyCardProductClasses?: any; // TODO ts type this if/when MCP is typed
  belowImageRenderer?: (...args: any) => JSX.Element | null;
  id?: string;
  isVertical?: boolean;
  heartsData?: HeartProps;
  noBackground?: boolean;
  onRecoClicked: (...args: any) => void;
  recos: FormattedJanusReco[];
  recoType?: string;
  showTitle?: boolean;
  title?: string;
  /** Allows you to specify how many visible cards are in the carousel at one time */
  numberOfVisibleCards?: number;
  intersectionRef?: (node?: Element | null | undefined) => void;
  /** Display the recos in a gridlayout given the number of columns */
  numberOfGridColumns?: number;
  /** Ref for the title of the reco <h2> */
  titleRef?: React.RefObject<HTMLHeadingElement>;
  isFullMaxWidth?: boolean;
}

const Recos = (props: Props) => {
  const {
    showTitle = true,
    title = 'You Might Also Like',
    id = null,
    isVertical,
    onRecoClicked,
    recos,
    recoType,
    numberOfVisibleCards,
    intersectionRef,
    numberOfGridColumns,
    titleRef,
    isFullMaxWidth = false,
    belowImageRenderer
  } = props;
  const { testId } = useMartyContext();
  const recoProductRelations = useSelector((state: AppState) => state.products.recoProductRelations);

  if (!recos || !(recos.length > 0)) {
    return null;
  }

  let showRecosOnTop = false;
  let isTopRecos = false;
  let isBottomRecos = false;
  if (showRecosOnTopForExternalReferrer) {
    showRecosOnTop = isExternalReferrer();
    if (recoType === 'subRecos') {
      isTopRecos = showRecosOnTop;
      isBottomRecos = !showRecosOnTop;
    }
  }

  const amethystRecoType = ProductUtils.translateRecoTitleToAmethystWidget(title);

  const rowHasBadge = getRowHasBadge(recos, recoProductRelations);
  const shouldUseCarousel =
    (recos.length > 4 && recoType !== 'gridRecos' && !numberOfGridColumns) ||
    (recos.length > 3 && isTopRecos && !numberOfGridColumns) ||
    !numberOfGridColumns;

  const recoElements = recos.map((reco, index) => {
    const { link, productUrl, styleId } = reco;
    const url = link || productUrl;
    const productWithRelatedStyles = getProductWithRelatedStyles(styleId, recoProductRelations);
    const { relatedStyles, ...productInfo } = productWithRelatedStyles;

    const recoProps = { ...reco, productType: productInfo?.productType, txAttrFacet_Gender: productInfo?.txAttrFacet_Gender };

    return (
      <Reco
        {...props}
        key={`${reco.productId}-${styleId}`}
        reco={recoProps}
        index={index}
        productUrl={url}
        onRecoClicked={onRecoClicked}
        amethystRecoType={amethystRecoType}
        belowImageRenderer={belowImageRenderer}
        relatedStyles={enableColorSwatches && relatedStyles ? relatedStyles : []}
        productInfo={productInfo}
        isFullMaxWidth={isFullMaxWidth}
        rowHasBadge={rowHasBadge}
        shouldUseCarousel={shouldUseCarousel}
      />
    );
  });

  if (shouldUseCarousel) {
    return (
      <div className={css.carouselContainer} ref={intersectionRef} data-test-id={testId(`recoCarousel_${amethystRecoType}`)}>
        <ProductDetailCarousel title={title} slides={recoElements} />
      </div>
    );
  }

  return (
    <div ref={intersectionRef} className={cn({ [css.bottomRecos]: isBottomRecos })} data-test-id={testId(`recoCarousel_${amethystRecoType}`)}>
      {showTitle && <h2 ref={titleRef}>{title}</h2>}
      <div
        style={
          numberOfVisibleCards ?? numberOfGridColumns
            ? ({
                '--width-split': numberOfVisibleCards ?? 1 // fullwidth when in grid layout
              } as React.CSSProperties)
            : undefined
        }
        id={id || undefined}
        data-test-id={testId(id)}
        className={cn(css.recos, {
          [css.gridRecos]: recoType === 'gridRecos',
          [css.topRecos]: isTopRecos,
          [css.bottomRecos]: isBottomRecos,
          [css.noCarousel]: recos.length < 5,
          [css.vertical]: isVertical,
          [css.fullMaxWidth]: isFullMaxWidth,
          [css.gridColumns]: Boolean(numberOfGridColumns)
        })}
      >
        {Boolean(numberOfGridColumns) && <Grid columns={numberOfGridColumns}>{recoElements}</Grid>}
      </div>
    </div>
  );
};

Recos.defaultProps = {
  noBackground: false
};

interface RecoProps extends Props {
  amethystRecoType: string | null;
  index: number;
  onRecoClicked: (...args: any[]) => void;
  reco: FormattedJanusReco;
  productUrl?: string;
  productInfo: any; // TODO cleanup various product type definitions (search product, calypso related product)
  relatedStyles: ProductWithRelations[];
  rowHasBadge?: boolean;
  shouldUseCarousel?: boolean;
}

export const Reco = (props: RecoProps) => {
  const {
    amethystRecoType,
    heartsData = {},
    index,
    onRecoClicked,
    reco,
    belowImageRenderer,
    productInfo,
    relatedStyles,
    isFullMaxWidth,
    rowHasBadge,
    shouldUseCarousel
  } = props;
  const { testId } = useMartyContext();
  const { styleId, productId, link, productUrl } = reco;
  const url = link || productUrl;
  const recoSource = useSelector((state: AppState) => state?.recos?.source || '');
  const dispatch = useDispatch();

  const { badges } = productInfo;

  const recommendedProduct = {
    productId,
    supplementalData: {
      badgeId: getBadgeId(badges)
    }
  };

  if (relatedStyles?.length) {
    const onProductMediaHovered = (product: Product, mainProductStyleId: string) => {
      track(() => [
        evProductInteract,
        {
          mainStyleId: mainProductStyleId,
          interactedProduct: product,
          interactionType: 'HOVER'
        }
      ]);
    };

    return (
      <ColorSwatchWrapper
        {...productInfo}
        relatedStyles={relatedStyles}
        heartsInfo={heartsData}
        key={`${productInfo.styleId}-${productInfo.colorId}`}
        testId={testId('productReco')}
        msaImageParams={msaImageParams}
        onClick={() => {
          dispatch(setHFSearchTerm(''));
          onRecoClicked({ index, amethystRecoType, recoSource, recommendedProduct });
        }}
        onProductMediaHovered={onProductMediaHovered}
        // reco exclusives
        className={cn({
          [css.recoCard]: !shouldUseCarousel,
          [css.displaySixCardTiles]: isFullMaxWidth
        })}
        productUrl={url}
        CardDetailsTopSlot2={belowImageRenderer} //ColorSwatches uses CardDetailsTopSlot
        showRecosBadge={true}
        badges={badges}
        imageBadgeClassName={rowHasBadge && css.rowHasBadge}
        amethystRecoType={amethystRecoType}
        isMobileBlockLayout
      />
    );
  }

  return (
    <ProductCard
      key={`d-${styleId}`}
      className={cn({
        [css.recoCard]: !shouldUseCarousel,
        [css.displaySixCardTiles]: isFullMaxWidth
      })}
      hearts={heartsData}
      msaImageParams={msaImageParams}
      data-test-id={testId('productReco')}
      {...reco}
      makeStarsBlock={productInfo.reviewCount > 0}
      productRating={productInfo.productRating}
      reviewCount={productInfo.reviewCount}
      productUrl={url}
      onClick={() => {
        dispatch(setHFSearchTerm(''));
        onRecoClicked({ index, amethystRecoType, recoSource, recommendedProduct, clickThrough: RECOMMENDATION_CTA_CLICK.PRODUCT_TILE });
      }}
      CardDetailsTopSlot={belowImageRenderer}
      showRecosBadge={true}
      badges={badges}
      imageBadgeClassName={rowHasBadge && css.rowHasBadge}
      amethystRecoType={amethystRecoType}
      isMobileBlockLayout
    />
  );
};

export default Recos;
