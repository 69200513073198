import type { Dispatch, ReactElement, SetStateAction } from 'react';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AnyAction } from 'redux';
import type { ThunkDispatch } from 'redux-thunk';

import { cn } from 'helpers/classnames';
import type { ImageDimensionMap, ImageDimensions, OutfitRecoContent, RecoCardProduct } from 'types/outfitRecos';
import { ImageCategory, RecoCardLayout } from 'types/outfitRecos';
import { constructMSAImageUrl, makeAscii } from 'helpers/index';
import { addImageDimensions } from 'actions/outfitRecos';
import { selectOutfitRecos } from 'selectors/outfitRecos';
import CardPrice from 'components/common/card/CardPrice';
import { createCurrencyObj } from 'helpers/DataFormatUtils';
import ProductUtils from 'helpers/ProductUtils';
import { SmallLoader } from 'components/Loader';
import OutfitFeedback from 'components/outfitRecos/OutfitFeedback';
import type { AppState } from 'types/app';
import Tag from 'tailwind/components/Tag/Tag';
import { track } from 'apis/amethyst';
import { evOutfitRecoCardClick } from 'events/outfitRecos';
import { areAllProductsBuyable, generateLabelForProduct, generateOutfitGroup } from 'helpers/outfitRecoUtils';
import ImageLazyLoader from 'components/common/ImageLazyLoader';
import { MSA_SETTINGS_JPG_85 } from 'constants/shoppablePosts';
import type { ProductWithRelationsFromCalypso } from 'types/calypso';
import Carousel from 'components/common/Carousel/Carousel';

import css from 'styles/components/outfitRecos/recosCardV3.scss';

interface RecosCardV3Props {
  title?: string;
  contents: OutfitRecoContent[];
  contentIndex: number;
  setContentIndex: Dispatch<SetStateAction<number>>;
  setIsQuickViewModalOpen: Dispatch<SetStateAction<boolean>>;
  productRelations: { [s: string]: ProductWithRelationsFromCalypso } | undefined;
}

interface ProductCardProps {
  openModal: () => void;
  setIndexForModal: () => void;
  triggerEventForRecoCardClick: (clickStyleId: string) => void;
  productRelations: { [s: string]: ProductWithRelationsFromCalypso } | undefined;
}

export const RecosCardV3 = ({ title, contents, setIsQuickViewModalOpen, setContentIndex, productRelations }: RecosCardV3Props) => {
  const dispatch = useDispatch();

  const { imageDimensions } = useSelector(selectOutfitRecos);

  useEffect(() => {
    contents.forEach(content => {
      const { productList } = content;
      productList.forEach(product => {
        const { imageId } = product;
        loadImage(imageId, dispatch);
      });
    });
  }, [contents]);

  const openModal = () => setIsQuickViewModalOpen(true);

  const carouselSlides = useMemo(
    () =>
      contents
        .map((content, index) => {
          const { productList, layout, complementaryGroupId } = content;
          const setIndexForModal = () => setContentIndex(index);
          const outfitNumber = index + 1;
          const triggerEventForRecoCardClick = (clickStyleId: string) => {
            const outfitGroup = generateOutfitGroup(complementaryGroupId, productList);
            const { styleId: seedStyleId } = productList.find(product => product.currentlyViewing)!;
            track(() => [
              evOutfitRecoCardClick,
              {
                seedStyleId,
                outfitGroup,
                clickStyleId,
                outfitNumber
              }
            ]);
          };
          const productCardProps: ProductCardProps = {
            openModal,
            setIndexForModal,
            triggerEventForRecoCardClick,
            productRelations
          };
          return makeRecosCard(productList, layout, complementaryGroupId, imageDimensions, productCardProps, outfitNumber);
        })
        .filter((card: ReactElement | null): card is ReactElement => card !== null) || [],
    [contents, imageDimensions]
  );

  return <Carousel header={{ title }} slides={carouselSlides} slideWidths={{ mobile: '100%', tablet: '100%', desktop: '50%' }} />;
};

export default RecosCardV3;

const makeRecosCard = (
  products: RecoCardProduct[],
  cardLayout: RecoCardLayout,
  groupId: string,
  imageDimensions: ImageDimensionMap,
  productCardProps: ProductCardProps,
  outfitNumber: number
) => {
  const { productRelations } = productCardProps;
  switch (cardLayout) {
    case RecoCardLayout.DEFAULT:
      if (products.length !== 4 || !areAllProductsBuyable(products, productRelations)) return null;

      const [topLeftProduct, bottomLeftProduct, topRightProduct, bottomRightProduct] = products;

      const column1CSS = calculateProductSlotDimensions(topLeftProduct!, bottomLeftProduct!, imageDimensions);
      const column2CSS = calculateProductSlotDimensions(topRightProduct!, bottomRightProduct!, imageDimensions);

      return (
        <div key={groupId} className={css.recosCard}>
          <div className={css.defaultLayoutContainer}>
            <div className={css.column}>
              {makeProductSlot(`${groupId}-0`, topLeftProduct, column1CSS.topClass, productCardProps)}
              {makeProductSlot(`${groupId}-1`, bottomLeftProduct, column1CSS.bottomClass, productCardProps)}
            </div>
            <div className={css.column}>
              {makeProductSlot(`${groupId}-2`, topRightProduct, column2CSS.topClass, productCardProps)}
              {makeProductSlot(`${groupId}-3`, bottomRightProduct, column2CSS.bottomClass, productCardProps)}
            </div>
          </div>
          <OutfitFeedback groupId={groupId} products={products} outfitNumber={outfitNumber} />
        </div>
      );
    default:
      return <></>;
  }
};

const calculateProductSlotDimensions = (topProduct: RecoCardProduct, bottomProduct: RecoCardProduct, imageDimensions: ImageDimensionMap) => {
  const topProductDimensions = imageDimensions[topProduct.imageId];
  const bottomProductDimensions = imageDimensions[bottomProduct.imageId];
  const topProductCategory: ImageCategory = categorizeImage(topProductDimensions);
  const bottomProductCategory: ImageCategory = categorizeImage(bottomProductDimensions);

  if (topProductCategory === bottomProductCategory) {
    return {
      topClass: css.equalProduct,
      bottomClass: css.equalProduct
    };
  } else if (topProductCategory === ImageCategory.PORTRAIT) {
    return {
      topClass: css.bigProduct,
      bottomClass: css.smallProduct
    };
  } else {
    return {
      topClass: css.smallProduct,
      bottomClass: css.bigProduct
    };
  }
};

const categorizeImage = (imageDimensions: ImageDimensions = { height: 0, width: 0 }) => {
  const { height, width } = imageDimensions;
  if (height! > width!) {
    return ImageCategory.PORTRAIT;
  } else {
    return ImageCategory.LANDSCAPE;
  }
};

const loadImage = (imageId: string, dispatch: ThunkDispatch<AppState, void, AnyAction>) => {
  const imageUrl = constructMSAImageUrl(imageId, { autoCrop: true });
  const img = new Image();
  img.src = imageUrl;

  img.onload = () => {
    dispatch(
      addImageDimensions({
        [imageId]: {
          height: img.height,
          width: img.width
        }
      })
    );
  };
};

const makeProductSlot = (key: string, product: RecoCardProduct | undefined, className: string, productCardProps: ProductCardProps) => {
  const { styleId, currentlyViewing } = product || { styleId: '' };

  const { openModal, setIndexForModal, triggerEventForRecoCardClick, productRelations } = productCardProps;

  const Loader = (
    <div key={key} className={css.loader}>
      <SmallLoader />
    </div>
  );

  if (!productRelations || !(+styleId in productRelations)) {
    return Loader;
  }

  const { brandName, productName, productUrl, originalPrice, price, color } = productRelations[styleId]!;

  const { imageId } = product!;

  const msaOpts = {
    width: 500,
    autoCrop: true,
    customSettings: MSA_SETTINGS_JPG_85
  };

  const productCardClicked = () => {
    setIndexForModal();
    openModal();
    triggerEventForRecoCardClick(styleId);
  };

  const imgProps = {
    alt: productName,
    src: constructMSAImageUrl(imageId, msaOpts)
  };

  const productLabel = generateLabelForProduct(brandName, productName, color, price, originalPrice);

  return (
    <button key={key} type="button" onClick={productCardClicked} className={cn(css.productCard, className)} aria-label={productLabel}>
      {currentlyViewing && (
        <div className={css.currentlyViewing}>
          <Tag size="small" variant="blue 400">
            Currently Viewing
          </Tag>
        </div>
      )}
      <div className={cn(css.imageContainer, css.columnContent)}>
        <ImageLazyLoader imageClassName={cn(css.productImage, className)} placeholder={Loader} imgProps={imgProps} />
      </div>
      <dl className={cn(css.details, css.columnContent)}>
        <dt>Brand Name</dt>
        <dd className={css.mainText}>{makeAscii(brandName)}</dd>
        <dt>Product Name</dt>
        <dd className={css.subText}>{makeAscii(productName)}</dd>
        <CardPrice
          price={createCurrencyObj(price)}
          msrp={createCurrencyObj(originalPrice)}
          onSale={ProductUtils.isStyleOnSale({ price, originalPrice })}
          isAvailable={true}
          url={productUrl}
        />
      </dl>
    </button>
  );
};
