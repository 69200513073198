import React, { useState } from 'react';

import type { ProductDetailState } from 'reducers/detail/productDetail';
import type { InfluencerStatus } from 'types/influencer';
import InfluencerProductLinkShareModal from 'components/productdetail/sharing/InfluencerProductLinkShareModal';
import { PRODUCT_PAGE } from 'constants/amethystPageTypes';
import ProductUtils from 'helpers/ProductUtils';
import InfluencerCollectionsWidget from 'components/influencer/collections/InfluencerCollectionsWidget';
import type { AccordionItemProps } from 'components/common/MelodyAccordion';
import { Accordion, AccordionItem } from 'components/common/MelodyAccordion';
import { INFLUENCER_MSA_FOLDER_PATH } from 'constants/influencerPages';

import css from 'styles/components/productdetail/influencerAddToCollectionAndSharing.scss';

interface Props {
  product: ProductDetailState;
  productId: string;
  colorId?: string;
  influencerStatus: InfluencerStatus;
  influencerToken: string;
  obfuscatedCustomerId?: string;
  getStyleId: (...args: any[]) => void;
  isLoadingFromVrsnl?: boolean;
}

const InfluencerAddToCollectionAndSharing = ({
  product,
  productId,
  colorId,
  influencerStatus,
  influencerToken,
  obfuscatedCustomerId,
  getStyleId,
  isLoadingFromVrsnl = false
}: Props) => {
  const [isLinkShareModalOpen, setLinkShareModal] = useState(false);

  const makeAddToCollectionView = () => {
    const { detail } = product;
    if (!detail) {
      return null;
    }

    const style = ProductUtils.getStyleByColor(detail?.styles, colorId);
    return (
      <InfluencerCollectionsWidget
        getStyleId={getStyleId}
        productId={productId}
        colorId={style.colorId}
        price={style.price}
        sourcePage={PRODUCT_PAGE}
      />
    );
  };

  const makeInfluencerAddToCollectionAndSharingView = () => (
    <>
      {isLinkShareModalOpen && (
        <InfluencerProductLinkShareModal
          isLoadingFromVrsnl={isLoadingFromVrsnl}
          toShowModal={isLinkShareModalOpen}
          toggleVisibility={setLinkShareModal}
          product={product}
          productId={productId}
          colorId={colorId}
          influencerStatus={influencerStatus}
          influencerToken={influencerToken}
          obfuscatedCustomerId={obfuscatedCustomerId}
        />
      )}
      <div>
        <button type="button" onClick={() => setLinkShareModal(true)} className={css.sharingContainer}>
          Get Link And Share
        </button>
        <div className={css.addToCollectionContainer}>{makeAddToCollectionView()}</div>
      </div>
    </>
  );

  const makeInfluencerAccordionSection = () => {
    const imgOverrideStyle = {
      width: '140px',
      height: '22px',
      display: 'inline-block'
    };
    return (
      <AccordionItem
        headingImageURL={`${INFLUENCER_MSA_FOLDER_PATH}Zappos-Influencer-Logo.svg`}
        imgStyleOvrride={imgOverrideStyle}
        accordionRegionContainerStyleOverride={isLoadingFromVrsnl ? null : css.influencerAccordionContainer}
        isLoadingFromVrsnl={isLoadingFromVrsnl}
        accordionTestId="InfluencerPDPSharing"
        key="InfluencerPDPSharing"
      >
        {makeInfluencerAddToCollectionAndSharingView()}
      </AccordionItem>
    );
  };

  const accordionSections: React.ReactElement<AccordionItemProps>[] = [];
  const influencerSharingInfo = makeInfluencerAccordionSection();

  if (influencerSharingInfo) {
    accordionSections.push(influencerSharingInfo);
  }

  return (
    <div className={css.accordionContainer}>
      {accordionSections.length > 0 && (
        <Accordion defaultOpenIndex={0} openMultiple>
          {accordionSections}
        </Accordion>
      )}
    </div>
  );
};

export default InfluencerAddToCollectionAndSharing;
