import React, { useState } from 'react';
import useEmblaCarousel from 'embla-carousel-react';

import type { FeaturedImage } from 'types/product';
import ProductGalleryThumbnails from 'components/productdetail/productGallery/ProductGalleryThumbnails';

export interface ThumbnailCarouselProps {
  assets: FeaturedImage[];
  prependedAltText?: string;
  onThumbnailSelected?: (selectedIndex: number) => void;
  defaultSelectedIndex?: number;
}

const ThumbnailCarousel = ({ assets, prependedAltText, defaultSelectedIndex = 0, onThumbnailSelected }: ThumbnailCarouselProps) => {
  const [baseConfig] = useState({ speed: 100, startIndex: 0 });
  const [productThumbnailsRef, productThumbnailsCarousel] = useEmblaCarousel(baseConfig); // [containerRef, carouselApi]

  const [thumbnailIndex, setThumbnailIndex] = useState(defaultSelectedIndex);

  const handleThumbnailSelected = (selectedAssetIndex: number) => {
    setThumbnailIndex(selectedAssetIndex);

    if (onThumbnailSelected) {
      onThumbnailSelected(selectedAssetIndex);
    }
  };

  return (
    <ProductGalleryThumbnails
      productAssets={assets}
      zoomIsActive={false}
      selectedAsset={thumbnailIndex}
      productThumbnailsRef={productThumbnailsRef}
      productThumbnailsCarousel={productThumbnailsCarousel}
      productFeaturedCarousel={undefined}
      hasHorizontalThumbnails={false}
      prependedAltText={prependedAltText}
      onThumbnailSelected={handleThumbnailSelected}
    />
  );
};

export default ThumbnailCarousel;
