import React from 'react';

import InfluencerLinkShareModal from 'components/influencer/InfluencerLinkShareModal';
import { PRODUCT_PAGE } from 'constants/amethystPageTypes';
import ProductUtils from 'helpers/ProductUtils';
import { InfluencerStatus } from 'types/influencer';
import type { ProductDetailState } from 'reducers/detail/productDetail';

interface Props {
  toShowModal: boolean;
  toggleVisibility: any;
  product: ProductDetailState;
  productId: string;
  colorId?: string;
  influencerStatus: InfluencerStatus;
  influencerToken: string;
  obfuscatedCustomerId?: string;
  isLoadingFromVrsnl?: boolean;
}

export const InfluencerProductLinkShareModal = ({
  toShowModal,
  toggleVisibility,
  product,
  productId,
  colorId,
  influencerStatus,
  influencerToken,
  obfuscatedCustomerId,
  isLoadingFromVrsnl
}: Props) => {
  const { selectedSizing, detail } = product;
  if (!detail) {
    return null;
  }
  const [link, notificationMessage] =
    influencerStatus === InfluencerStatus.ACTIVE && influencerToken
      ? [ProductUtils.getInfluencerSharingButtonLink(productId, influencerToken, colorId), 'Your unique influencer URL is copied.']
      : [ProductUtils.getSharingButtonLink(productId, colorId), 'Product link copied.'];

  const style = ProductUtils.getStyleByColor(detail?.styles, colorId);
  const asin = ProductUtils.getSelectedStyleStockBySize([], detail?.sizing, selectedSizing, style.stocks)?.asin || null;

  const productObj = {
    link: link,
    name: `${detail?.brandName} ${detail?.productName}`,
    style: style.color,
    image: detail?.defaultImageUrl.replace('vrsnl.', '')
  };

  const onSharingButtonClick = (linkName: string) => {
    if (influencerStatus === InfluencerStatus.ACTIVE && obfuscatedCustomerId) {
      ProductUtils.generateShareLinkAmethystEvent(linkName, obfuscatedCustomerId, productId, influencerToken, colorId);
    }
    ProductUtils.generateShareLinkEvent(linkName, asin, PRODUCT_PAGE);
  };

  return (
    <InfluencerLinkShareModal
      isModalOpen={toShowModal}
      toggleVisibility={() => toggleVisibility(false)}
      copyMessage={notificationMessage}
      onSharingButtonClick={onSharingButtonClick}
      objectToShare={productObj}
      isLoadingFromVrsnl={isLoadingFromVrsnl}
    />
  );
};

export default InfluencerProductLinkShareModal;
