import React from 'react';

import { BRAND_INFLUENCERS_TITLE } from 'constants/influencerMessages';
import InlineBrandInfluencerShoppablePostCard from 'components/influencer/shoppablePosts/InlineBrandInfluencerShoppablePostCard';
import type { InfluencerContent } from 'types/influencer';
import { track } from 'apis/amethyst';
import { BrandShopTheLookPlacementType } from 'constants/amethystEnums';
import { SEARCH_PAGE } from 'constants/amethystPageTypes';
import { SHOP_THE_LOOK_HEADING } from 'constants/shoppablePosts';
import { evBrandShopTheLookSearchImpression } from 'events/influencerShoppablePost';
import { transformContentForAmethystEvent } from 'helpers/ShoppablePostUtils';
import useEffectOnce from 'hooks/useEffectOnce';

import css from 'styles/components/influencer/brand/brandContentSearchCarousel.scss';

interface BrandContentResultsProps {
  brandContents: InfluencerContent[];
  searchTerm: string;
  rowPosition: number;
}

export const BrandContentSearchCarousel = (props: BrandContentResultsProps) => {
  const { brandContents, rowPosition, searchTerm } = props;

  useEffectOnce(() => {
    if (brandContents.length) {
      const contentData = brandContents.map((content, elementIndex) => transformContentForAmethystEvent(content, elementIndex + 1));
      track(() => [
        evBrandShopTheLookSearchImpression,
        {
          placementType: BrandShopTheLookPlacementType.BANNER,
          position: rowPosition,
          searchTerm,
          contents: contentData,
          sourcePage: SEARCH_PAGE
        }
      ]);
    }
  });

  return (
    <div className={css.content}>
      <h1 className={css.title}>{SHOP_THE_LOOK_HEADING}</h1>
      <h2 className={css.subTitle}>{BRAND_INFLUENCERS_TITLE}</h2>
      <div className={css.banner}>
        {brandContents.map((content, index) => {
          const { contentId = '' } = content;
          if (index < 3) {
            return (
              <InlineBrandInfluencerShoppablePostCard
                key={`shopTheLook-${contentId}`}
                content={content}
                className={css.productCardOverride}
                isRenderedOnBanner={true}
                slotPosition={rowPosition}
                searchTerm={searchTerm}
                cardIndex={index}
                sourcePage={SEARCH_PAGE}
              />
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default BrandContentSearchCarousel;
