import type { FormattedProductData } from 'reducers/detail/productDetail';

export interface OutfitRecoContent {
  productList: RecoCardProduct[];
  complementaryGroupId: string;
  complementaryGroupScore: number;
  layout: RecoCardLayout;
}

export interface GetOutfitsResponse {
  recommendations: OutfitRecoContent[];
}

export enum RecoCardLayout {
  DEFAULT = 'default',
  TILED = 'tiled'
}

export interface RecoCardProduct {
  productId: string;
  brand?: string;
  asin: string;
  listPrice?: string;
  price?: string | number;
  productName?: string;
  imageId: string;
  styleId: string;
  productUrl?: string;
  currentlyViewing: boolean;
}

export interface RecoProductsData {
  [p: string]: FormattedProductData & { styleId: string; imageDimensions?: any };
}

export interface ImageDimensions {
  height: number;
  width: number;
}

export interface ImageDimensionMap {
  [imageId: string]: ImageDimensions;
}

export enum ImageCategory {
  PORTRAIT,
  LANDSCAPE
}
