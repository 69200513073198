import React from 'react';

import MelodyModal from 'components/common/MelodyModal';
import MelodySizingGuide from 'components/landing/MelodySizingGuide';
import sizeChartData from 'data/sizeChart.json';

import css from 'styles/components/common/SizeChartModal.scss';

interface DefaultShoeSizeConversionContent {
  kind: 'defaultShoeSizeConversion';
}

interface ImageContent {
  kind: 'image';
  src: string;
  alt: string;
}

export type SizeChartModalContent = DefaultShoeSizeConversionContent | ImageContent;

interface ClosedProps {
  isOpen: false;
}

interface OpenProps {
  content: SizeChartModalContent;
  isOpen: true;
  onRequestClose: () => void;
}

export type SizeChartModalProps = ClosedProps | OpenProps;

function makeContent(content: SizeChartModalContent) {
  switch (content.kind) {
    case 'defaultShoeSizeConversion':
      return <MelodySizingGuide slotDetails={sizeChartData} />;
    case 'image': {
      const { src, alt } = content;
      return <img className={css.imageContent} src={src} alt={alt} />;
    }
  }
}

function SizeChartModal(props: SizeChartModalProps) {
  if (!props.isOpen) {
    return null;
  }

  const { content, isOpen, onRequestClose } = props;
  return (
    <MelodyModal className={css.modal} isOpen={isOpen} onRequestClose={onRequestClose} wrapperTestId="sizeGuideModal" buttonTestId="close">
      {makeContent(content)}
    </MelodyModal>
  );
}

export default SizeChartModal;
