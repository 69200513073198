import React, { Component } from 'react';
import { parseUrl } from 'query-string';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import { TrackRecosInView } from 'components/productdetail/TrackRecosInView';
import type { AppState } from 'types/app';
import Recos from 'components/productdetail/Recos';
import { getJanusRecos, shouldRecosUpdate } from 'helpers/RecoUtils';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import type { RecosState } from 'reducers/recos';
import { onEvent } from 'helpers/EventHelpers';
import ProductUtils from 'helpers/ProductUtils';

interface Params {
  productId: string;
  colorId?: string;
  seoName?: string;
}
interface OwnProps {
  numberOfAskQuestions: number;
  numberOfReviews: number;
  onRecoClicked: (...args: any) => void;
  params: Params;
  similarProductRecos: RecosState;
  styleId: string;
  heartsData: any; // TODO ts type this when `hearts` are typed
  numberOfGridColumns?: number;
  /** Ref for the title of the reco <h2> */
  titleRef?: React.RefObject<HTMLHeadingElement>;
  isFullMaxWidth?: boolean;
}
interface State {
  productIdFromHash: string;
  styleIdFromHash: string;
}

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = OwnProps & PropsFromRedux;

export class RecosDetail1 extends Component<Props> {
  static defaultProps = {
    numberOfAskQuestions: 0,
    numberOfReviews: 0
  };

  state: State = {
    productIdFromHash: '',
    styleIdFromHash: ''
  };

  componentDidMount() {
    this.setIdsFromHash();
    onEvent(window, 'popstate', this.setIdsFromHash, undefined, this);
  }

  // We don't want this component to re-render when it's either loading, or recommendations haven't changed
  shouldComponentUpdate(nextProps: Props) {
    return shouldRecosUpdate(this.props, nextProps);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.setIdsFromHash);
  }

  setIdsFromHash = () => {
    const { urlHash } = this.props;
    if (urlHash.includes('#quickview')) {
      const {
        query: { styleId, productId }
      } = parseUrl(urlHash);
      this.setState({
        productIdFromHash: productId,
        styleIdFromHash: styleId
      });
    } else {
      this.setState({
        productIdFromHash: '',
        styleIdFromHash: ''
      });
    }
  };

  render() {
    const {
      similarProductRecos = {},
      onRecoClicked,
      numberOfAskQuestions,
      numberOfReviews,
      heartsData,
      numberOfGridColumns,
      titleRef,
      isFullMaxWidth = false
    } = this.props;

    // If we have recommendations, render the component
    const janusRecos = getJanusRecos(similarProductRecos);

    if (janusRecos) {
      let { recos } = janusRecos;
      const { title } = janusRecos;
      if (recos && numberOfAskQuestions <= 0 && numberOfReviews <= 0) {
        recos = recos.slice(0, 3);
      }

      return (
        <TrackRecosInView
          products={recos}
          impression={{
            widgetType: ProductUtils.translateRecoTitleToAmethystWidget(title),
            numberOfRecommendations: recos.length,
            recommendationSource: similarProductRecos.source
          }}
        >
          <Recos
            id="alsoBought"
            isVertical={false}
            recoType="crossRecos"
            recos={recos}
            title={title}
            onRecoClicked={onRecoClicked}
            heartsData={heartsData}
            numberOfGridColumns={numberOfGridColumns}
            titleRef={titleRef}
            isFullMaxWidth={isFullMaxWidth}
          />
        </TrackRecosInView>
      );
    }

    return null;
  }
}

const mapStateToProps = (state: AppState) => {
  const {
    router: {
      location: { hash = '' }
    }
  } = state;

  return { urlHash: hash };
};

const connector = connect(mapStateToProps, {});
export default withErrorBoundary('RecosDetail1', connector(RecosDetail1));
