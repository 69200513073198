// This file was generated with `npm run icons:generate`. Don’t edit directly.
import Icon from 'tailwind/components/Icon/Icon';
import type { SmallIconProps } from 'tailwind/components/Icon/Icon.types';

const UtilityStrokeCheckOutlineSmallIcon = (props: SmallIconProps) => (
  <Icon {...props} viewBox="0 0 16 16">
    <g clipPath="url(#icon__clip0_8836_1442)">
      <path
        d="M5 8.48018L7 10.3135L10.902 6.08849M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z"
        stroke="currentcolor"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="icon__clip0_8836_1442">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);

export default UtilityStrokeCheckOutlineSmallIcon;
