import React from 'react';
import { useDispatch } from 'react-redux';

import type { Product } from 'constants/searchTypes';
import { track } from 'apis/amethyst';
import { evRecommendationClick } from 'events/recommendations';
import { useSponsoredAds } from 'hooks/useSponsoredAds';
import { trackSponsoredAdImpressions } from 'actions/products';
import SponsoredAdsCarousel from 'components/search/SponsoredAdsCarousel';
import { BOTTOM_PLACEMENT } from 'constants/sponsoredAds';
import type { AppDispatch } from 'entrypoints/bootstrapOnClient';

export interface OwnProps {
  stockId: string;
  keywords: string[];
  containerClassName: string;
  styleId: string;
  isFullMaxWidth?: boolean;
}

const PdpSponsoredAds = ({ stockId, keywords, containerClassName, styleId, isFullMaxWidth = false }: OwnProps): React.ReactElement => {
  const matchStylesStyleId = typeof styleId === 'string' ? [styleId] : [];
  const sponsoredAds = useSponsoredAds({ stockId, keywords, pageType: 'Product', matchStyles: matchStylesStyleId });
  const dispatch: AppDispatch = useDispatch();

  const makeTrackSponsoredAdClick = (product: Pick<Product, 'productId' | 'styleId' | 'colorId'>, index: number) => {
    const { productId, styleId, colorId } = product;
    return () => {
      track(() => [
        evRecommendationClick,
        {
          index,
          recommendationType: 'PRODUCT_RECOMMENDATION',
          productIdentifiers: {
            productId,
            styleId,
            colorId
          },
          recommendationSource: 'MICROSOFT',
          widgetType: 'MICROSOFT_TOP_BLOCK',
          sourcePage: 'PRODUCT_PAGE'
        }
      ]);
    };
  };

  return (
    <SponsoredAdsCarousel
      containerClassName={containerClassName}
      sponsoredAds={sponsoredAds}
      trackSponsoredAdImpressions={({ url }: { url: string }) => dispatch(trackSponsoredAdImpressions({ url }))}
      makeSponsoredAdClick={makeTrackSponsoredAdClick}
      isFullMaxWidth={isFullMaxWidth}
      preferredResultPlacement={BOTTOM_PLACEMENT}
    />
  );
};

export default PdpSponsoredAds;
