import React, { isValidElement } from 'react';
import { Parser } from 'html-to-react';

import type { DescriptionItems } from 'components/productdetail/description/ProductDescription';
import type { OpenSizeChartModal } from 'containers/ProductDetail';
import SizeChartLi from 'components/productdetail/description/SizeChartLi';
import ViewSizeChartModalButton from 'components/productdetail/description/ViewSizeChartModalButton';
import { getTextContent } from 'helpers/HtmlHelpers';
import { getAbsoluteMarketplaceUrl } from 'helpers/MarketplaceUtils';
import ProductUtils from 'helpers/ProductUtils';
import useMartyContext from 'hooks/useMartyContext';

const htmlToReactParser = new Parser();

interface SizeChartsProps {
  descriptionItems: DescriptionItems;
  openSizeChartModal: OpenSizeChartModal;
  productType: string;
  trackViewSizeChart: () => void;
}

interface PopupProps {
  'children'?: React.ReactElement[];
  'data-popup-options': string;
  'href': string;
  'onClick': (event: React.MouseEvent<HTMLElement>) => void;
}

type ParsedPopupLink = string | React.ReactElement<PopupProps>;

function makeSizeChartLink(
  props: SizeChartsProps,
  context: ReturnType<typeof useMartyContext>,
  parsedLink: ParsedPopupLink
): JSX.Element | React.ReactElement<any>[] | string | null {
  if (typeof parsedLink === 'string') {
    return parsedLink;
  }

  const { openSizeChartModal, trackViewSizeChart } = props;
  const { marketplace } = context;

  const text = getTextContent(parsedLink) || 'View the size chart';
  let imageSource = '';

  const { props: { href, children } = {} } = parsedLink;

  if (!href) {
    // if this is HTML with only text children ( no links ) return just the text
    if (typeof children === 'string') {
      return children;
    } else if (Array.isArray(children)) {
      const imageElement = children?.find((child: React.ReactElement<any>) => child?.props?.href);
      if (!imageElement) {
        // children are html elements but have no links ( nested HTML )
        return children?.map(child => getTextContent(child)).reduce((acc, childText) => acc + childText) || null;
      }
      imageSource = getAbsoluteMarketplaceUrl(marketplace.domain, '') + imageElement?.props?.href;
    } else {
      return children ? children : null;
    }
  } else if (href.startsWith('/')) {
    imageSource = getAbsoluteMarketplaceUrl(marketplace.domain, '') + href;
  }

  const modalContent = {
    kind: 'image' as const,
    src: imageSource,
    alt: text
  };

  const buttonProps = { modalContent, openSizeChartModal, trackViewSizeChart };
  return <ViewSizeChartModalButton {...buttonProps}>{text}</ViewSizeChartModalButton>;
}

export function makeSizeChartLiBody(
  props: SizeChartsProps,
  context: ReturnType<typeof useMartyContext>,
  sizeChart: string
): JSX.Element | string | null | (JSX.Element | string | React.ReactElement<any>[] | null)[] {
  const parsedLink = ProductUtils.parseSizeChartLink(sizeChart);

  if (isValidElement<PopupProps>(parsedLink)) {
    return makeSizeChartLink(props, context, parsedLink);
  }

  const linkParsedFromWrappedMarkup = htmlToReactParser.parse(`<span>${sizeChart.trim()}</span>`);
  if (!isValidElement<{ children: ParsedPopupLink[] }>(linkParsedFromWrappedMarkup)) {
    return null;
  }
  const {
    props: { children }
  } = linkParsedFromWrappedMarkup;
  if (children.length <= 1) {
    return linkParsedFromWrappedMarkup;
  }

  return React.Children.map(children, child => makeSizeChartLink(props, context, child));
}

const SizeCharts = (props: SizeChartsProps) => {
  const { descriptionItems, productType } = props;

  const { sizeCharts = [] } = descriptionItems;

  const context = useMartyContext();

  const isShoe = ProductUtils.isShoeType(productType);
  const hasSizeCharts = ProductUtils.getHasSizeCharts(descriptionItems);

  if (!hasSizeCharts) {
    if (isShoe) {
      const modalContent = {
        kind: 'defaultShoeSizeConversion' as const
      };
      const { openSizeChartModal, trackViewSizeChart } = props;

      const buttonProps = { modalContent, openSizeChartModal, trackViewSizeChart };
      return (
        <SizeChartLi>
          <ViewSizeChartModalButton {...buttonProps}>View the size chart</ViewSizeChartModalButton>
        </SizeChartLi>
      );
    } else {
      return null;
    }
  }

  // map each size chart to markup
  const sizeChartListItems = sizeCharts.map((sizeChart, index) => {
    const link = makeSizeChartLiBody(props, context, sizeChart);
    return <SizeChartLi key={index}>{link}</SizeChartLi>;
  });
  return <>{sizeChartListItems}</>;
};

export default SizeCharts;
