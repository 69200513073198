import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';

import {
  fetchProductComparisonTable,
  getProductRelationsForComparableProductIds,
  resetProductComparisonTable
} from 'actions/productdetail/productComparison';
import type { ProductFeature, ProductFeatureValue, ProductHeader } from 'types/productComparison';
import { ProductComparisonAPIResponse } from 'types/productComparison';
import ColorSwatchWrapper from 'components/search/ColorSwatches';
import useEffectOnce from 'hooks/useEffectOnce';
import { Loader } from 'components/Loader';
import UtilityStrokeCheckOutlineSmallIcon from 'tailwind/components/Icons/UtilityStrokeCheckOutlineSmallIcon';
import UtilityStrokeCheckOutlineMediumIcon from 'tailwind/components/Icons/UtilityStrokeCheckOutlineMediumIcon';
import UtilityStrokeCloseOutlineMediumIcon from 'tailwind/components/Icons/UtilityStrokeCloseOutlineMediumIcon';
import UtilityStrokeCloseOutlineSmallIcon from 'tailwind/components/Icons/UtilityStrokeCloseOutlineSmallIcon';
import UtilityStrokeInfoOutlineSmallIcon from 'tailwind/components/Icons/UtilityStrokeInfoOutlineSmallIcon';
import useWindowSize from 'hooks/useWindowSize';
import useMartyContext from 'hooks/useMartyContext';
import { track } from 'apis/amethyst';
import { evProductComparisonTableView, evProductInteract } from 'events/productComparison';
import { PRODUCT_INTERACTION_TYPE } from 'constants/amethystEnums';
import { lowercaseThenCapitalize } from 'helpers/index';
import Tag from 'tailwind/components/Tag/Tag';
import type { AppDispatch } from 'entrypoints/bootstrapOnClient';
import type { AppState } from 'types/app';
import { useInAssignment } from 'hooks/useHydra';
import { HYDRA_CORE_EXPERIENCE_FUNCTIONALITY_IMPROVEMENTS } from 'constants/hydraTests';
import { cn } from 'helpers/classnames';

import css from 'styles/components/productdetail/productComparisonTable.scss';

interface OwnProps {
  styleId: string;
  productId: string;
}

const ProductComparisonTable = (props: OwnProps) => {
  const { styleId, productId } = props;
  const dispatch: AppDispatch = useDispatch();
  const {
    comparisonTableData: {
      comparisonModule: { header: headerProducts = [], row: productFeatures, rowAdditionalInfo: additionalInfo = {} }
    },
    comparisonTableDataStatus
  } = useSelector((state: AppState) => state.productComparison);
  const hydraCefi = useInAssignment(HYDRA_CORE_EXPERIENCE_FUNCTIONALITY_IMPROVEMENTS);
  const { width: windowWidth = 0, height: windowHeight = 0 } = useWindowSize();
  const isMobile = windowWidth < 650 || windowHeight < 650;
  const { testId } = useMartyContext();

  useEffectOnce(() => {
    dispatch(fetchProductComparisonTable(styleId));

    return () => {
      dispatch(resetProductComparisonTable());
    };
  });

  useEffect(() => {
    if (headerProducts.length > 0) {
      const productIds = headerProducts.map(headerProduct => headerProduct.productId);
      dispatch(getProductRelationsForComparableProductIds(productIds));
      track(() => [evProductComparisonTableView, { sourceProductId: productId, productIds }]);
    }
  }, [headerProducts.length]);

  if (comparisonTableDataStatus === ProductComparisonAPIResponse.FAILED || comparisonTableDataStatus === ProductComparisonAPIResponse.UNKNOWN) {
    return null;
  }
  if (comparisonTableDataStatus === ProductComparisonAPIResponse.FETCHING) {
    return <Loader />;
  }

  const makeAttributeValue = (values: ProductFeatureValue, header: ProductHeader) => {
    const val = values[header.linkedAsin] || '';
    switch (val) {
      case '✔':
      case 'APLUS-TRUE':
        return isMobile ? <UtilityStrokeCheckOutlineSmallIcon size={16} /> : <UtilityStrokeCheckOutlineMediumIcon size={32} />;
      case '✘':
      case 'APLUS-FALSE':
        return isMobile ? <UtilityStrokeCloseOutlineSmallIcon size={16} /> : <UtilityStrokeCloseOutlineMediumIcon size={32} />;
      default:
        const displayName = additionalInfo[val]?.displayName ?? val;
        const tooltipInfo = additionalInfo[val]?.additionalInfo;
        return (
          <>
            {displayName}
            {!!tooltipInfo && (
              <span className={css.tooltipIcon}>
                <UtilityStrokeInfoOutlineSmallIcon size={16} />
              </span>
            )}
            <span className={css.tooltipText}>{tooltipInfo}</span>
          </>
        );
    }
  };

  const createProductCardWithColorSwatches = (currentProduct: ProductHeader) => {
    const { productWithRelations: productWithRelatedStyles, selected } = currentProduct;
    if (!productWithRelatedStyles) {
      return null;
    }
    const { relatedStyles, badges, ...productInfo } = productWithRelatedStyles;

    if (!productInfo || !productInfo.productUrl) {
      return null;
    }

    const onProductClicked = () => {
      track(() => [
        evProductInteract,
        {
          sourceStyleId: styleId,
          interactedProduct: productInfo,
          interactionType: PRODUCT_INTERACTION_TYPE.Click
        }
      ]);
    };
    const onProductMediaHovered = () => {
      track(() => [
        evProductInteract,
        {
          sourceStyleId: styleId,
          interactedProduct: productInfo,
          interactionType: PRODUCT_INTERACTION_TYPE.Hover
        }
      ]);
    };

    return (
      <div className={css.productCardContainer} onMouseOverCapture={onProductMediaHovered}>
        {selected && (
          <div className={css.currentlyViewing}>
            <Tag size="small" variant="blue 400">
              Currently Viewing
            </Tag>
          </div>
        )}
        <ColorSwatchWrapper
          {...productInfo}
          relatedStyles={relatedStyles}
          onClick={onProductClicked}
          onProductMediaHovered={onProductMediaHovered}
          testId={testId('comparableProducts')}
          badges={badges}
          className={css.headerProductImage}
        />
      </div>
    );
  };

  return (
    <div className={cn(css.comparisonWrapper, hydraCefi && 'w-full')}>
      <h2 className={cn(css.tableHeader, hydraCefi && 'text-[26px] sm:text-4xl')}>How they compare</h2>
      <div className={css.comparisonTableComponent}>
        <table className={css.dynamicTable}>
          <thead>
            <tr>
              {headerProducts.map((product: ProductHeader) => (
                <th scope="col" key={product.linkedAsin}>
                  <div className={css.headerProduct}>{createProductCardWithColorSwatches(product)}</div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {productFeatures
              // For maintaining naming consistency, lowercaseThenCapitalize each row label in the response
              .map((property: ProductFeature) => ({ ...property, label: lowercaseThenCapitalize(property.label)?.trim() }) as ProductFeature)
              .filter((property: ProductFeature) => additionalInfo[property.label]?.displayName !== 'DO_NOT_SHOW')
              .map((property: ProductFeature) => {
                const { label, value } = property;
                const displayName = additionalInfo[label]?.displayName ?? label;
                const tooltipInfo = additionalInfo[label]?.additionalInfo;
                return (
                  <React.Fragment key={`${label}_row`}>
                    <tr key={`${label}_tr`}>
                      <td key={`${label}`} className={css.attributeLabel}>
                        {displayName}
                        {!!tooltipInfo && (
                          <span className={css.tooltipIcon}>
                            <UtilityStrokeInfoOutlineSmallIcon size={16} />
                          </span>
                        )}
                        <span className={css.tooltipText}>{tooltipInfo}</span>
                      </td>
                    </tr>
                    <tr className={css.attributeValueRow}>
                      {headerProducts.map(h => (
                        <td key={`${h.linkedAsin}_val`} className={css.attributeValue}>
                          {makeAttributeValue(value, h)}
                        </td>
                      ))}
                    </tr>
                  </React.Fragment>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductComparisonTable;
