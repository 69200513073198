import React from 'react';

import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import useMartyContext from 'hooks/useMartyContext';

interface Props {
  productId: string;
}

const ProductSkuNumber = ({ productId }: Props) => {
  const { testId } = useMartyContext();
  return (
    <div data-test-id={testId('sku')}>
      SKU <span itemProp="sku">{productId}</span>
    </div>
  );
};

export default withErrorBoundary('ProductSkuNumber', ProductSkuNumber);
