import type { Dispatch, SetStateAction } from 'react';
import React from 'react';

import { cn } from 'helpers/classnames';
import type { OutfitRecoContent, RecoCardProduct } from 'types/outfitRecos';
import { RecoCardLayout } from 'types/outfitRecos';
import { constructMSAImageUrl } from 'helpers/index';
import MelodyCarousel from 'components/common/MelodyCarousel';
import { SmallLoader } from 'components/Loader';
import { track } from 'apis/amethyst';
import { areAllProductsBuyable, generateOutfitGroup } from 'helpers/outfitRecoUtils';
import { evOutfitRecoCardClick } from 'events/outfitRecos';
import ImageLazyLoader from 'components/common/ImageLazyLoader';
import { MSA_SETTINGS_JPG_85 } from 'constants/shoppablePosts';
import type { ProductWithRelationsFromCalypso } from 'types/calypso';

import css from 'styles/components/outfitRecos/recosCardV2.scss';

interface RecosCardV2Props {
  contents: OutfitRecoContent[];
  contentIndex: number;
  setContentIndex: Dispatch<SetStateAction<number>>;
  setIsQuickViewModalOpen: Dispatch<SetStateAction<boolean>>;
  currentImageId: string;
  productRelations: { [s: string]: ProductWithRelationsFromCalypso } | undefined;
}

interface ProductCardProps {
  openModal: () => void;
  setIndexForModal: () => void;
  triggerEventForRecoCardClick: (clickStyleId: string) => void;
  productRelations: { [s: string]: ProductWithRelationsFromCalypso } | undefined;
}

export const RecosCardV2 = ({ contents, currentImageId, setIsQuickViewModalOpen, setContentIndex, productRelations }: RecosCardV2Props) => {
  const openModal = () => setIsQuickViewModalOpen(true);

  return (
    <div className={css.recosCard}>
      <div className={css.currentlyViewing}>
        <img
          className={cn(css.productImage, css.mainImage)}
          src={constructMSAImageUrl(currentImageId, { width: 300, autoCrop: true })}
          alt="Current Product"
        />
        <p>Currently Viewing</p>
      </div>
      <div className={css.plusIcon} />
      <div className={css.recosCarousel}>
        <MelodyCarousel isFullWidth={false} showDots={true} showArrows={false} contentIndexChangeCallback={setContentIndex}>
          {contents.map((content, index) => {
            const { productList, layout, complementaryGroupId } = content;
            const setIndexForModal = () => setContentIndex(index);
            const triggerEventForRecoCardClick = (clickStyleId: string) => {
              const outfitGroup = generateOutfitGroup(complementaryGroupId, productList);
              const { styleId: seedStyleId } = productList.find(product => product.currentlyViewing)!;
              track(() => [
                evOutfitRecoCardClick,
                {
                  seedStyleId,
                  outfitGroup,
                  clickStyleId,
                  outfitNumber: index + 1
                }
              ]);
            };
            const productCardProps: ProductCardProps = {
              openModal,
              setIndexForModal,
              triggerEventForRecoCardClick,
              productRelations
            };
            return makeRecosCard(
              productList.filter(product => product.imageId !== currentImageId),
              layout,
              complementaryGroupId,
              productCardProps
            );
          })}
        </MelodyCarousel>
      </div>
    </div>
  );
};

export default RecosCardV2;

const makeRecosCard = (products: RecoCardProduct[], cardLayout: RecoCardLayout, groupId: string, productCardProps: ProductCardProps) => {
  const { productRelations } = productCardProps;
  switch (cardLayout) {
    case RecoCardLayout.DEFAULT:
      if (products.length !== 3 || !areAllProductsBuyable(products, productRelations)) return null;
      const cssClasses = [css.primary1, css.secondary1, css.secondary2];
      return (
        <div key={groupId} className={css.defaultLayoutContainer}>
          {products.map((product, index) => makeProductSlot(`${groupId}-${index}`, product, cssClasses[index], productCardProps))}
        </div>
      );
    default:
      return <></>;
  }
};

const makeProductSlot = (key: string, product: RecoCardProduct | undefined, className: string, productCardProps: ProductCardProps) => {
  const { styleId } = product || { styleId: '' };

  const { openModal, triggerEventForRecoCardClick, productRelations } = productCardProps;

  const Loader = (
    <div key={key} className={css.loader}>
      <SmallLoader />
    </div>
  );

  if (!productRelations || !(+styleId in productRelations)) {
    return Loader;
  }

  const { productName, originalPrice, price } = productRelations[styleId]!;

  const { imageId } = product!;

  const msaOpts = {
    width: 300,
    autoCrop: true,
    customSettings: MSA_SETTINGS_JPG_85
  };

  const imgProps = {
    alt: productName,
    src: constructMSAImageUrl(imageId, msaOpts)
  };

  const productCardClicked = (styleId: string) => {
    triggerEventForRecoCardClick(styleId);
    openModal();
  };

  const isOnSale = originalPrice !== price;

  return (
    <button key={key} type="button" onClick={() => productCardClicked(styleId)} className={cn(css.productCard, className)}>
      <div className={css.productBox}>
        <ImageLazyLoader imageClassName={css.productImage} placeholder={Loader} imgProps={imgProps} />
      </div>
      <p className={cn(css.price, { [css.salePrice]: isOnSale })}>{`${price}`}</p>
    </button>
  );
};
