import React from 'react';

import { cn } from 'helpers/classnames';
import { track } from 'apis/amethyst';
import { evOutfitFeedbackClick } from 'events/outfitRecos';
import type { RecoCardProduct } from 'types/outfitRecos';
import { generateOutfitGroup } from 'helpers/outfitRecoUtils';

import css from 'styles/components/outfitRecos/outfitFeedback.scss';

interface OutfitFeedbackProps {
  groupId: string;
  products: RecoCardProduct[];
  outfitNumber: number;
}

export const OutfitFeedback = ({ groupId, products, outfitNumber }: OutfitFeedbackProps) => {
  const [feedbackGiven, setFeedbackGiven] = React.useState<string[]>([]);

  const onUpvoteClick = () => {
    setFeedbackGiven([...feedbackGiven, groupId]);
    triggerAmethystEventForFeedback(groupId, products, true, outfitNumber);
  };

  const onDownvoteClick = () => {
    setFeedbackGiven([...feedbackGiven, groupId]);
    triggerAmethystEventForFeedback(groupId, products, false, outfitNumber);
  };

  return (
    <div className={css.feedbackContainer}>
      {feedbackGiven.includes(groupId) ? (
        <span>Thanks for the feedback!</span>
      ) : (
        <>
          <span>Do you like this outfit?</span>
          <button className={cn(css.upvoteIcon, css.feedbackIcon)} type="button" aria-label="Like this Outfit" onClick={onUpvoteClick} />
          <button className={cn(css.downvoteIcon, css.feedbackIcon)} type="button" aria-label="Dislike this Outfit" onClick={onDownvoteClick} />
        </>
      )}
    </div>
  );
};

export default OutfitFeedback;

const triggerAmethystEventForFeedback = (groupId: string, products: RecoCardProduct[], isUpvote: boolean, outfitNumber: number) => {
  const { styleId: seedStyleId } = products.find(product => product.currentlyViewing) || {};
  if (seedStyleId) {
    const outfitGroup = generateOutfitGroup(groupId, products);
    track(() => [evOutfitFeedbackClick, { seedStyleId, outfitGroup, isUpvote, outfitNumber }]);
  }
};
